var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { TextComponent } from 'renderer/components';
import { COLORS } from 'renderer/styles';
import styled from 'styled-components';
import { ScheduleQuery } from 'renderer/queries';
import { Lodash } from 'renderer/utils';
export var MobileSmallCalendar = function (props) {
    var date = props.targetDate;
    return (React.createElement(MobileSmallCalendarWrapper, null,
        React.createElement(MobileSmallCalendarHeader, null, ['일', '월', '화', '수', '목', '금', '토'].map(function (day, index) { return (React.createElement("div", { key: index },
            React.createElement(TextComponent, { type: "caption2", children: day }))); })),
        Lodash.times(6).map(function (week) {
            var startDayInWeek = date.startOf('month').add(week, 'week').startOf('week');
            var isSameWeek = date.isSame(startDayInWeek, 'week');
            return (React.createElement(MobileSmallCalendarRow, { key: week, isCurrentRow: isSameWeek }, Lodash.times(7).map(function (dayInWeek) {
                var targetDay = startDayInWeek.add(dayInWeek, 'day');
                var dayInMonth = targetDay.date();
                var isSaturday = targetDay.day() === 6;
                var isSameMonth = date.isSame(targetDay, 'month');
                var isToday = targetDay.isSame(new Date(), 'date');
                var isSelected = targetDay.isSame(date, 'date');
                var holiday = ScheduleQuery.getHolidayInfo(targetDay.toDate());
                return (React.createElement(DayDiv, { "$isSameMonth": isSameMonth, "$isToday": isToday, "$isSelected": isSelected, key: dayInWeek, onClick: function () { return props.onDateChange(targetDay.toDate()); } },
                    React.createElement(TextComponent, { type: "caption2", children: dayInMonth, color: isToday
                            ? COLORS.white
                            : !!holiday
                                ? COLORS.calendarRedActive
                                : isSelected
                                    ? COLORS.primary1
                                    : isSaturday
                                        ? COLORS.info
                                        : COLORS.black })));
            })));
        })));
};
var MobileSmallCalendarWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n"], ["\n  height: 100%;\n  width: 100%;\n"])));
var MobileSmallCalendarHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 14.285%;\n\n  & > div {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 14.285%;\n\n  & > div {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n"])));
var MobileSmallCalendarRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 14.285%;\n\n  ", "\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 14.285%;\n\n  ", "\n"])), function (props) {
    return props.isCurrentRow &&
        "\n    border-radius: 2px;\n    background-color: var(--gray-7);\n  ";
});
var DayDiv = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  ", "\n  ", "\n"], ["\n  flex: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  ", "\n  ", "\n"])), function (props) {
    return (props.$isToday || props.$isSelected) &&
        "\n    & > div {\n      border-radius: 2px;\n      background-color: ".concat(props.$isToday ? COLORS.primary1 : COLORS.primary4, ";\n      padding: 3px;\n      width: 23px;\n      display: flex;\n      justify-content: center;\n    }\n  ");
}, function (props) {
    return !props.$isSameMonth &&
        "\n    & > div {\n      opacity: 0.3;\n    }\n  ";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
