var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useInterval } from 'renderer/utils';
export var Iframe = React.forwardRef(function (props, ref) {
    return (React.createElement("iframe", { ref: ref, allowFullScreen: true, src: props.src, height: props.height || '100%', width: props.width || '100%', style: __assign(__assign({}, props.style), { border: 0 }) }));
});
export var HelpCenterIframe = React.forwardRef(function (props, ref) {
    useInterval(function () {
        window.scrollTo(0, 0);
    }, 500);
    return (React.createElement("iframe", { ref: ref, allowFullScreen: true, src: props.src, height: props.height || '100%', width: props.width || '100%', style: __assign(__assign({}, props.style), { border: 0 }), sandbox: "allow-presentation allow-scripts allow-same-origin allow-popups allow-modals" }));
});
