var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import notification from 'antd/lib/notification';
import React from 'react';
import { NaverLambdaQuery } from 'renderer/queries';
import { isEmpty } from 'renderer/utils';
import { AntButton, EntireLoader, HookFormInputs } from 'renderer/components';
import { Col, Row } from 'antd/lib';
import styled from 'styled-components';
export var handleSubmitHairTag = function (values, shouldUpdate, create, update, bookingId) { return __awaiter(void 0, void 0, void 0, function () {
    var hairtagCategories, err_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                hairtagCategories = [];
                (_a = values === null || values === void 0 ? void 0 : values.hairTags) === null || _a === void 0 ? void 0 : _a.map(function (value) {
                    var _a = value.split('_'), sex = _a[0], hairTagCategoryCode = _a[1], hairTagPlaceCode = _a[2], hairTagName = _a[3];
                    var currentCategory = hairtagCategories.find(function (category) { return category.hairTagCategoryCode === hairTagCategoryCode; });
                    if (currentCategory) {
                        currentCategory.hairTags = currentCategory.hairTags.concat({
                            sex: sex,
                            hairTagName: hairTagName,
                            hairTagPlaceCode: hairTagPlaceCode
                        });
                    }
                    else {
                        hairtagCategories.push({
                            hairTagCategoryCode: hairTagCategoryCode,
                            hairTags: [
                                {
                                    sex: sex,
                                    hairTagName: hairTagName,
                                    hairTagPlaceCode: hairTagPlaceCode
                                }
                            ]
                        });
                    }
                });
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 7]);
                if (!shouldUpdate) return [3 /*break*/, 3];
                return [4 /*yield*/, update.mutateAsync({ hairtagCategories: hairtagCategories, bookingId: bookingId })];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, create.mutateAsync({ hairtagCategories: hairtagCategories, bookingId: bookingId })];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                notification.success({ message: '헤어태그 설정이 완료되었습니다.' });
                return [3 /*break*/, 7];
            case 6:
                err_1 = _b.sent();
                notification.error({ message: err_1.message || '헤어태그 설정중 오류가 발생했습니다.' });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var NaverHairTagForm = function (props) {
    var _a, _b, _c, _d;
    var tags = NaverLambdaQuery.useGetNaverHairTags();
    var bookingId = (_b = (_a = props.schedule) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.idFromProvider;
    var tagsFromSchedule = NaverLambdaQuery.useGetNaverHairTagsById({ bookingId: bookingId }, { enabled: !!bookingId });
    var _e = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _e[1];
    React.useEffect(function () {
        var _a;
        if (!((_a = props.schedule) === null || _a === void 0 ? void 0 : _a.id)) {
            props.form.reset({ shouldUpdate: false, hairTags: [] });
        }
        else {
            tagsFromSchedule.refetch();
        }
    }, [(_c = props.schedule) === null || _c === void 0 ? void 0 : _c.id]);
    React.useEffect(function () {
        var values = [];
        var items = tagsFromSchedule === null || tagsFromSchedule === void 0 ? void 0 : tagsFromSchedule.data;
        var requiredCategoryItem = items === null || items === void 0 ? void 0 : items.find(function (item) { return item.hairTagCategoryCode === 'REQUIRED'; });
        var requiredTag = (requiredCategoryItem === null || requiredCategoryItem === void 0 ? void 0 : requiredCategoryItem.hairTags) && (requiredCategoryItem === null || requiredCategoryItem === void 0 ? void 0 : requiredCategoryItem.hairTags[0]);
        if (!requiredTag) {
            props.form.reset({ shouldUpdate: shouldUpdate, hairTags: values || [] });
        }
        else {
            var requiredCategory_1 = "".concat(requiredTag.sex, "_").concat(requiredCategoryItem.hairTagCategoryCode, "_").concat(requiredTag.hairTagPlaceCode, "_").concat(requiredTag.hairTagName);
            var gender_1 = requiredTag.sex;
            var rest_1 = {};
            items === null || items === void 0 ? void 0 : items.map(function (item) {
                if (item.hairTagCategoryCode !== 'REQUIRED') {
                    rest_1[item.hairTagCategoryCode] = item.hairTags
                        .map(function (tag) {
                        return "".concat(tag.sex, "_").concat(item.hairTagCategoryCode, "_").concat(tag.hairTagPlaceCode, "_").concat(tag.hairTagName);
                    })
                        .filter(Boolean);
                }
            });
            props.form.reset(__assign({ shouldUpdate: shouldUpdate, gender: gender_1, requiredCategory: requiredCategory_1 }, rest_1));
        }
        forceUpdate();
    }, [tagsFromSchedule.data]);
    var shouldUpdate = isEmpty(tagsFromSchedule === null || tagsFromSchedule === void 0 ? void 0 : tagsFromSchedule.data);
    var dropdowns = [];
    var requiredOptions = [];
    var form = props.form;
    var gender = form.watch('gender');
    var requiredCategory = form.watch('requiredCategory');
    (_d = tags === null || tags === void 0 ? void 0 : tags.data) === null || _d === void 0 ? void 0 : _d.map(function (item) {
        var _a, _b;
        if (item.hairTagCategoryCodeName === '필수') {
            (_a = item.hairTags) === null || _a === void 0 ? void 0 : _a.map(function (tag) {
                if (tag.sex === gender) {
                    requiredOptions.push({
                        value: "".concat(tag.sex, "_").concat(item.hairTagCategoryCode, "_").concat(tag.hairTagPlaceCode, "_").concat(tag.hairTagName),
                        label: "".concat(tag.hairTagName)
                    });
                }
            });
        }
        else {
            if (!gender) {
                return;
            }
            dropdowns.push({
                name: item.hairTagCategoryCode,
                placeholder: item.hairTagCategoryCodeName,
                maxCount: item.maxSelectionCount,
                options: (_b = item.hairTags) === null || _b === void 0 ? void 0 : _b.map(function (tag) {
                    if (tag.sex !== gender) {
                        return;
                    }
                    return {
                        value: "".concat(tag.sex, "_").concat(item.hairTagCategoryCode, "_").concat(tag.hairTagPlaceCode, "_").concat(tag.hairTagName),
                        label: "".concat(tag.hairTagName)
                    };
                }).filter(Boolean)
            });
        }
    });
    return (React.createElement(NaverHairFormWrapper, null,
        React.createElement(HookFormInputs.SelectInput, { label: "\uD544\uC218\uC120\uD0DD*", controlProps: {
                name: 'gender',
                control: form.control,
                rules: gender
                    ? {
                        required: {
                            value: true,
                            message: '성별을 선택해주세요.'
                        }
                    }
                    : null
            }, inputProps: {
                style: { width: '100%' },
                loading: tags.isLoading,
                options: [
                    { label: '여성', value: 'FEMALE' },
                    { label: '남성', value: 'MALE' }
                ],
                onChange: function (value) {
                    form.reset({
                        shouldUpdate: shouldUpdate,
                        gender: value
                    }, { keepValues: false });
                }
            } }),
        React.createElement(Row, { gutter: 4 }, requiredOptions.map(function (option) {
            return (React.createElement(Col, { key: option.value },
                React.createElement(AntButton, { ghost: option.value === requiredCategory ? true : false, type: option.value === requiredCategory ? 'primary' : 'default', children: option.label, onClick: function () {
                        form.setValue('requiredCategory', option.value, { shouldValidate: true });
                    } })));
        })),
        React.createElement(HookFormInputs.SelectInput, { inputProps: {
                optionType: 'button',
                buttonStyle: 'solid',
                options: requiredOptions,
                hidden: true
            }, controlProps: {
                name: 'requiredCategory',
                control: form.control,
                rules: {
                    required: {
                        value: true,
                        message: '필수 선택사항입니다.'
                    }
                }
            } }),
        requiredCategory &&
            dropdowns.map(function (dropdown, index) {
                return (React.createElement(HookFormInputs.SelectInput, { key: dropdown.name, label: index === 0 ? '상세선택' : null, controlProps: {
                        name: dropdown.name,
                        control: form.control
                    }, inputProps: {
                        style: { width: '100%' },
                        loading: tags.isLoading,
                        options: dropdown.options,
                        allowClear: true,
                        placeholder: dropdown.placeholder,
                        onChange: function (value) {
                            if (!value) {
                                form.setValue(dropdown.name, null);
                            }
                        }
                    } }));
            }),
        tags.isLoading && React.createElement(EntireLoader, { open: true })));
};
var NaverHairFormWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0px 24px;\n  & > * {\n    margin-bottom: 8px;\n  }\n"], ["\n  padding: 0px 24px;\n  & > * {\n    margin-bottom: 8px;\n  }\n"])));
var templateObject_1;
