var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { AlertSentenceWithIcon, KRWFomatToString, Rectangle, TextComponent, useEntireLoader, BigTitleModal, RegisterButtonWithOptions, SALES_CONTROLLER_KEYS, HookFormInputs } from 'renderer/components';
import { LOCAL_STORAGE_KEY_PAID_AT_STANDARD, LOCAL_STORAGE_KEY_REFUND_AT_STANDARD } from 'renderer/constants';
import { SalesHistoryModel, EXTENDED_SALES_METHODS } from 'renderer/models';
import { PaymentHistoryQuery } from 'renderer/queries';
import { groupBy, sum, useLocalStorage, useWindowSize } from 'renderer/utils';
import { PCSalesFormLeft, PCSalesFormRight, PCSalesFormRow, SalesModalFooter, TimeBaseSelector } from './SalesForm';
import { useForm } from 'react-hook-form';
import { Layout } from 'common-ui';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import { ManagerCardForRefund, TicketCardForRefund } from './RefundFormElements';
import { SearchInputForSalesDetail } from './SalesDetail';
import { SalesDetailContentWrapper } from './SalesDetailContents';
var FullWidth = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var RefundForm = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var isPC = useWindowSize().isPC;
    var store = props.store;
    var ticket = (_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.ticket;
    var type = !!ticket ? 'ticket' : 'products';
    var paymentMethodsSetting = store.paymentMethodsSetting;
    var fields = [
        EXTENDED_SALES_METHODS.CARD,
        EXTENDED_SALES_METHODS.TRANSFER,
        EXTENDED_SALES_METHODS.CASH,
        EXTENDED_SALES_METHODS.NAVER_PAY,
        EXTENDED_SALES_METHODS.ETC1,
        EXTENDED_SALES_METHODS.ETC2,
        EXTENDED_SALES_METHODS.ETC3,
        EXTENDED_SALES_METHODS.ETC4,
        EXTENDED_SALES_METHODS.ETC5
    ]
        .filter(function (key) {
        var _a, _b, _c;
        var isFiltered = false;
        if ([
            EXTENDED_SALES_METHODS.ETC1,
            EXTENDED_SALES_METHODS.ETC2,
            EXTENDED_SALES_METHODS.ETC3,
            EXTENDED_SALES_METHODS.ETC4,
            EXTENDED_SALES_METHODS.ETC5
        ].includes(key)) {
            if (!((_a = paymentMethodsSetting[key]) === null || _a === void 0 ? void 0 : _a.active) && !Object.keys(((_c = (_b = props.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.methods) || {}).includes(key)) {
                isFiltered = true;
            }
        }
        return !isFiltered;
    })
        .map(function (key) {
        return {
            key: key,
            label: SalesHistoryModel.getSalesMethodLabel(key, props.store)
        };
    });
    var messages = type === 'products'
        ? [
            '매출등록시 적립된 포인트는 자동으로 환불되지 않습니다. 포인트 차감을 원하시면 고객정보에서 포인트를 조정해주세요.',
            '매출등록시 차감된 선불액은 환불할 수 없습니다. 선불액 환불을 원하시면 고객정보에서 선불액을 조정해주세요.',
            '매출등록시 차감된 정기권은 환불할 수 없습니다. 정기권 환불을 원하시면 고객정보에서 정기권을 조정해주세요.',
            '환불한 제품의 재고는 현재고에 반영되지 않습니다. 환불할 제품의 제고를 조정해주세요.'
        ]
        : [
            '매출등록시 적립된 포인트는 자동으로 환불되지 않습니다. 포인트 차감을 원하시면 고객정보에서 포인트를 조정해주세요.'
        ];
    var groupdedSPM = groupBy(((_d = (_c = props.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.salesPerManagers) || [], 'managerId');
    var salesPerManagers = Object.keys(groupdedSPM).map(function (managerId) {
        var _a, _b, _c, _d;
        var types = SalesHistoryModel.PAID_ELEMENTS;
        var methods = {};
        types
            .filter(function (type) { return fields.map(function (field) { return field.key; }).includes(type); })
            .map(function (type) {
            var amount = sum(groupdedSPM[managerId].map(function (item) {
                return ((item === null || item === void 0 ? void 0 : item.methods) && (item === null || item === void 0 ? void 0 : item.methods[type])) || 0;
            }) || 0);
            methods[type] = {
                amount: amount,
                checked: amount > 0
            };
        });
        return {
            ticket: ticket,
            managerId: Number(managerId),
            manager: (_d = (_c = (_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.salesPerManagers) === null || _c === void 0 ? void 0 : _c.find(function (item) { return Number(item.managerId) === Number(managerId); })) === null || _d === void 0 ? void 0 : _d.manager,
            methods: methods
        };
    });
    var mode = useLocalStorage(LOCAL_STORAGE_KEY_PAID_AT_STANDARD, 'salesTime')[0];
    var form = useForm({
        mode: 'onChange',
        defaultValues: {
            salesPerManagers: salesPerManagers,
            memo: '',
            ticket: __assign({}, ticket),
            paidAt: mode === 'salesTime' ? (_f = (_e = props.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.paidAt : new Date()
        }
    });
    var refund = PaymentHistoryQuery.useRefund();
    var finalPrice = (_h = (_g = props.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.paidPrice;
    var renderModal = function () {
        return (React.createElement(BigTitleModal, { okText: "\uD658\uBD88", centered: true, open: !!dialog, onCancel: function () { return setDialog(undefined); }, onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                var payload, result, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            payload = dialog.payload;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            entireLoader.show();
                            return [4 /*yield*/, refund.mutateAsync(payload)];
                        case 2:
                            result = _a.sent();
                            props.onComplete(result);
                            notification.info({ message: '환불 완료' });
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _a.sent();
                            notification.info({ message: '환불 중 에러발생' });
                            return [3 /*break*/, 5];
                        case 4:
                            setDialog(null);
                            entireLoader.hide();
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            }); }, title: "\uD658\uBD88", children: React.createElement("div", { style: { padding: '20px' } },
                React.createElement(TextComponent, { type: "subtitle1", children: "\uB9E4\uCD9C\uAE08\uC561 : ".concat(KRWFomatToString({ value: dialog === null || dialog === void 0 ? void 0 : dialog.finalPrice })) }),
                React.createElement(TextComponent, { type: "subtitle1", children: "\uD658\uBD88\uAE08\uC561 : ".concat(KRWFomatToString({ value: dialog === null || dialog === void 0 ? void 0 : dialog.totalPrice })) }),
                React.createElement(TextComponent, { type: "subtitle1", children: '환불을 진행하시겠습니까?', marginTop: 30 })) }));
    };
    var renderButtons = function () {
        return (React.createElement(SalesModalFooter, { left: {
                buttonProps: {
                    type: 'primary',
                    children: '닫기',
                    onClick: function () { return props.onChangeControlerKey(SALES_CONTROLLER_KEYS.SELECT_MENU); }
                }
            }, right: {
                element: (React.createElement("div", { style: { width: '50%' } },
                    React.createElement(RegisterButtonWithOptions, __assign({ registerType: "sales" }, {
                        children: '환불등록',
                        onClick: onSubmit
                    }))))
            } }));
    };
    var renderDatepicker = function () {
        var _a;
        return (React.createElement(Layout.Inline, { gutter: 16, style: { marginBottom: '20px' } },
            React.createElement(TimeBaseSelector, { isShown: true, modeTime: (_a = props.data.data) === null || _a === void 0 ? void 0 : _a.paidAt, modeKey: 'salesTime', modeLabel: "\uB9E4\uCD9C\uC2DC\uAC04", data: props.data, methods: form, modeLocalKey: LOCAL_STORAGE_KEY_REFUND_AT_STANDARD }),
            React.createElement(HookFormInputs.DateSelector, { controlProps: { name: 'paidAt', control: form === null || form === void 0 ? void 0 : form.control }, inputProps: {} })));
    };
    var renderMemo = function () {
        return (React.createElement(FullWidth, { style: { marginTop: '20px' } },
            React.createElement(StyledTextarea, { inputProps: {}, label: "\uD658\uBD88 \uBA54\uBAA8", controlProps: {
                    name: "memo",
                    control: form.control,
                    rules: {
                        maxLength: {
                            value: 200,
                            message: "\uBA54\uBAA8\uB294 200\uC790\uB97C \uB118\uAE38 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4."
                        }
                    }
                } })));
    };
    var onSubmit = form.handleSubmit(function (values) {
        var _a;
        var totalPrice = 0;
        var salesMethods = {};
        var salesPerManagers = values.salesPerManagers.map(function (spm) {
            var methods = {};
            Object.keys(spm.methods).map(function (key) {
                if (spm.methods[key] && spm.methods[key].amount) {
                    var amount = -spm.methods[key].amount;
                    methods[key] = amount;
                    if (salesMethods[key]) {
                        salesMethods[key] = salesMethods[key] + amount;
                    }
                    else {
                        salesMethods[key] = amount;
                    }
                    totalPrice = amount + totalPrice;
                }
            });
            return __assign(__assign({}, spm), { methods: methods });
        });
        setDialog({
            finalPrice: finalPrice,
            totalPrice: totalPrice,
            payload: {
                paidAt: values.paidAt,
                totalPrice: totalPrice,
                ticket: values.ticket,
                salesPerManagers: salesPerManagers,
                storeId: props.store.id,
                prevSalesId: props.data.data.id,
                methods: salesMethods,
                customerId: props.data.data.customerId,
                memo: values.memo,
                prevSalesContent: (_a = props.data) === null || _a === void 0 ? void 0 : _a.data.saleListString
            }
        });
    }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var entireLoader = useEntireLoader();
    var values = form.watch();
    var _s = React.useState(), dialog = _s[0], setDialog = _s[1];
    return isPC ? (React.createElement(PCSalesFormRow, { wrap: false },
        renderModal(),
        React.createElement(PCSalesFormLeft, null,
            React.createElement("div", null,
                renderDatepicker(),
                messages.map(function (item, index) {
                    return React.createElement(AlertSentenceWithIcon, { key: index, descriptions: [item] });
                }),
                React.createElement(TicketCardForRefund, { data: props.data, form: form }),
                React.createElement(TextComponent, { type: "headline3", marginTop: 20, marginBottom: 10 },
                    "\uB9E4\uCD9C \uAE08\uC561 ",
                    KRWFomatToString({ value: finalPrice })),
                salesPerManagers.map(function (spm, index) {
                    var _a, _b, _c, _d;
                    return (React.createElement(SalesDetailContentWrapper, { key: (_a = spm.manager) === null || _a === void 0 ? void 0 : _a.id },
                        React.createElement(Layout.Inline, { gutter: 16, align: "end", style: { marginBottom: '20px' } },
                            React.createElement(Rectangle, { width: 12, color: (_c = (_b = spm === null || spm === void 0 ? void 0 : spm.manager) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.color }),
                            React.createElement(TextComponent, { type: "caption1", children: (_d = spm === null || spm === void 0 ? void 0 : spm.manager) === null || _d === void 0 ? void 0 : _d.displayName })),
                        React.createElement(ManagerCardForRefund, { index: index, spm: spm, fields: fields, data: props.data, form: form })));
                }),
                renderMemo())),
        React.createElement(PCSalesFormRight, null,
            React.createElement(SearchInputForSalesDetail, { customerId: (_k = (_j = props.data) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.customerId, isDeleted: !((_m = (_l = props.data) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.customer) }),
            React.createElement("div", { style: { height: '100%' } }),
            renderButtons()))) : (React.createElement(MobileForm, null,
        renderModal(),
        React.createElement(PCSalesFormLeft, { style: { flexDirection: 'column', padding: '20px' } },
            renderDatepicker(),
            React.createElement(SearchInputForSalesDetail, { customerId: (_p = (_o = props.data) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.customerId, isDeleted: !((_r = (_q = props.data) === null || _q === void 0 ? void 0 : _q.data) === null || _r === void 0 ? void 0 : _r.customer) }),
            messages.map(function (item, index) {
                return React.createElement(AlertSentenceWithIcon, { key: index, descriptions: [item] });
            }),
            React.createElement(TicketCardForRefund, { data: props.data, form: form }),
            React.createElement(TextComponent, { type: "headline3", marginTop: 20, marginBottom: 10 },
                "\uB9E4\uCD9C \uAE08\uC561 ",
                KRWFomatToString({ value: finalPrice })),
            salesPerManagers.map(function (spm, index) {
                var _a, _b, _c, _d;
                return (React.createElement(SalesDetailContentWrapper, { key: (_a = spm.manager) === null || _a === void 0 ? void 0 : _a.id },
                    React.createElement(Layout.Inline, { gutter: 16, align: "end", style: { marginBottom: '20px' } },
                        React.createElement(Rectangle, { width: 12, color: (_c = (_b = spm === null || spm === void 0 ? void 0 : spm.manager) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.color }),
                        React.createElement(TextComponent, { type: "caption1", children: (_d = spm === null || spm === void 0 ? void 0 : spm.manager) === null || _d === void 0 ? void 0 : _d.displayName })),
                    React.createElement(ManagerCardForRefund, { index: index, spm: spm, fields: fields, data: props.data, form: form })));
            }),
            renderMemo(),
            renderButtons())));
};
var MobileForm = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  overflow: scroll;\n"], ["\n  width: 100%;\n  height: 100%;\n  overflow: scroll;\n"])));
var StyledTextarea = styled(HookFormInputs.Textarea)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3;
