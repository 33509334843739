var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import isEmpty from 'lodash-es/isEmpty';
export function HookForm(props) {
    var methods = props.methods, children = props.children, onSubmit = props.onSubmit;
    var handleSubmit = methods.handleSubmit;
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) }, React.Children.map(children, function (child) {
            return child.props.name
                ? React.createElement(child.type, __assign({}, __assign(__assign({}, child.props), { register: methods.register, key: child.props.name })))
                : child;
        }))));
}
export var isSubmittable = function (state) {
    return isEmpty(state.errors) && !isEmpty(state.dirtyFields);
};
