// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Ks05seSuwVgKjHk-wgeln::-webkit-scrollbar {\n  display: none;\n}\n\n._1d7TFOt2BPTWyQrrLf6uVr {\n  position: relative;\n  height: 100%;\n}\n\n.Ks05seSuwVgKjHk-wgeln {\n  overflow: auto;\n  height: 100%;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  position: relative;\n}\n\n.wsB-yAzXhbmjApkEgXtzt {\n  width: 10px;\n  height: 100%;\n  right: 0;\n  top: 0px;\n  position: absolute;\n  border-radius: 7px;\n  bottom: 0px;\n  /* background-color: rgba(0, 0, 0, 0.35); */\n}\n\n._2spxPK-yutaPQz2rHbZsOV {\n  width: 6px;\n  height: 20px;\n  margin-left: 1px;\n  position: absolute;\n  border-radius: 7px;\n  opacity: 1;\n  top: 0;\n  background-color: rgba(0, 0, 0, 0.3);\n}\n", ""]);
// Exports
exports.locals = {
	"scrollhost": "Ks05seSuwVgKjHk-wgeln",
	"scrollhostContainer": "_1d7TFOt2BPTWyQrrLf6uVr",
	"scrollBar": "wsB-yAzXhbmjApkEgXtzt",
	"scrollThumb": "_2spxPK-yutaPQz2rHbZsOV"
};
module.exports = exports;
