var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Col, Row } from 'antd/lib/grid';
import React from 'react';
import { BigTitleModal, ClickableText, FluidRow, Icon, KRWFomatToString, TextComponent, CustomDropdown, ButtonWithPlusCircle, typographyByName, ScheduleIconComponent, XScrollableTable } from 'renderer/components';
import { HookFormInputs } from 'renderer/components/Forms/Inputs';
import { EXTENDED_SALES_METHODS, getOtherDataFromTicket, getOtherDataFromUsingData, SalesHistoryModel, SALES_METHODS_KEYS, TICKET_TYPE, EXTENDED_SALES_MEHOD_KOREAN } from 'renderer/models';
import { set, sum, get, useWindowSize, floor, times } from 'renderer/utils';
import { handleClearPrepaidInputs } from './SalesForm';
import styled, { css } from 'styled-components';
import { COLORS, MediaQuery } from 'renderer/styles';
import { useForm, useFormState } from 'react-hook-form';
import notification from 'antd/lib/notification';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'renderer/constants';
import { CustomerGroupQuery, StoreQuery } from 'renderer/queries';
import { Layout } from 'common-ui';
import { isEmpty } from 'lodash';
export var PaymentInputs = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var store = StoreQuery.getMyStoreInCache();
    var paymentMethodsSetting = store.paymentMethodsSetting;
    var isPC = useWindowSize().isPC;
    var prevSales = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data;
    var isUpdatedAndPrepaidSales = (prevSales === null || prevSales === void 0 ? void 0 : prevSales.isUpdated) && ((_b = prevSales.ticket) === null || _b === void 0 ? void 0 : _b.type) === TICKET_TYPE.CHARGING_MONEY;
    var isUpdated = prevSales === null || prevSales === void 0 ? void 0 : prevSales.isUpdated;
    var isDeposit = props.parentType === 'deposit';
    var customerId = props.methods.watch('customerId');
    // FIXME: type
    var methodsValue = props.methods.watch("methods");
    React.useEffect(function () {
        var _a;
        if (!isDeposit) {
            handleClearPrepaidInputs(props.methods, true);
        }
        (_a = props.methods) === null || _a === void 0 ? void 0 : _a.trigger();
    }, [customerId]);
    React.useEffect(function () {
        setTimeout(function () {
            var _a;
            calculateCreateCustomerPoint();
            (_a = props.methods) === null || _a === void 0 ? void 0 : _a.trigger([
                'leftPayment',
                'customerPoint',
                "methods.sharedPrepaidPoint.deduction",
                "methods.prepaidPoint.deduction",
                "methods.customerPoint.deduction"
            ]);
        }, 100);
    }, [props.customer]);
    var methods = props.methods;
    var finalPrice = isDeposit
        ? props.finalPrice || 0
        : ['product', 'reservationFee'].includes((_c = props.data) === null || _c === void 0 ? void 0 : _c.type)
            ? sum((_d = methods
                .getValues('usingHistory')) === null || _d === void 0 ? void 0 : _d.map(function (history) { return getOtherDataFromUsingData(history, props.customer).finalPrice; }))
            : ((_f = (_e = getOtherDataFromTicket(methods.getValues('ticket'))) === null || _e === void 0 ? void 0 : _e.copiedTicketTemplate) === null || _f === void 0 ? void 0 : _f.totalPrice) || 0;
    React.useEffect(function () {
        setTimeout(function () {
            var _a;
            (_a = props.methods) === null || _a === void 0 ? void 0 : _a.trigger(['leftPayment']);
        }, 100);
    }, [finalPrice]);
    var paidPrice = sum(SALES_METHODS_KEYS.map(function (key) { return get(methodsValue, "".concat(key, ".deduction")) || 0; }));
    var customer = props.customer;
    var hasCustomerPrepaids = (sum((_g = props.methods.getValues('prepaidPoints')) === null || _g === void 0 ? void 0 : _g.map(function (point) { return point.deduction || 0; })) || 0) !== 0;
    var hasSharedPrepaidPoint = (sum((_h = props.methods.getValues('sharedPrepaidPoints')) === null || _h === void 0 ? void 0 : _h.map(function (point) { return point.deduction || 0; })) || 0) !== 0;
    var activeSharedPrepaidPointValue = props.methods.getValues('methods.sharedPrepaidPoint.active');
    var calculateCreateCustomerPoint = function () {
        var _a, _b;
        var customerObject = customer || ((_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.customer) || props.methods.getValues('customer');
        var createCustomerPoint = (customerObject &&
            CustomerGroupQuery.calculateWillCreateCustomerPoint(methodsValue, customerObject === null || customerObject === void 0 ? void 0 : customerObject.customerGroupId, customerObject === null || customerObject === void 0 ? void 0 : customerObject.storeId)) ||
            0;
        props.methods.setValue('createCustomerPoint', createCustomerPoint);
    };
    var handleChangeCheck = function (ev) {
        var _a;
        var inputName = (_a = ev.target.name) === null || _a === void 0 ? void 0 : _a.split('methods.').pop();
        var newMethods = set(methodsValue || {}, inputName, ev.target.checked);
        var checkedMethods = Object.keys(newMethods).filter(function (key) { var _a; return (_a = newMethods[key]) === null || _a === void 0 ? void 0 : _a.active; });
        if (!hasCustomerPrepaids && !hasSharedPrepaidPoint) {
            var prevTotalPrice = sum(Object.keys(newMethods).map(function (key) { var _a; return ((_a = newMethods[key]) === null || _a === void 0 ? void 0 : _a.deduction) || 0; })) || 0;
            var remainFinalPrice_1 = finalPrice - prevTotalPrice;
            checkedMethods.map(function (key) {
                var _a;
                var deduction = (inputName.includes(key) ? remainFinalPrice_1 : (_a = newMethods[key]) === null || _a === void 0 ? void 0 : _a.deduction) || 0;
                newMethods[key] = {
                    active: true,
                    deduction: deduction
                };
            });
        }
        Object.keys(newMethods).map(function (key) {
            if (!newMethods[key].active) {
                newMethods[key] = {
                    active: false,
                    deduction: 0
                };
            }
            else {
                newMethods[key] = {
                    active: true,
                    deduction: newMethods[key].deduction || 0
                };
            }
        });
        methods.setValue('methods', newMethods, {
            shouldDirty: true
        });
        if (inputName === 'prepaidPoint.active' && !ev.target.checked) {
            methods.setValue('prepaidPoints', null);
        }
        if (inputName === 'sharedPrepaidPoint.active' && !ev.target.checked) {
            methods.setValue('sharedPrepaidPoints', null);
        }
        // FIXME: methodsValue 변화 리렌더 안됌
        calculateCreateCustomerPoint();
        setTimeout(function () {
            methods.trigger(['methods', 'prepaidPoints', 'sharedPrepaidPoints', 'leftPayment']);
        }, 100);
    };
    // FIXME: methodsValue 변화 리렌더 안됌
    var handleInputChange = function (key, value) {
        // 인풋에서 수정시 수동차감 해제
        if (key === 'prepaidPoint') {
            props.methods.setValue('prepaidPoints', null);
        }
        // 인풋이 두개 활성화 일때 다른 값 변경해줌 (prepaid 포함 일 경우 x)
        var checkedMethods = Object.keys(methodsValue).filter(function (key) {
            var _a;
            return (_a = methodsValue[key]) === null || _a === void 0 ? void 0 : _a.active;
        });
        if (checkedMethods.length === 2 &&
            !(hasCustomerPrepaids || hasSharedPrepaidPoint || activeSharedPrepaidPointValue)) {
            var targetKey = checkedMethods.find(function (item) { return item !== key; });
            if (targetKey) {
                var newValue = (finalPrice || 0) - (value || 0);
                if (newValue >= 0) {
                    methods.setValue("methods.".concat(targetKey, ".deduction"), newValue, { shouldValidate: true });
                }
            }
        }
        calculateCreateCustomerPoint();
    };
    var getDisabled = function (key) {
        return !get(methodsValue, "".concat(key, ".active"));
    };
    var renderByKey = function (key) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (key === EXTENDED_SALES_METHODS.DEDUCTION_PRICE_BY_TICKET) {
            return undefined;
        }
        if ([
            EXTENDED_SALES_METHODS.PREPAID_POINT,
            EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT,
            EXTENDED_SALES_METHODS.CUSTOMER_POINT
        ].includes(key)) {
            var isPrepaidOrSharedPrepaid = [
                EXTENDED_SALES_METHODS.PREPAID_POINT,
                EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT
            ].includes(key);
            var isPrepaid = key === EXTENDED_SALES_METHODS.PREPAID_POINT;
            var ticketType = methods.getValues("ticket.copiedTicketTemplate.type");
            var originalPointAmount = (isPrepaid
                ? (_a = customer === null || customer === void 0 ? void 0 : customer.meta) === null || _a === void 0 ? void 0 : _a.prepaid
                : key === 'sharedPrepaidPoint'
                    ? customer === null || customer === void 0 ? void 0 : customer.sharedPrepaidPoint
                    : (_b = customer === null || customer === void 0 ? void 0 : customer.meta) === null || _b === void 0 ? void 0 : _b.customerPoint) || 0;
            // 본 거래로 얻은 포인트 FIXME: 왜 티켓에만 포인트가??
            var shouldMinusPointAmount = isUpdated && key === 'customerPoint' ? prevSales.createCustomerPoint || 0 : 0;
            // 본 거래에 사용한 포인트
            var shouldPlusPointAmount = (isUpdated && ((_d = (_c = props.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.methods) && ((_f = (_e = props.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.methods[key])) || 0;
            var label = isPrepaid ? '선불액' : key === 'sharedPrepaidPoint' ? '공유선불액' : '포인트';
            var pointAmount = originalPointAmount - shouldMinusPointAmount + shouldPlusPointAmount;
            var availablePoint = pointAmount > 0 ? pointAmount : 0;
            var left = availablePoint - (methods.getValues("methods.".concat(key, ".deduction")) || 0);
            var inputDisabled = getDisabled(key);
            var disabled = !customer ||
                (isPrepaidOrSharedPrepaid &&
                    ((_g = props.data) === null || _g === void 0 ? void 0 : _g.type) === 'ticket' &&
                    (!ticketType || ticketType === TICKET_TYPE.CHARGING_MONEY));
            return (React.createElement(PrepaidInput, { label: label, onChangeCheck: handleChangeCheck, completelyDisabled: disabled, inputDisabled: inputDisabled, methods: methods, customerId: customerId, key: key, inputKey: key, inputProps: props, availablePoint: availablePoint, finalPrice: finalPrice, handleInputChange: handleInputChange, left: left }));
        }
        else {
            return (React.createElement(NormalPaidInputWithCheckbox, { key: key, label: SalesHistoryModel.getSalesMethodLabel(key, props.store), checkboxProps: {
                    memoDeps: [methodsValue, finalPrice, hasCustomerPrepaids, hasSharedPrepaidPoint],
                    inputProps: {
                        onChange: handleChangeCheck,
                        checked: methodsValue ? (_h = methodsValue[key]) === null || _h === void 0 ? void 0 : _h.active : false
                    },
                    controlProps: {
                        name: "methods.".concat(key, ".active"),
                        control: props.methods.control,
                        rules: {
                            deps: ['leftPayment']
                        }
                    }
                }, inputProps: {
                    controlProps: {
                        name: "methods.".concat(key, ".deduction"),
                        control: props.methods.control,
                        rules: {
                            deps: ['leftPayment', "methods.customerPoint.deduction"]
                        }
                    },
                    inputProps: {
                        disabled: getDisabled(key),
                        suffix: '원',
                        onChange: function (value) { return handleInputChange(key, value); }
                    }
                }, rateSelectorProps: key !== 'discount'
                    ? null
                    : {
                        key: key,
                        finalPrice: finalPrice,
                        onChange: function (value) {
                            var methods = __assign({}, props.methods.getValues('methods'));
                            methods[key] = { active: true, deduction: value };
                            props.methods.setValue('methods', methods, { shouldValidate: true, shouldDirty: true });
                            handleInputChange(key, value);
                            setTimeout(function () {
                                props.methods.trigger(['methods', 'leftPayment']);
                            }, 100);
                        }
                    } }));
        }
    };
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 } },
        React.createElement("div", { style: { flex: 1 } },
            isPC && !props.hideTitle && (React.createElement(PaidReservationFeeInfo, { data: props.data.data, fallback: React.createElement(TextComponent, { type: "headline3", children: "\uACB0\uC81C \uAE08\uC561", marginBottom: 8, marginTop: 10, textAlign: "center" }) })),
            React.createElement(HookFormInputs.NumberInput, { inputProps: { style: { display: 'none' } }, controlProps: {
                    control: (_j = props.methods) === null || _j === void 0 ? void 0 : _j.control,
                    value: Number(finalPrice || 0) - Number(paidPrice || 0),
                    name: 'leftPayment',
                    rules: {
                        validate: function (_) {
                            if (props.ignoreLeftPayment) {
                                return true;
                            }
                            return finalPrice - paidPrice !== 0 ? '결제 금액을 확인해주세요.' : true;
                        }
                    }
                } }),
            React.createElement(PaidPriceWithFinalPrice, { finalPrice: finalPrice, paidPrice: paidPrice }),
            [
                EXTENDED_SALES_METHODS.PREPAID_POINT,
                EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT,
                EXTENDED_SALES_METHODS.CUSTOMER_POINT,
                EXTENDED_SALES_METHODS.DISCOUNT,
                EXTENDED_SALES_METHODS.CARD,
                EXTENDED_SALES_METHODS.TRANSFER,
                EXTENDED_SALES_METHODS.CASH,
                EXTENDED_SALES_METHODS.NAVER_PAY,
                EXTENDED_SALES_METHODS.ETC1,
                EXTENDED_SALES_METHODS.ETC2,
                EXTENDED_SALES_METHODS.ETC3,
                EXTENDED_SALES_METHODS.ETC4,
                EXTENDED_SALES_METHODS.ETC5,
                EXTENDED_SALES_METHODS.UNPAID
            ]
                .filter(function (key) { return !(props.hideMethods || []).includes(key); })
                .filter(function (key) {
                var _a, _b, _c;
                var isFiltered = false;
                if ([
                    EXTENDED_SALES_METHODS.ETC1,
                    EXTENDED_SALES_METHODS.ETC2,
                    EXTENDED_SALES_METHODS.ETC3,
                    EXTENDED_SALES_METHODS.ETC4,
                    EXTENDED_SALES_METHODS.ETC5
                ].includes(key)) {
                    if (!((_a = paymentMethodsSetting[key]) === null || _a === void 0 ? void 0 : _a.active) && !Object.keys(((_c = (_b = props.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.methods) || {}).includes(key)) {
                        isFiltered = true;
                    }
                }
                return !isFiltered;
            })
                .map(function (key) {
                return renderByKey(key);
            })
                .filter(Boolean)),
        !props.hideCreateCustomerPoint && !!customer && (React.createElement(WillCreatePoint, { justify: "space-between", align: "middle", gutter: 8 },
            React.createElement(Col, { span: 12 },
                React.createElement(TextComponent, { children: "\uC801\uB9BD \uC608\uC815 \uD3EC\uC778\uD2B8", type: "caption1", marginLeft: 5 })),
            React.createElement(Col, { span: 12 },
                React.createElement(HookFormInputs.NumberInput, { inputProps: { suffix: '원' }, controlProps: {
                        control: (_k = props.methods) === null || _k === void 0 ? void 0 : _k.control,
                        name: 'createCustomerPoint'
                    } }))))));
};
export var PaidPriceWithFinalPrice = function (props) {
    var finalPrice = props.finalPrice, paidPrice = props.paidPrice;
    return (React.createElement(GrayBorder, null,
        React.createElement(TextComponent, { color: COLORS.gray4, type: "caption1", style: { textDecoration: 'line-through' }, children: KRWFomatToString({ value: finalPrice }) }),
        React.createElement(TextComponent, { type: "headline2", children: KRWFomatToString({ value: finalPrice - paidPrice }) })));
};
export var NormalPaidInputWithCheckbox = function (props) {
    var checkboxProps = props.checkboxProps, rateSelectorProps = props.rateSelectorProps, inputProps = props.inputProps, label = props.label;
    return (React.createElement(FluidRow, { gutter: 2, align: "middle", wrap: false, style: { marginBottom: '15px' }, key: props.key },
        React.createElement(Col, { span: 14 },
            React.createElement(StyledCheckbox, __assign({}, checkboxProps, { inputProps: __assign(__assign({}, checkboxProps.inputProps), { children: React.createElement(ClickableText, { type: "caption1", children: props.label }) }) }))),
        React.createElement(Col, { span: 10 },
            !!rateSelectorProps && React.createElement(DiscountRateSelector, __assign({}, rateSelectorProps)),
            React.createElement(HookFormInputs.NumberInput, __assign({}, inputProps)))));
};
var PaidReservationFeeInfo = function (props) {
    var _a, _b, _c, _d;
    var scheduleDeposit = ((_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.scheduleDeposit) || 0;
    var scheduleDepositMethods = ((_b = props === null || props === void 0 ? void 0 : props.data) === null || _b === void 0 ? void 0 : _b.scheduleDepositMethods) || {};
    var paidReservationFee = sum(Object.keys(scheduleDepositMethods).map(function (key) { return scheduleDepositMethods[key] || 0; })) || 0;
    var depositStatus = paidReservationFee === 0
        ? 'pending'
        : scheduleDeposit === paidReservationFee
            ? 'completed'
            : scheduleDeposit > paidReservationFee
                ? 'under'
                : 'over';
    var statusObject = {
        completed: {
            label: '입금완료',
            color: COLORS.info
        },
        under: {
            label: '입금부족',
            color: COLORS.calendarRedActive
        },
        pending: {
            label: '입금대기',
            color: COLORS.warning
        },
        over: {
            label: '입금초과',
            color: COLORS.calendarRedActive
        }
    };
    return !!scheduleDeposit || !!paidReservationFee ? (React.createElement("div", null,
        React.createElement(Layout.Inline, { gutter: 4, align: "center" },
            React.createElement(TextComponent, { type: "caption1", children: "\uC608\uC57D\uAE08 \uC2E4\uC785\uAE08\uC561" }),
            React.createElement(Layout.Inline, { gutter: 2, align: "center" },
                React.createElement(ScheduleIconComponent, { iconName: depositStatus }),
                React.createElement(TextComponent, { type: "caption1", children: (_c = statusObject[depositStatus]) === null || _c === void 0 ? void 0 : _c.label, color: (_d = statusObject[depositStatus]) === null || _d === void 0 ? void 0 : _d.color }))),
        React.createElement(TextComponent, { type: "caption1", children: Object.keys(scheduleDepositMethods)
                .map(function (key) {
                var label = EXTENDED_SALES_MEHOD_KOREAN[key];
                var value = scheduleDepositMethods[key] || 0;
                if (value) {
                    return "[".concat(label, "] ").concat(KRWFomatToString({ value: value }));
                }
            })
                .filter(Boolean)
                .join(' ') }))) : (props.fallback);
};
var PrepaidInput = function (props) {
    var _a, _b;
    var inputKey = props.inputKey, availablePoint = props.availablePoint, inputProps = props.inputProps, completelyDisabled = props.completelyDisabled, finalPrice = props.finalPrice, inputDisabled = props.inputDisabled, handleInputChange = props.handleInputChange, left = props.left, label = props.label;
    var isDeposit = ((_a = props.inputProps) === null || _a === void 0 ? void 0 : _a.parentType) === 'deposit';
    var isPrepaid = inputKey === 'prepaidPoint' || inputKey === 'sharedPrepaidPoint';
    var isShared = isPrepaid && inputKey === 'sharedPrepaidPoint';
    return (React.createElement(FluidRow, { key: inputKey, gutter: 2, align: "top", wrap: false, style: props.customerId ? null : { display: 'none' } },
        React.createElement(Col, { span: 14 },
            React.createElement(StyledCheckbox, { memoDeps: [finalPrice, availablePoint], inputProps: {
                    onChange: props.onChangeCheck,
                    checked: props.methods.getValues("methods.".concat(inputKey, ".active")),
                    disabled: completelyDisabled || availablePoint === 0,
                    children: (React.createElement(PrimaryDiv, null,
                        React.createElement(TextComponent, { type: "caption1", color: COLORS.white, children: "".concat(label, " | ").concat(KRWFomatToString({
                                value: availablePoint || 0
                            })) })))
                }, controlProps: {
                    name: "methods.".concat(inputKey, ".active"),
                    control: props.methods.control,
                    rules: {
                        deps: ['leftPayment', "methods.".concat(inputKey, ".deduction")]
                    }
                } }),
            isPrepaid &&
                !isDeposit &&
                ((_b = inputProps.customer) === null || _b === void 0 ? void 0 : _b.id) &&
                inputKey !== 'sharedPrepaidPoint' &&
                !completelyDisabled && (React.createElement(SmallButtonWithPlusCircle, { children: "\uC120\uBD88\uC561 \uD310\uB9E4", onClick: function () { var _a; return inputProps.onClickPurchaseTicket((_a = inputProps.customer) === null || _a === void 0 ? void 0 : _a.id); } }))),
        React.createElement(Col, { span: 10 },
            React.createElement(Row, { style: { position: 'relative' } },
                isPrepaid && !isDeposit && (React.createElement(SelectDeductionPrepaid, __assign({ methodKey: inputKey }, inputProps, { finalPrice: finalPrice, disabled: completelyDisabled }))),
                React.createElement(HookFormInputs.NumberInput, { memoDeps: [finalPrice], inputProps: {
                        // style: { width: '100%' },
                        disabled: inputDisabled || completelyDisabled || isShared,
                        suffix: '원',
                        onChange: function (value) {
                            handleInputChange(inputKey, value);
                        }
                    }, controlProps: {
                        name: "methods.".concat(inputKey, ".deduction"),
                        control: props.methods.control,
                        rules: {
                            max: {
                                value: availablePoint,
                                message: "".concat(label, " \uC0AC\uC6A9\uB7C9\uC774 \uBCF4\uC720\uB7C9\uC744 \uCD08\uACFC\uD569\uB2C8\uB2E4.")
                            },
                            deps: ['leftPayment']
                        }
                    } })),
            React.createElement(Row, { justify: "end" },
                React.createElement(TextComponent, { type: "caption1", color: COLORS.gray3, children: "\uC794\uC5EC : ".concat(KRWFomatToString({ value: left || 0 })) })))));
};
var StyledCheckbox = styled(HookFormInputs.CheckboxInput)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var PrimaryDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 2px;\n  background-color: var(--primary-1);\n  width: 100%;\n  height: 32px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding-left: 10px;\n  width: 150px;\n  cursor: pointer;\n"], ["\n  border-radius: 2px;\n  background-color: var(--primary-1);\n  width: 100%;\n  height: 32px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding-left: 10px;\n  width: 150px;\n  cursor: pointer;\n"])));
var GrayBorder = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: 2px;\n  border: solid 1px var(--gray-5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 68px;\n  margin-bottom: 15px;\n"], ["\n  border-radius: 2px;\n  border: solid 1px var(--gray-5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 68px;\n  margin-bottom: 15px;\n"])));
var WillCreatePoint = styled(Row)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-radius: 2px;\n  background-color: var(--gray-7);\n  height: 50px;\n  padding: 0px;\n"], ["\n  border-radius: 2px;\n  background-color: var(--gray-7);\n  height: 50px;\n  padding: 0px;\n"])));
var ListButton = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 21px;\n  width: 21px;\n  position: absolute;\n  z-index: 9999;\n  top: 6px;\n  left: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: ", ";\n  border-radius: 20px;\n  cursor: pointer;\n"], ["\n  height: 21px;\n  width: 21px;\n  position: absolute;\n  z-index: 9999;\n  top: 6px;\n  left: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: ", ";\n  border-radius: 20px;\n  cursor: pointer;\n"])), COLORS.gray2);
var ShareListButton = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 100%;\n  width: 260%;\n  position: absolute;\n  z-index: 1;\n  top: 6px;\n  left: -150%;\n  padding-left: 70%;\n  margin-top: -5px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  ", "\n\n  & > div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: ", ";\n    border-radius: 20px;\n    height: 21px;\n    width: 21px;\n    & > svg {\n    }\n  }\n"], ["\n  height: 100%;\n  width: 260%;\n  position: absolute;\n  z-index: 1;\n  top: 6px;\n  left: -150%;\n  padding-left: 70%;\n  margin-top: -5px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  ", "\n\n  & > div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: ", ";\n    border-radius: 20px;\n    height: 21px;\n    width: 21px;\n    & > svg {\n    }\n  }\n"])), MediaQuery.tabletWithMobile(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100vw;\n  left: -156%;\n  padding-left: 70%;\n  "], ["\n  width: 100vw;\n  left: -156%;\n  padding-left: 70%;\n  "]))), COLORS.gray2);
var SelectDeductionPrepaid = function (props) {
    var _a, _b, _c, _d, _e, _f;
    if (!((_a = props.customer) === null || _a === void 0 ? void 0 : _a.id)) {
        return React.createElement("div", null);
    }
    var prevData = (_b = props.data) === null || _b === void 0 ? void 0 : _b.data;
    var isUpdated = prevData === null || prevData === void 0 ? void 0 : prevData.isUpdated;
    var parentForm = props.methods;
    var _g = React.useState(false), visible = _g[0], setVisible = _g[1];
    var customer = props.customer;
    var finalPrice = props.finalPrice;
    var isShared = props.methodKey === EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT;
    var activeTickets = (isShared ? customer === null || customer === void 0 ? void 0 : customer.activeShareTicketsCharging : customer === null || customer === void 0 ? void 0 : customer.activeTicketsCharging) || [];
    var usedTicketHistories = ((_e = (_d = (_c = props.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.prepaidHistory) === null || _e === void 0 ? void 0 : _e.filter(function (history) { var _a, _b; return isShared ? ((_a = history.ticket) === null || _a === void 0 ? void 0 : _a.customerId) !== (customer === null || customer === void 0 ? void 0 : customer.id) : ((_b = history.ticket) === null || _b === void 0 ? void 0 : _b.customerId) === (customer === null || customer === void 0 ? void 0 : customer.id); })) || [];
    var selectedItemKey = isShared ? 'sharedPrepaidPoints' : 'prepaidPoints';
    var validKey = isShared ? 'isValidSharedPrepaid' : 'isValidPrepaid';
    var originalPrepaidsValue = parentForm.watch(selectedItemKey) || [];
    var availablePrepaidItems = !isUpdated
        ? activeTickets.map(function (ticket) {
            var _a;
            return {
                ticketId: ticket.id,
                ticket: ticket,
                deduction: null,
                customerId: ticket.customerId,
                expiredAt: ticket.expiredAt,
                data: {
                    remain: ((_a = ticket === null || ticket === void 0 ? void 0 : ticket.data) === null || _a === void 0 ? void 0 : _a.remain) || 0
                }
            };
        })
        : usedTicketHistories
            .map(function (item) {
            var _a, _b, _c;
            var activeTicket = activeTickets.find(function (ticket) { return item.ticketId === ticket.id; }) || item.ticket;
            if (!activeTicket) {
                return;
            }
            return {
                ticketId: activeTicket.id,
                ticket: activeTicket,
                deduction: null,
                expiredAt: activeTicket.expiredAt,
                customerId: (activeTicket === null || activeTicket === void 0 ? void 0 : activeTicket.customerId) || ((_a = item.ticket) === null || _a === void 0 ? void 0 : _a.customerId),
                data: {
                    remain: ((_b = activeTicket === null || activeTicket === void 0 ? void 0 : activeTicket.data) === null || _b === void 0 ? void 0 : _b.remain) + ((_c = item.data) === null || _c === void 0 ? void 0 : _c.deduction)
                }
            };
        })
            .concat(activeTickets
            .filter(function (ticket) { return !usedTicketHistories.map(function (history) { return history.ticketId; }).includes(Number(ticket.id)); })
            .map(function (ticket) {
            var _a;
            return {
                ticketId: ticket.id,
                ticket: ticket,
                deduction: null,
                customerId: ticket.customerId,
                expiredAt: ticket.expiredAt,
                data: {
                    remain: ((_a = ticket === null || ticket === void 0 ? void 0 : ticket.data) === null || _a === void 0 ? void 0 : _a.remain) || 0
                }
            };
        }))
            .filter(Boolean);
    var modalForm = useForm({ mode: 'onChange' });
    var modalFormState = useFormState({ name: validKey, control: modalForm === null || modalForm === void 0 ? void 0 : modalForm.control });
    var errorMessage = (modalFormState.errors[validKey] || {}).message;
    React.useEffect(function () {
        if (visible) {
            modalForm.setValue(selectedItemKey, availablePrepaidItems.map(function (item) {
                var _a, _b;
                var deduction = ((_a = originalPrepaidsValue.find(function (origin) { return origin.ticketId === item.ticketId; })) === null || _a === void 0 ? void 0 : _a.deduction) || item.deduction || 0;
                if (isUpdated && !isEmpty(usedTicketHistories)) {
                    var usedTicketHistory = usedTicketHistories.find(function (history) { return history.ticketId === item.ticketId; });
                    deduction = ((_b = usedTicketHistory === null || usedTicketHistory === void 0 ? void 0 : usedTicketHistory.data) === null || _b === void 0 ? void 0 : _b.deduction) || 0;
                }
                return {
                    ticketId: item.ticketId,
                    deduction: deduction
                };
            }));
        }
        else {
            modalForm.setValue(selectedItemKey, []);
        }
    }, [visible]);
    var totalDeduction = sum((_f = modalForm.watch(selectedItemKey)) === null || _f === void 0 ? void 0 : _f.map(function (prepaid) { return prepaid.deduction || 0; })) || 0;
    var handlOpen = function () {
        var _a, _b, _c;
        if (props.disabled) {
            return;
        }
        if ((_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.hasExpiredOrCompletedPrepaid) {
            return notification.info({ message: '사용완료,만료,삭제된 선불액이 등록된 경우 수정할 수 없습니다.' });
        }
        if (isShared) {
            if (!isEmpty(activeTickets)) {
                setVisible(!visible);
            }
            else {
                notification.info({ message: '사용가능한 선불액이 없습니다.' });
            }
        }
        else {
            if ((_c = customer === null || customer === void 0 ? void 0 : customer.meta) === null || _c === void 0 ? void 0 : _c.prepaid) {
                setVisible(!visible);
            }
            else {
                notification.info({ message: '사용가능한 선불액이 없습니다.' });
            }
        }
    };
    var handleSubmit = modalForm.handleSubmit(function (data) {
        var _a, _b;
        var totalDeduction = sum((_a = data[selectedItemKey]) === null || _a === void 0 ? void 0 : _a.map(function (prepaid) { return prepaid.deduction || 0; })) || 0;
        if (totalDeduction) {
            parentForm.setValue("methods.".concat(props.methodKey), {
                deduction: totalDeduction,
                active: true
            }, {
                shouldValidate: true,
                shouldDirty: true
            });
            parentForm.setValue(selectedItemKey, (_b = data[selectedItemKey]) === null || _b === void 0 ? void 0 : _b.filter(function (prepaid) { return prepaid.deduction; }), {
                shouldValidate: true,
                shouldDirty: true
            });
        }
        else {
            parentForm.setValue("methods.".concat(props.methodKey), {
                deduction: null,
                active: false
            }, {
                shouldValidate: true,
                shouldDirty: true
            });
            parentForm.setValue(selectedItemKey, null, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
        setTimeout(function () {
            parentForm.trigger('leftPayment');
        }, 10);
        setVisible(false);
    });
    return (React.createElement(React.Fragment, null,
        isShared ? (React.createElement(ShareListButton, { onClick: handlOpen },
            React.createElement("div", null,
                React.createElement(Icon, { name: "list", color: COLORS.white })))) : (React.createElement(ListButton, { onClick: handlOpen },
            React.createElement(Icon, { name: "list", color: COLORS.white }))),
        React.createElement(BigTitleModal, { open: visible, onCancel: function () {
                setVisible(false);
            }, onOk: handleSubmit, okButtonProps: {
                disabled: !!errorMessage
            }, title: isShared ? '공유선불액 차감' : '선불액 선택차감', children: React.createElement("div", null,
                React.createElement(TextComponent, { marginLeft: 20, marginTop: 20, marginBottom: 20, type: "headline3", children: "\uC794\uC5EC ".concat(isShared ? '공유선불액' : '선불액', " : ").concat(KRWFomatToString({
                        value: isShared ? customer === null || customer === void 0 ? void 0 : customer.sharedPrepaidPoint : customer === null || customer === void 0 ? void 0 : customer.prepaidPoint
                    })) }),
                React.createElement(XScrollableTable, { dataSource: availablePrepaidItems, columns: [
                        isShared && {
                            title: '고객명',
                            dataIndex: 'customer.name',
                            key: 'ticket.name',
                            render: function (_, row) { var _a; return (_a = customer.getCustomerRelation(row.customerId)) === null || _a === void 0 ? void 0 : _a.customerName; }
                        },
                        isShared && {
                            title: '관계명',
                            dataIndex: 'relactionName',
                            key: 'relactionName',
                            render: function (_, row) { var _a; return (_a = customer.getCustomerRelation(row.customerId)) === null || _a === void 0 ? void 0 : _a.title; }
                        },
                        !isShared && {
                            title: '선불액명',
                            dataIndex: 'ticket.name',
                            key: 'ticket.name',
                            render: function (_, row) { var _a; return (_a = row.ticket) === null || _a === void 0 ? void 0 : _a.name; }
                        },
                        {
                            title: '잔여',
                            dataIndex: 'data.remain',
                            key: 'data.remain',
                            render: function (value, row) {
                                var _a;
                                return KRWFomatToString({ value: (_a = row.data) === null || _a === void 0 ? void 0 : _a.remain });
                            }
                        },
                        {
                            title: '만료일',
                            dataIndex: 'expiredAt',
                            key: 'expiredAt',
                            render: function (value) { return (value ? dayjs(value).format(DATE_FORMAT) : '무제한'); }
                        },
                        {
                            title: '차감금액',
                            dataIndex: 'amount',
                            key: 'amount',
                            render: function (_, row, index) {
                                return (React.createElement(React.Fragment, null,
                                    React.createElement(HookFormInputs.NumberInput, { inputProps: {
                                            style: { display: 'none' },
                                            suffix: '원'
                                        }, controlProps: {
                                            name: "".concat(selectedItemKey, ".").concat(index, ".ticketId"),
                                            control: modalForm.control
                                        } }),
                                    React.createElement(HookFormInputs.NumberInput, { inputProps: {
                                            suffix: '원'
                                        }, controlProps: {
                                            name: "".concat(selectedItemKey, ".").concat(index, ".deduction"),
                                            control: modalForm.control,
                                            rules: {
                                                deps: [validKey],
                                                validate: function (value) {
                                                    var _a;
                                                    if (value > (((_a = row.data) === null || _a === void 0 ? void 0 : _a.remain) || 0)) {
                                                        return '잔여 금액이 부족합니다.';
                                                    }
                                                    return true;
                                                }
                                            }
                                        } })));
                            }
                        }
                    ].filter(Boolean) }),
                React.createElement(HookFormInputs.NumberInput, { inputProps: {
                        style: { display: 'none' }
                    }, controlProps: {
                        name: validKey,
                        control: modalForm.control,
                        rules: {
                            validate: function () {
                                return totalDeduction > finalPrice
                                    ? "\uCC28\uAC10 \uCD1D\uC561\uC740 \uACB0\uC81C\uAE08\uC561(".concat(KRWFomatToString({ value: finalPrice }), ")\uC774\uD558\uC5EC\uC57C \uD569\uB2C8\uB2E4.")
                                    : true;
                            }
                        }
                    } }),
                React.createElement(TextComponent, { color: errorMessage ? COLORS.calendarRedActive : COLORS.black, textAlign: "right", marginRight: 20, marginTop: 20, marginBottom: 20, type: "headline3", children: "\uCC28\uAC10 \uCD1D\uC561 : ".concat(KRWFomatToString({ value: totalDeduction })) })) })));
};
var DiscountRateSelector = function (props) {
    var finalPrice = props.finalPrice;
    var items = times(20).map(function (value) {
        var percentage = (value + 1) * 5;
        return {
            name: "".concat(percentage),
            label: "".concat(percentage, "%"),
            value: percentage,
            onClick: function () {
                props.onChange(floor((finalPrice * percentage) / 100));
            }
        };
    });
    return (React.createElement(CustomDropdown, { items: items, overlayStyle: { height: '300px', overflow: 'scroll' } },
        React.createElement(ListButton, null,
            React.createElement(Icon, { name: "percent", color: COLORS.white }))));
};
var SmallButtonWithPlusCircle = styled(ButtonWithPlusCircle)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  & svg {\n    height: 16px;\n    width: 16px;\n  }\n  & > span {\n    ", "} \n  }\n"], ["\n  & svg {\n    height: 16px;\n    width: 16px;\n  }\n  & > span {\n    ", "} \n  }\n"])), css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      ", ";\n    "], ["\n      ", ";\n    "])), typographyByName('subtitle3')));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
