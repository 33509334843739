var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Icon, TextComponent, Marginer, AntLeftIconButton } from 'renderer/components';
import { COLORS } from 'renderer/styles';
var FlexColumn = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var FlexRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n"], ["\n  display: flex;\n  flex-direction: row;\n"])));
var FlexColumnCenter = styled(FlexColumn)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  justify-content: center;\n  align-items: center;\n"], ["\n  justify-content: center;\n  align-items: center;\n"])));
var FlexRowCenter = styled(FlexRow)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  justify-content: center;\n  align-items: center;\n"], ["\n  justify-content: center;\n  align-items: center;\n"])));
export var ErrorDisplayer = function (props) {
    return (React.createElement(Wrapper, null,
        React.createElement(ErrorCircle, { isUpdated: props.isUpdated, children: props.icon ? (props.icon) : (React.createElement(Icon, { name: "x", color: COLORS.white, size: 32, attr: { strokeWidth: 3, strokeLinecap: 'round', strokeLinejoin: 'round', viewBox: '0 0 24 24' } })) }),
        React.createElement(TextComponent, { type: "headline1", color: props.isUpdated ? COLORS.primary1 : COLORS.danger, children: props.title || 'Error', marginTop: 20, marginBottom: 12 }),
        (props.messages || ['페이지를 불러올 수 없습니다.', '새로고침을 눌러 다시 시도해보세요.']).map(function (message, index) {
            return React.createElement(TextComponent, { key: index, type: "24b", children: message, color: COLORS.gray1 });
        }),
        React.createElement(Marginer, { height: 30 }),
        props.onClick && (React.createElement(AntLeftIconButton, { type: "primary", ghost: true, onClick: props.onClick, children: props.button || '새로고침', icon: props.buttonIcon }))));
};
var Wrapper = styled(FlexColumnCenter)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100vw;\n  height: 100vh;\n"], ["\n  width: 100vw;\n  height: 100vh;\n"])));
var ErrorCircle = styled(FlexColumnCenter)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 82px;\n  height: 82px;\n  background-color: ", ";\n  border-radius: 41px;\n"], ["\n  width: 82px;\n  height: 82px;\n  background-color: ", ";\n  border-radius: 41px;\n"])), function (props) { return (props.isUpdated ? COLORS.primary1 : COLORS.danger); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
