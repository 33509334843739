var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Col, Row } from 'antd/lib/grid';
import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { IMAGES } from 'renderer/assets';
import { COLORS } from 'renderer/styles';
import { useHover } from 'renderer/utils';
import styled from 'styled-components';
import { TextComponent } from 'renderer/components';
var DragHandle = SortableHandle(function () { return React.createElement(DragHandlerIcon, null); });
var DragHandlerIcon = function (props) {
    var _a = useHover(), hoverRef = _a[0], isHovered = _a[1];
    return React.createElement(IconImage, { ref: hoverRef, src: props.isHovered || isHovered ? IMAGES.icon_drag_hover : IMAGES.icon_drag });
};
var SortableItem = SortableElement(function (props) {
    var _a = useHover(), hoverRef = _a[0], isHovered = _a[1];
    var rightAction = props.item.rightAction;
    var body = props.item.body;
    return (React.createElement(ListItemWrapper, { ref: hoverRef, key: "item-".concat(props.item.key), children: React.createElement(React.Fragment, null,
            React.createElement(Row, { align: "middle", wrap: false, justify: "space-between", className: "header" },
                React.createElement(Col, null,
                    React.createElement(Row, { align: "middle", wrap: false },
                        !props.item.disabled && React.createElement(DragHandle, null),
                        typeof props.item.title === 'string' ? (React.createElement(TextComponent, { marginLeft: 10, children: props.item.title, color: COLORS.gray1 })) : (props.item.title))),
                React.createElement(Col, null, rightAction ? rightAction : React.createElement("div", null))),
            body && React.createElement(ListItemBody, null, body)) }));
});
var ListItemBody = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-top: solid 1px var(--gray-6);\n"], ["\n  border-top: solid 1px var(--gray-6);\n"])));
var ListItemWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n\n  justify-content: space-between;\n  margin-bottom: 5px;\n  z-index: 9999999;\n\n  .header {\n    min-height: 43px;\n    padding-left: 20px;\n  }\n"], ["\n  width: 100%;\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n\n  justify-content: space-between;\n  margin-bottom: 5px;\n  z-index: 9999999;\n\n  .header {\n    min-height: 43px;\n    padding-left: 20px;\n  }\n"])));
var IconImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: grab;\n"], ["\n  cursor: grab;\n"])));
export var SortableList = SortableContainer(function (props) {
    return (React.createElement("div", null, props.items.map(function (item, index) { return (React.createElement(SortableItem, { key: "item-".concat(item.key), index: index, item: item, disabled: item.disabled })); })));
});
var templateObject_1, templateObject_2, templateObject_3;
