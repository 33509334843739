var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Icon, TextComponent } from 'renderer/components';
import Input from 'antd/lib/input/Input';
import { COLORS } from 'renderer/styles';
import styled from 'styled-components';
export var EditableTextInput = function (props) {
    var ref = React.useRef();
    var _a = React.useState(props.value), value = _a[0], setValue = _a[1];
    var _b = React.useState(false), editable = _b[0], setEditable = _b[1];
    React.useEffect(function () {
        if (value !== props.value) {
            setValue(props.value);
        }
    }, [props.value]);
    var handleSave = function () {
        if (props.onSave) {
            props.onSave(value);
        }
        handleToggle();
    };
    var handleBlur = function () {
        setValue(props.value);
        handleToggle();
    };
    var handleChange = function (event) {
        setValue(event.target.value);
    };
    var handleToggle = function () {
        setEditable(!editable);
    };
    return (React.createElement(EditableTextInputWrapper, null,
        editable ? (React.createElement(Input, { ref: ref, onPressEnter: handleSave, onBlur: handleBlur, value: value, onChange: handleChange, suffix: React.createElement(Icon, { name: "corner-down-left", color: COLORS.gray3 }) })) : props.children ? (props.children) : (React.createElement(TextComponent, { children: value || '제목을 입력해주세요.', type: "headline2" })),
        !editable && React.createElement(Icon, { name: "edit", onClick: handleToggle })));
};
var EditableTextInputWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n\n  & > svg {\n    cursor: pointer;\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n\n  & > svg {\n    cursor: pointer;\n    margin-left: 10px;\n  }\n"])));
var templateObject_1;
