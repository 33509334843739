var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { NumberFormatInput } from '../antd/Forms';
import { isNumber } from 'renderer/utils';
import { Icon } from 'renderer/components';
export var NumberInputWithController = function (props) {
    var _a = React.useState(1), value = _a[0], setValue = _a[1];
    var handleChange = function (value) {
        if (props.disabled || !isNumber(value) || value < 1) {
            return;
        }
        setValue(value);
        if (props.onChange) {
            props.onChange(value);
        }
    };
    React.useEffect(function () {
        if (value !== props.value) {
            setValue(props.value || 1);
        }
    }, [props.value]);
    var handleClick = function (type) {
        if (type === 'plus') {
            handleChange(value + 1);
        }
        else {
            return value - 1 > 0 ? handleChange(value - 1) : undefined;
        }
    };
    return (React.createElement(ValueDisplay, { disabled: props.disabled, value: value, onChange: handleChange, size: "small", addonAfter: React.createElement(Addon, { type: "plus", onClick: handleClick }), addonBefore: React.createElement(Addon, { type: "minus", onClick: handleClick }) }));
};
var Addon = function (props) {
    return (React.createElement("div", { onClick: function () { return props.onClick(props.type); } },
        React.createElement(Icon, { name: props.type })));
};
var ValueDisplay = styled(NumberFormatInput)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-input-number-input-wrap {\n    height: 28px;\n    display: flex;\n    align-items: center;\n  }\n\n  .ant-input-number-group-addon {\n    background: white;\n    height: 29px;\n    padding: 6px;\n    cursor: pointer;\n  }\n\n  .ant-input-number {\n    border-left: 0px;\n    border-right: 0px;\n  }\n\n  input {\n    height: 100%;\n    padding-top: 7px;\n  }\n"], ["\n  .ant-input-number-input-wrap {\n    height: 28px;\n    display: flex;\n    align-items: center;\n  }\n\n  .ant-input-number-group-addon {\n    background: white;\n    height: 29px;\n    padding: 6px;\n    cursor: pointer;\n  }\n\n  .ant-input-number {\n    border-left: 0px;\n    border-right: 0px;\n  }\n\n  input {\n    height: 100%;\n    padding-top: 7px;\n  }\n"])));
var templateObject_1;
