var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import Layout from 'antd/lib/layout';
import styled from 'styled-components';
import { MediaQuery } from 'renderer/styles';
import { ReservationDrawer } from 'renderer/containers/app/Reservation/ReservationDrawer';
import { RegistSalesModal } from 'renderer/components';
export var RootContainer = function (props) {
    var backDetector = function (e) {
        console.log('e', e);
        e.stopPropagation();
        e.preventDefault();
    };
    React.useEffect(function () {
        window.addEventListener('popstate', backDetector);
        return function () { return window.removeEventListener('popstate', backDetector); };
    }, []);
    return (React.createElement(RootAppLayout, { style: { minHeight: '100vh' } },
        props.children,
        React.createElement(ReservationDrawer, null),
        React.createElement(RegistSalesModal, null)));
};
var RootAppLayout = styled(Layout)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-height: 100vh;\n  ", "\n"], ["\n  min-height: 100vh;\n  ", "\n"])), MediaQuery.tabletWithMobile(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    overflow: hidden;\n  "], ["\n    overflow: hidden;\n  "]))));
var templateObject_1, templateObject_2;
