var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Row } from 'antd/lib/grid';
import React from 'react';
import { COLORS } from 'renderer/styles';
import styled from 'styled-components';
import { Icon, TextComponent } from 'renderer/components';
var StyledList = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ListItem = function (props) {
    return (React.createElement(StyledListItem, { justify: "space-between", onClick: function () { return !props.disabled && props.onClick(props.value); }, disabled: props.disabled },
        React.createElement(Row, null,
            React.createElement(TextComponent, { children: props.label, type: "body1", color: props.disabled ? COLORS.gray3 : COLORS.black }),
            props.badge ? (React.createElement(StyledBadge, { children: React.createElement(TextComponent, { type: "caption1", children: props.badge, color: COLORS.primary1 }) })) : (React.createElement("div", null))),
        React.createElement(Icon, { name: "right-chevron", size: 24 })));
};
export var ListWithBadge = function (props) {
    var items = props.items;
    return (React.createElement(StyledList, null, items.map(function (item) {
        return React.createElement(ListItem, __assign({ key: item.value, value: item.value }, item));
    })));
};
var StyledListItem = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  padding: 10px;\n  cursor: ", ";\n"], ["\n  border-bottom: 1px solid ", ";\n  padding: 10px;\n  cursor: ", ";\n"])), COLORS.gray7, function (props) { return (props.disabled ? 'normal' : 'pointer'); });
var StyledBadge = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: 11px;\n  background-color: var(--primary-4);\n  width: 35px;\n  height: 22px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-bottom: 2px;\n  margin-left: 5px;\n"], ["\n  border-radius: 11px;\n  background-color: var(--primary-4);\n  width: 35px;\n  height: 22px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-bottom: 2px;\n  margin-left: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
