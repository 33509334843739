var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Lodash } from 'renderer/utils';
export function FormattedNumber(receivedProps) {
    var defaultProps = {
        locale: 'kr-ko',
        options: {}
    };
    var props = __assign(__assign({}, defaultProps), receivedProps);
    return new Intl.NumberFormat(props.locale, props.options).format(props.value);
}
export function KRWFomat(receivedProps) {
    var defaultProps = {
        locale: 'kr-ko',
        options: {
            style: 'currency',
            currency: 'KRW'
        }
    };
    var props = __assign(__assign({}, defaultProps), receivedProps);
    return new Intl.NumberFormat(props.locale, props.options).format(props.value);
}
export function KRWFomatToString(receivedProps) {
    var value = Lodash.isNumber(receivedProps.value) ? receivedProps.value : 0;
    return "".concat(new Intl.NumberFormat().format(value), " \uC6D0");
}
export function KRWFomatWithoutWon(receivedProps) {
    return "".concat(new Intl.NumberFormat().format(receivedProps.value));
}
export * from './i18n';
