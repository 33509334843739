var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { CustomerModel, DEFAULT_VARIABLES, MESSAGE_TYPE_OBJECT } from 'renderer/models';
import Modal from 'antd/lib/modal';
import { createUUID, isEqual, useWindowSize, isNilOrEmptyArray, uploadFileToMessageImageDirectory, getByteLength, isEmpty, orderBy, openWindow } from 'renderer/utils';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
import { BigTitleModal, DatePickerAutoaccept, DialogComponent, FormItemWithoutInput, KRWFomatWithoutWon, Marginer, AntLeftIconButton, TextComponent, useEntireLoader, ZIndexer, checkSMSService, NoticeSentenceWithLink, AdditionalCover, ClickableText, Icon, AntPrimaryGhostButton, AntButton } from 'renderer/components';
import { CustomerQuery, MessageTemplateQuery, StorePaymentMethodQuery, StoreQuery } from 'renderer/queries';
import dayjs from 'dayjs';
import Form from 'antd/lib/form';
import { Col, Row } from 'antd/lib/grid';
import Radio from 'antd/lib/radio';
import Button from 'antd/lib/button';
import { ChargeDrawer } from 'renderer/containers/app/NewStore/StorePointCenter';
import { MessageContentEditor } from 'renderer/containers/app/Message/MessageTemplates';
import Switch from 'antd/lib/switch';
import { AbsoluteCounter, CustomerTable, isValidFilter } from 'renderer/containers/app/Customer';
import { CustomerTableColumns } from 'renderer/containers/app/Customer';
import Select from 'antd/lib/select';
import { getAdText, MessageError } from '../../containers/app/Message/MessageTemplates';
import notification from 'antd/lib/notification';
import { useRecoilState } from 'recoil';
import { Atoms } from 'renderer/stores';
import { DEFAULT_KAKAO_ALIMTALK_PROFILE_KEY, DEFAULT_KAKAO_ALIMTALK_PROFILE_NAME } from 'renderer/constants';
export var SendMessageModal = function (props) {
    var store = props.store;
    var handleClose = function () {
        setOpen(undefined);
        if (props.onClose) {
            props.onClose();
        }
    };
    var _a = useRecoilState(Atoms.globalSettingModalState), _ = _a[0], openSettings = _a[1];
    var handleOpen = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isJoinedPopbill;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkSMSService(props.store, openSettings)];
                case 1:
                    isJoinedPopbill = _a.sent();
                    if (isJoinedPopbill) {
                        setOpen(true);
                    }
                    else {
                        setOpen(false);
                        props.onClose();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    React.useEffect(function () {
        if (props.visible !== open) {
            if (props.visible) {
                handleOpen();
            }
            else {
                setOpen(false);
            }
        }
    }, [props.visible]);
    return (React.createElement(React.Fragment, null,
        props.trigger ? (React.cloneElement(props.trigger, { onClick: handleOpen })) : (React.createElement(AntLeftIconButton, { onClick: handleOpen, children: "\uBB38\uC790\uBC1C\uC1A1", type: "primary", icon: React.createElement(Icon, { name: "send", color: COLORS.white }) })),
        React.createElement(SendingMessageModal, { open: open, store: store, onClose: handleClose, target: props.target })));
};
export var SendingMessageModal = function (props) {
    var _a;
    if (!props.open) {
        return React.createElement("div", null);
    }
    var store = props === null || props === void 0 ? void 0 : props.store;
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var _b = useWindowSize(), height = _b.height, isPC = _b.isPC;
    var loader = useEntireLoader();
    var sendToServer = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    loader.show();
                    return [4 /*yield*/, sendMessage.mutateAsync(payload)];
                case 1:
                    _a.sent();
                    handleClose(true);
                    notification.success({ message: '문자 전송에 성공했습니다.' });
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    notification.error({
                        message: error_1.message
                    });
                    return [3 /*break*/, 4];
                case 3:
                    loader.hide();
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSend = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var contentLength, sendAt, customerIds, image, uploadResult, err_1, title, rest, count, dormancyCustomers, err_2, modal;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    console.log('first handlesend', values);
                    if (!((_a = values.content) === null || _a === void 0 ? void 0 : _a.body)) {
                        return [2 /*return*/, notification.error({
                                message: '메시지를 입력해주세요.'
                            })];
                    }
                    else {
                        contentLength = getByteLength((_b = values.content) === null || _b === void 0 ? void 0 : _b.body);
                        if (contentLength > 2000) {
                            return [2 /*return*/, notification.error({
                                    message: '문자 내용이 2000byte를 초과할 수 없습니다.'
                                })];
                        }
                    }
                    if (values.sendAtMode === 'reservation' && !values.sendAt) {
                        return [2 /*return*/, notification.error({
                                message: '예약전송 시간을 입력해주세요.'
                            })];
                    }
                    if (values.isAd) {
                        sendAt = values.sendAtMode === 'now' ? dayjs() : dayjs(values.sendAt);
                        if (sendAt.isBetween(dayjs().startOf('day').add(21, 'hour'), dayjs().add(1, 'day').startOf('day').add(8, 'hour'))) {
                            return [2 /*return*/, notification.error({
                                    message: '광고메시지 제한',
                                    description: '스팸관련 정보통신망법에 따라 광고성 메세지 야간 전송이 제한됩니다. '
                                })];
                        }
                    }
                    customerIds = values.target.isAll
                        ? ((_d = (_c = totalQuery.data) === null || _c === void 0 ? void 0 : _c.ids) === null || _d === void 0 ? void 0 : _d.filter(function (id) { var _a, _b; return !((_b = (_a = values.target) === null || _a === void 0 ? void 0 : _a.customerIds) === null || _b === void 0 ? void 0 : _b.includes(id)); })) || []
                        : ((_e = values.target) === null || _e === void 0 ? void 0 : _e.customerIds) || [];
                    if (!((countWithType === null || countWithType === void 0 ? void 0 : countWithType.count) > 0)) {
                        return [2 /*return*/, notification.error({ message: '대상이 없습니다.', description: '문자를 보낼 대상을 선택해주세요.' })];
                    }
                    image = (_f = values.content) === null || _f === void 0 ? void 0 : _f.image;
                    loader.show();
                    if (!(image && typeof image !== 'string' && image[0] && image[0].originFileObj)) return [3 /*break*/, 4];
                    _g.label = 1;
                case 1:
                    _g.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, uploadFileToMessageImageDirectory(image[0].originFileObj, createUUID())];
                case 2:
                    uploadResult = _g.sent();
                    image = uploadResult.location;
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _g.sent();
                    loader.hide();
                    return [2 /*return*/, notification.error({
                            message: "\uC774\uBBF8\uC9C0\uC5C5\uB85C\uB4DC \uC911 \uC5D0\uB7EC \uBC1C\uC0DD \uAD00\uB9AC\uC790\uC5D0\uAC8C \uBB38\uC758\uD574\uC8FC\uC138\uC694 ".concat(JSON.stringify(err_1))
                        })];
                case 4:
                    title = values.title, rest = __rest(values, ["title"]);
                    count = (countWithType === null || countWithType === void 0 ? void 0 : countWithType.count) || 0;
                    if (!values.isAd) return [3 /*break*/, 8];
                    _g.label = 5;
                case 5:
                    _g.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, MessageTemplateQuery.checkDormancy({
                            meta: {},
                            queryKey: [
                                '',
                                {
                                    storeId: storeId,
                                    isAd: values === null || values === void 0 ? void 0 : values.isAd,
                                    customerIds: customerIds,
                                    isAll: false
                                }
                            ]
                        })];
                case 6:
                    dormancyCustomers = _g.sent();
                    return [3 /*break*/, 8];
                case 7:
                    err_2 = _g.sent();
                    loader.hide();
                    return [2 /*return*/, notification.error({
                            message: "\uC218\uC2E0\uC815\uBCF4 \uD655\uC778 \uC911 \uC5D0\uB7EC \uBC1C\uC0DD \uAD00\uB9AC\uC790\uC5D0\uAC8C \uBB38\uC758\uD574\uC8FC\uC138\uC694 ".concat(JSON.stringify(err_2))
                        })];
                case 8:
                    loader.hide();
                    modal = Modal.warn({
                        title: '문자 전송 확인',
                        autoFocusButton: null,
                        content: "\uACE0\uAC1D(".concat(count, "\uBA85)\uC5D0\uAC8C \uBB38\uC790\uB97C \uC804\uC1A1\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?"),
                        cancelText: '아니오',
                        cancelButtonProps: {
                            onClick: function () {
                                modal.destroy();
                            }
                        },
                        okText: '네',
                        onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var payload, dormancyCustomerIds_1, adConfirmModal_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        payload = __assign(__assign({ storeId: storeId }, rest), { sendAt: values.sendAtMode === 'now' ? undefined : values.sendAt, content: image ? __assign(__assign({}, rest.content), { image: image }) : rest.content, target: {
                                                isAll: false,
                                                customerIds: customerIds
                                            } });
                                        if (!!isNilOrEmptyArray(dormancyCustomers)) return [3 /*break*/, 1];
                                        dormancyCustomerIds_1 = dormancyCustomers.map(function (item) { return item.id; });
                                        adConfirmModal_1 = Modal.warn({
                                            title: '광고문자 발송 확인',
                                            autoFocusButton: null,
                                            content: (React.createElement("div", null,
                                                React.createElement(TextComponent, { children: "\uC815\uBCF4\uD1B5\uC2E0\uB9DD\uBC95 \uC81C 61\uC870\uC5D0 \uB530\uB77C \uCD5C\uADFC \uC7AC\uD654\uAC70\uB798(\uB9E4\uCD9C\uB4F1\uB85D)\uC77C\uC774 6\uAC1C\uC6D4\uC774 \uC9C0\uB09C \uACE0\uAC1D\uC758 \uACBD\uC6B0 \uAD11\uACE0\uBB38\uC790 \uBC1C\uC1A1\uC774 \uBD88\uAC00\uB2A5\uD569\uB2C8\uB2E4. \uADF8\uB798\uB3C4 \uBC1C\uC1A1\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?" }),
                                                React.createElement(TextComponent, { color: COLORS.calendarRedActive, children: "\uAD11\uACE0\uBB38\uC790 \uBC1C\uC1A1 \uBD88\uAC00\uB2A5 \uACE0\uAC1D : ".concat(dormancyCustomers.length, "\uBA85"), marginTop: 10, marginBottom: 10 }),
                                                React.createElement("div", { style: { overflow: 'overlay' } },
                                                    React.createElement(TextComponent, { children: dormancyCustomers.map(function (customer) { return customer.name; }).join(', ') })))),
                                            cancelText: '그냥 발송',
                                            cancelButtonProps: {
                                                onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0: return [4 /*yield*/, sendToServer(payload)];
                                                            case 1:
                                                                _a.sent();
                                                                adConfirmModal_1.destroy();
                                                                return [2 /*return*/];
                                                        }
                                                    });
                                                }); }
                                            },
                                            okText: '제외하고 발송',
                                            onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                var newCustomerIds;
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            newCustomerIds = customerIds.filter(function (id) { return !dormancyCustomerIds_1.includes(id); }) || [];
                                                            if (!(newCustomerIds.length === 0)) return [3 /*break*/, 1];
                                                            notification.info({ message: '발송대상이 없습니다.' });
                                                            return [3 /*break*/, 3];
                                                        case 1: return [4 /*yield*/, sendToServer(__assign(__assign({}, payload), { target: {
                                                                    isAll: false,
                                                                    customerIds: newCustomerIds
                                                                } }))];
                                                        case 2:
                                                            _a.sent();
                                                            _a.label = 3;
                                                        case 3:
                                                            adConfirmModal_1.destroy();
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); },
                                            zIndex: 9999,
                                            okCancel: true,
                                            centered: true,
                                            closable: true
                                        });
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, sendToServer(payload)];
                                    case 2:
                                        _a.sent();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); },
                        zIndex: 9999,
                        okCancel: true,
                        centered: true,
                        closable: true
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var sendMessage = MessageTemplateQuery.useSendMessage({
        onError: function (error) {
            throw new Error(error.message);
        }
    });
    var messageSendInfo = props.store.messageSendInfo;
    var DEFAULT_VALUES = {
        id: undefined,
        sender: messageSendInfo.senderNumber,
        content: {
            body: ''
        },
        target: {
            isAll: false
        },
        sendMessageOnFailAlimtalk: true,
        type: 'message',
        isAd: true,
        sendAtMode: 'now',
        sendAt: dayjs().add(1, 'hour')
    };
    var form = Form.useForm()[0];
    var targetInForm = form.getFieldValue('target');
    var totalQuery = StoreQuery.useGetSMSAvailableIds({ filter: targetInForm === null || targetInForm === void 0 ? void 0 : targetInForm.filters, search: targetInForm === null || targetInForm === void 0 ? void 0 : targetInForm.keyword });
    var isAvailableUpdate = function () {
        return (
        // values?.title !== selectedTemplate?.title ||
        // values?.content?.body !== selectedTemplate?.content?.body ||
        // values?.isAd !== selectedTemplate?.isAd ||
        // values?.emoji !== selectedTemplate?.emoji
        true);
    };
    var handleClose = function (force) {
        if (force === true) {
            props.onClose();
        }
        else {
            if (!isAvailableUpdate()) {
                return props.onClose();
            }
            var modal_1 = Modal.warn({
                title: '문자 전송취소',
                content: "\uBB38\uC790 \uC804\uC1A1\uC744 \uC911\uB2E8\uD558\uC2DC\uACA0\uC5B4\uC694?",
                cancelText: '아니오',
                cancelButtonProps: {
                    onClick: function () {
                        modal_1.destroy();
                    }
                },
                okText: '네',
                onOk: function () {
                    handleClose(true);
                    modal_1.destroy();
                },
                zIndex: 9999,
                okCancel: true,
                centered: true,
                closable: true
            });
        }
    };
    var bodyStyle = isPC ? { height: '650px' } : { height: "".concat(height - 150, "px"), overflow: 'scroll' };
    var _c = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _c[1];
    var setCountWithTypeFunction = function () {
        var _a, _b, _c;
        var target = form.getFieldValue(['target']);
        var count = (target === null || target === void 0 ? void 0 : target.isAll)
            ? (((_b = (_a = totalQuery === null || totalQuery === void 0 ? void 0 : totalQuery.data) === null || _a === void 0 ? void 0 : _a.ids) === null || _b === void 0 ? void 0 : _b.length) || 0) - (((_c = target === null || target === void 0 ? void 0 : target.customerIds) === null || _c === void 0 ? void 0 : _c.length) || 0)
            : ((target === null || target === void 0 ? void 0 : target.customerIds) || []).length;
        var hasImage = form.getFieldValue(['content', 'image']);
        var type = form.getFieldValue('type');
        var body = form.getFieldValue(['content', 'body']);
        var isAd = form.getFieldValue(['isAd']);
        var typeString = '';
        if (type === 'message') {
            var bytes = getByteLength(body + (isAd ? getAdText(props.store) : ''));
            typeString = hasImage ? 'mms' : bytes >= 90 ? 'lms' : 'sms';
        }
        else {
            typeString = hasImage ? 'fms' : 'fts';
        }
        setCountWithType({ type: typeString, count: count });
        forceUpdate();
    };
    var _d = React.useState({ type: 'sms', count: 0 }), countWithType = _d[0], setCountWithType = _d[1];
    React.useEffect(function () {
        if (props.open) {
            form.setFieldsValue(__assign(__assign({}, DEFAULT_VALUES), { sender: messageSendInfo.senderNumber, target: props.target }));
            forceUpdate();
            setCountWithTypeFunction();
        }
    }, [props.open]);
    var sendAtMode = form.getFieldValue('sendAtMode');
    React.useEffect(function () {
        setCountWithTypeFunction();
    }, [(_a = totalQuery.data) === null || _a === void 0 ? void 0 : _a.ids]);
    var hasSubscription = store.hasSubscription;
    var shownPreventPage = !hasSubscription && form.getFieldValue('type') === 'fts';
    return (React.createElement(BigTitleModal, { centered: true, bodyStyle: bodyStyle, open: props.open, onCancel: handleClose, okText: sendAtMode === 'reservation' ? '문자발송예약' : '문자발송', onOk: form.submit, cancelText: "\uB2EB\uAE30", title: "\uBB38\uC790\uBC1C\uC1A1", children: React.createElement("div", { style: { padding: '15px 20px' } },
            React.createElement(Form, { initialValues: DEFAULT_VALUES, form: form, onValuesChange: function (changed, value) {
                    setCountWithTypeFunction();
                }, onFinish: handleSend },
                React.createElement(Row, { gutter: isPC ? 16 : 0, style: { width: '100%' } },
                    React.createElement(HalfWrapper, { span: isPC ? 12 : 24 },
                        React.createElement(AdNotice, null),
                        React.createElement(Marginer, { height: 12 }),
                        React.createElement(SelectMessageType, { form: form, hideType: ['ats'], store: props.store }),
                        React.createElement(Marginer, { height: 12 }),
                        shownPreventPage ? (React.createElement("div", { style: { position: 'relative', height: '400px' } },
                            React.createElement(AdditionalCover, { store: store, type: "fts" }))) : (React.createElement(MessageContentEditor, { visible: props.open, variables: DEFAULT_VARIABLES, onChangeContent: setCountWithTypeFunction, availables: {
                                templateSaver: true,
                                templateSelector: true
                            }, form: form }))),
                    !shownPreventPage && (React.createElement(HalfWrapper, { span: isPC ? 12 : 24 },
                        React.createElement(SelectCustomerMode, { selectedCount: countWithType.count, target: form.getFieldValue('target'), store: store, bodyStyle: bodyStyle, onChange: function (target) {
                                form.setFieldsValue({ target: target });
                                setCountWithTypeFunction();
                            } }),
                        React.createElement(Form.Item, { name: "target", style: { display: 'none' } },
                            React.createElement("input", null)),
                        React.createElement(Marginer, { height: 12 }),
                        React.createElement(SelectSendAt, { form: form }),
                        React.createElement(Marginer, { height: 12 }),
                        React.createElement(StorePointDisplayer, __assign({ form: form }, countWithType, { store: store }))))))), width: 1200 }));
};
export var AdNotice = function (props) {
    var items = [
        {
            title: '정보통신법에 적합한 문자를 작성해주세요 >',
            url: 'https://oxidized-duke-790.notion.site/aadfffa46f334dabb320dea137d316c0'
        },
        {
            title: '등록한 발신번호가 차단서비스에 가입되었는지 확인해주세요 >',
            url: 'https://oxidized-duke-790.notion.site/6ed3c6caef8e4a4c85dc3d211c2de88d'
        },
        {
            title: '#{고객명} 변수 입력시 꼭 주의해주세요 >',
            url: 'https://oxidized-duke-790.notion.site/0e6b46220b744ae4bfc0aa45a1f693b0'
        }
    ];
    return (React.createElement(React.Fragment, null, items.map(function (item) {
        return React.createElement(NoticeSentenceWithLink, { key: item.title, title: item.title, url: item.url });
    })));
};
export var SelectMessageType = function (props) {
    var _a, _b, _c, _d, _e;
    var hideType = props.hideType || [];
    var availableAlimtalk = !!((_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.messageSendInfo) === null || _b === void 0 ? void 0 : _b.senderNumber);
    var availableFriendTalk = !!props.store.kakaoSendInfo;
    var types = [
        { value: 'message', label: '문자' },
        { value: 'fts', label: '친구톡', disabled: !availableFriendTalk || ((_c = props.disabledType) === null || _c === void 0 ? void 0 : _c.includes('fts')) },
        { value: 'ats', label: '알림톡', disabled: !availableAlimtalk || ((_d = props.disabledType) === null || _d === void 0 ? void 0 : _d.includes('ats')) }
    ];
    var isGroupProfile = props.form.getFieldValue(['content', 'profileKey']) === DEFAULT_KAKAO_ALIMTALK_PROFILE_KEY;
    var templateKey = props.form.getFieldValue(['content', 'templateCode']);
    var isGroupTemplate = templateKey === null || templateKey === void 0 ? void 0 : templateKey.includes('GAT');
    var profiles = [(_e = props.store) === null || _e === void 0 ? void 0 : _e.kakaoSendInfo]
        .concat(isGroupTemplate &&
        {
            plusFriendID: DEFAULT_KAKAO_ALIMTALK_PROFILE_NAME,
            searchName: "@".concat(DEFAULT_KAKAO_ALIMTALK_PROFILE_NAME),
            profileKey: DEFAULT_KAKAO_ALIMTALK_PROFILE_KEY
        })
        .filter(Boolean);
    var _f = React.useState(props.form.getFieldValue(['type']) !== 'message'), isVisible = _f[0], setIsVisible = _f[1];
    var isAlimtalk = props.form.getFieldValue(['type']) === 'ats';
    React.useEffect(function () {
        setIsVisible(props.form.getFieldValue(['type']) !== 'message');
    }, [props.form.getFieldValue('type')]);
    return (React.createElement(FormItemWithGrayBoarder, { title: "\uBB38\uC790\uD0C0\uC785\uC124\uC815" },
        React.createElement(Form.Item, { name: ['type'] },
            React.createElement(Radio.Group, { onChange: function (e) {
                    setIsVisible(e.target.value !== 'message');
                } }, types
                .filter(function (item) { return !hideType.includes(item.value); })
                .map(function (item) { return (React.createElement(Radio, { disabled: item.disabled, value: item.value, key: item.value }, item.label)); }))),
        isAlimtalk && !isEmpty(profiles) && (React.createElement(React.Fragment, null,
            React.createElement(Form.Item, { name: ['content', 'profileKey'], rules: [{ required: true, message: '알림톡을 발송할 발신프로필을 선택해주세요.' }] },
                React.createElement(Select, { optionLabelProp: "label" }, profiles.map(function (profile) {
                    var label = "".concat(profile.searchName, " \uCC44\uB110\uB85C \uBC1C\uC1A1");
                    return (React.createElement(Select.Option, { key: profile.profileKey, label: label, children: label, value: profile.profileKey }));
                }))),
            props.form.getFieldValue(['content', 'profileKey']) === DEFAULT_KAKAO_ALIMTALK_PROFILE_KEY && (React.createElement(ClickableText, { textAlign: "left", marginTop: -5, marginBottom: 10, onClick: function () {
                    openWindow("https://help.thinkofyou.kr/6688edd2-fc36-4dae-bc64-75009a0c844e", '팅커뷰로 발송시 주의사항');
                }, type: "caption1", children: "@\uD305\uCEE4\uBDF0\uC54C\uB9BC\uCC44\uB110\uB85C \uC54C\uB9BC\uD1A1\uC744 \uC804\uC1A1\uD558\uC2E4 \uACBD\uC6B0 \uBC18\uB4DC\uC2DC \uD655\uC778\uD574\uC8FC\uC138\uC694 >", color: COLORS.primary1 })))),
        isAlimtalk && !isEmpty(props.atsTemplates) && (React.createElement(Form.Item, { name: ['content', 'templateCode'], rules: [{ required: true, message: '발송하고자 하는 템플릿을 선택해주세요.' }] },
            React.createElement(Select, { optionLabelProp: "label" }, orderBy(props.atsTemplates.filter(function (template) { var _a, _b; return !isGroupProfile || ((_b = (_a = template === null || template === void 0 ? void 0 : template.content) === null || _a === void 0 ? void 0 : _a.templateCode) === null || _b === void 0 ? void 0 : _b.startsWith('G')); }), 'title', 'asc').map(function (template) {
                return (React.createElement(Select.Option, { key: template.content.templateCode, label: template.title, children: template.title, value: template.content.templateCode }));
            })))),
        isVisible && (React.createElement(Row, { align: "middle" },
            React.createElement(Form.Item, { name: ['sendMessageOnFailAlimtalk'] },
                React.createElement(Switch, { checked: props.form.getFieldValue('sendMessageOnFailAlimtalk') })),
            React.createElement(TextComponent, { children: "\uD1A1 \uBC1C\uC1A1 \uC2E4\uD328\uC2DC \uBB38\uC790 \uB300\uCCB4 \uBC1C\uC1A1", marginLeft: 12, marginBottom: 10 })))));
};
var SelectCustomerMode = function (props) {
    var target = props.target;
    var _a = React.useState(false), customerModal = _a[0], setCustomerModal = _a[1];
    return (React.createElement(FormItemWithGrayBoarder, { title: "\uBC1B\uB294 \uC0AC\uB78C" },
        React.createElement(Row, { align: "middle", style: { width: '100%', marginLeft: '20px' } },
            React.createElement(TextComponent, { type: "body1", color: COLORS.primary1, children: "".concat(props.selectedCount || 0, "\uBA85 \uC120\uD0DD"), marginRight: 12 }),
            React.createElement(AntPrimaryGhostButton, { children: "\uC218\uC815", onClick: function (ev) {
                    setCustomerModal(true);
                    ev.preventDefault();
                    ev.stopPropagation();
                } })),
        React.createElement(SelectCustomers, { store: props.store, bodyStyle: props.bodyStyle, visible: customerModal, onChange: function (target) {
                props.onChange(target);
            }, onClose: function () { return setCustomerModal(false); }, target: target })));
};
var SelectSendAt = function (props) {
    var types = [
        { value: 'now', label: '즉시' },
        { value: 'reservation', label: '예약 전송' }
    ];
    var namePath = ['sendAtMode'];
    var _a = React.useState(props.form.getFieldValue(namePath) === 'reservation'), isVisible = _a[0], setIsVisible = _a[1];
    return (React.createElement(FormItemWithGrayBoarder, { title: "\uC804\uC1A1 \uC2DC\uAC04" },
        React.createElement(Form.Item, { name: namePath },
            React.createElement(Radio.Group, { onChange: function (e) {
                    setIsVisible(e.target.value === 'reservation');
                } }, types.map(function (item) { return (React.createElement(Radio, { value: item.value, key: "".concat(item.value) }, item.label)); }))),
        isVisible && (React.createElement(Form.Item, { name: 'sendAt' },
            React.createElement(DatePickerAutoaccept, { inputReadOnly: true, allowClear: false, disabledDate: function (currentDate) { return dayjs().isAfter(currentDate, 'minute'); }, format: "YYYY-MM-DD HH:mm", showTime: true }))),
        React.createElement(FormItemWithoutInput, { name: ['sendAt'], rules: [
                {
                    validator: function (_, value) {
                        var isAd = props.form.getFieldValue(['isAd']);
                        if (!isAd) {
                            return Promise.resolve();
                        }
                        var type = props.form.getFieldValue(namePath);
                        var sendAt = type === 'now' ? dayjs() : dayjs(value);
                        var sendAtHour = sendAt.hour();
                        return sendAtHour > 21 || sendAtHour < 8 ? Promise.reject(new Error('error')) : Promise.resolve();
                    },
                    message: React.createElement(MessageError, { error: "\uC624\uD6C4 9\uC2DC~ \uC624\uC804 8\uC2DC \uC0AC\uC774\uC5D0\uB294 \uAD11\uACE0\uC131 \uBA54\uC138\uC9C0\uB97C \uBC1C\uC1A1\uC774 \uC81C\uD55C\uB429\uB2C8\uB2E4. " })
                }
            ] })));
};
var StorePointDisplayer = function (props) {
    var _a, _b, _c, _d, _e;
    var namePath = ['sendAtMode'];
    var _f = React.useState(props.form.getFieldValue(namePath) === 'reservation'), isVisible = _f[0], setIsVisible = _f[1];
    var pointQuery = StorePaymentMethodQuery.useStorePoint((_a = props.store) === null || _a === void 0 ? void 0 : _a.id, { enabled: !!((_b = props.store) === null || _b === void 0 ? void 0 : _b.id) });
    var renderRow = function (item) {
        var _a = item.textType, textType = _a === void 0 ? 'caption1' : _a, right = item.right, left = item.left, _b = item.rightUnit, rightUnit = _b === void 0 ? '원' : _b, key = item.key;
        return (React.createElement(Row, { justify: "space-between", align: "middle", key: key },
            React.createElement(Col, null, typeof item.left === 'string' ? React.createElement(TextComponent, { type: textType, children: left }) : left),
            React.createElement(Col, null,
                React.createElement(TextComponent, { type: textType, children: "".concat(KRWFomatWithoutWon({ value: right || 0 })).concat(rightUnit) }))));
    };
    var price = ((_c = MESSAGE_TYPE_OBJECT[props.type]) === null || _c === void 0 ? void 0 : _c.price) || 0;
    var label = ((_d = MESSAGE_TYPE_OBJECT[props.type]) === null || _d === void 0 ? void 0 : _d.label) || '';
    var items = [
        {
            textType: 'headline3',
            left: (React.createElement(Row, null,
                React.createElement(TextComponent, { type: "headline3", children: "\uC794\uC5EC \uBB38\uC790 \uD3EC\uC778\uD2B8", marginRight: 5 }),
                React.createElement(Button, { type: "primary", size: "small", children: "\uCDA9\uC804", onClick: function () { return setIsVisible(true); } }))),
            right: (_e = pointQuery === null || pointQuery === void 0 ? void 0 : pointQuery.data) === null || _e === void 0 ? void 0 : _e.amount,
            key: 'remain'
        },
        {
            left: "\uAC74\uB2F9(".concat(label, ")"),
            right: price,
            key: 'price'
        },
        {
            left: "\uBC1C\uC1A1\uAC74\uC218",
            right: props.count,
            key: 'count',
            rightUnit: '건'
        },
        {
            textType: 'headline3',
            left: "\uC608\uC0C1 \uBC1C\uC0DD\uBE44\uC6A9",
            right: props.count * price,
            key: 'willPaid'
        }
    ];
    return (React.createElement(Graybox, null,
        React.createElement(ChargeDrawer, { onClose: function () { return setIsVisible(false); }, visible: isVisible, store: props.store }),
        items.map(renderRow),
        React.createElement(FormItemWithoutInput, { name: ['point'], rules: [
                {
                    validator: function (_) {
                        var _a;
                        var total = ((_a = pointQuery.data) === null || _a === void 0 ? void 0 : _a.amount) || 0;
                        var needPoint = props.count * price || 0;
                        if (total >= needPoint) {
                            return Promise.resolve();
                        }
                        else {
                            return Promise.reject(new Error('error'));
                        }
                    },
                    message: React.createElement(MessageError, { error: "\uBB38\uC790\uD3EC\uC778\uD2B8\uAC00 \uBD80\uC871\uD569\uB2C8\uB2E4. \uD3EC\uC778\uD2B8\uB97C \uCDA9\uC804\uD574\uC8FC\uC138\uC694." })
                }
            ] })));
};
export var HalfWrapper = styled(Col)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  & > * {\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  & > * {\n    width: 100%;\n  }\n"])));
var Graybox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: auto;\n  background-color: ", ";\n  border-radius: 2px;\n  padding: 14px;\n\n  & > div {\n    width: 100%;\n    margin-bottom: 10px;\n  }\n"], ["\n  height: auto;\n  background-color: ", ";\n  border-radius: 2px;\n  padding: 14px;\n\n  & > div {\n    width: 100%;\n    margin-bottom: 10px;\n  }\n"])), COLORS.gray7);
export var FormItemWithGrayBoarder = function (props) {
    return (React.createElement(GrayBoarderBox, null,
        React.createElement(TextComponent, { children: props.title, type: "headline3", marginBottom: 8 }),
        props.children));
};
var GrayBoarderBox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 14px;\n  border-radius: 4px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n"], ["\n  padding: 14px;\n  border-radius: 4px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n"])));
export var SelectCustomers = function (props) {
    var _a, _b, _c, _d, _e, _f;
    if (!props.visible) {
        return React.createElement("div", null);
    }
    var _g = React.useState(props.target), target = _g[0], setTarget = _g[1];
    var handleClose = function () {
        setTarget(props.target);
        props.onClose();
    };
    var handleSubmit = function () {
        props.onChange(target);
        props.onClose();
    };
    var store = props.store;
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var filterOptions = [{ value: 'all', label: '전체' }].concat(((store === null || store === void 0 ? void 0 : store.filters) || []).map(function (item) { return ({ label: item.name, value: item.id }); }));
    React.useEffect(function () {
        var _a, _b;
        if (props.visible) {
            setTarget(props.target);
            setNewPagerOptions({
                page: 1,
                limit: 10,
                storeId: storeId,
                keyword: (_a = props.target) === null || _a === void 0 ? void 0 : _a.keyword,
                filter: (_b = props.target) === null || _b === void 0 ? void 0 : _b.filters
            });
        }
    }, [props.visible]);
    var handleTabChange = function (tabName) {
        var _a, _b;
        var data = __assign(__assign({}, target), { customerIds: [], isAll: false });
        if (tabName === 'all') {
            data = __assign(__assign({}, data), { filters: undefined, keyword: undefined });
        }
        if (store.filters) {
            var filter = store.filters.find(function (filter) { return filter.id === tabName; });
            data = __assign(__assign({}, data), { filters: filter, keyword: undefined });
        }
        if (!isEqual(target.filters, data.filters)) {
            var andList = ((_b = (_a = data.filters) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.and) || [];
            var isValid = isValidFilter(andList);
            if (!isValid) {
                return;
            }
            setNewPagerOptions({
                page: 1,
                limit: 10,
                storeId: storeId,
                filter: data.filters,
                keyword: data.keyword
            });
            setTarget(data);
        }
    };
    var _h = CustomerQuery.useCustomerPager({
        page: 1,
        limit: 10,
        storeId: storeId,
        search: '',
        filter: target.filters
    }, { enabled: !!storeId }), pagerResult = _h.pagerResult, isLoading = _h.isLoading, getPaginateObject = _h.getPaginateObject, setNewPagerOptions = _h.setNewPagerOptions;
    var totalQuery = StoreQuery.useGetSMSAvailableIds({ filter: target.filters, search: target.keyword });
    var totalCount = ((_b = (_a = totalQuery.data) === null || _a === void 0 ? void 0 : _a.ids) === null || _b === void 0 ? void 0 : _b.length) || 0;
    var selectedCount = target.isAll
        ? totalCount - (((_c = target === null || target === void 0 ? void 0 : target.customerIds) === null || _c === void 0 ? void 0 : _c.length) || 0)
        : ((_d = target === null || target === void 0 ? void 0 : target.customerIds) === null || _d === void 0 ? void 0 : _d.length) || 0;
    var tab = ((_e = target.filters) === null || _e === void 0 ? void 0 : _e.id) || 'all';
    return (React.createElement(WithoutPaddingModal, { width: 1200, onClose: props.onClose, title: "\uBB38\uC790\uC804\uC1A1 \uB300\uC0C1 \uC120\uD0DD", bodyStyle: props.bodyStyle, content: React.createElement(CustomerSelectorWrapper, null,
            React.createElement(Row, { gutter: 8 },
                React.createElement(Col, { span: 6 },
                    React.createElement(TextComponent, { color: COLORS.gray3, type: "subtitle1", children: "\uD544\uD130 \uC120\uD0DD" })),
                React.createElement(Col, { span: 6 },
                    React.createElement(FullWidthSelector, { options: filterOptions, value: tab, onSelect: handleTabChange }))),
            React.createElement(Marginer, { height: 20 }),
            React.createElement(CustomerTable, { scroll: { y: 500 }, pagination: getPaginateObject(), columns: CustomerTableColumns(false), dataSource: (_f = pagerResult === null || pagerResult === void 0 ? void 0 : pagerResult.items) === null || _f === void 0 ? void 0 : _f.map(function (item) { return new CustomerModel(__assign(__assign({}, item), { key: item.id })); }), loading: isLoading, selectData: target, selectedCount: selectedCount, onSelectDataChange: function (data) {
                    setTarget(__assign(__assign({}, target), data));
                } }),
            React.createElement(AbsoluteCounter, { selected: selectedCount, total: pagerResult.totalCount || 0 })), visible: props.visible, actions: [
            React.createElement(Row, { justify: "end", style: { height: '40px' }, align: "bottom" },
                React.createElement(AntPrimaryGhostButton, { children: "\uCDE8\uC18C", onClick: handleClose }),
                React.createElement(AntButton, { children: "\uACE0\uAC1D\uC120\uD0DD \uC644\uB8CC", onClick: handleSubmit, type: "primary" }))
        ] }));
};
var WithoutPaddingModal = function (props) {
    return props.visible ? (React.createElement(ZIndexer, null, function (_a) {
        var zIndex = _a.zIndex;
        return React.createElement(StyledWithoutPaddingModal, __assign({}, props, { zIndex: zIndex }));
    })) : (React.createElement("div", null));
};
var StyledWithoutPaddingModal = styled(DialogComponent)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  & .ant-modal-body {\n    padding: 0px;\n  }\n"], ["\n  & .ant-modal-body {\n    padding: 0px;\n  }\n"])));
var FullWidthSelector = styled(Select)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var CustomerSelectorWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n  padding: 50px 25px;\n"], ["\n  flex: 1;\n  padding: 50px 25px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
