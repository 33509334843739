var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { KRWFomatToString, CustomerTextButton, ManagerPermissionContent, TableSetterDefaultTrigger, FilterWithSearcher, XScrollableTable, SALES_CONTROLLER_KEYS } from 'renderer/components';
import { PaymentHistoryQuery, ManagerQuery, StoreQuery } from 'renderer/queries';
import * as dayjs from 'dayjs';
import { MANAGER_PERMISSION_ACTIONS, MANAGER_PERMISSION_ACTIONS_DURATION, MANAGER_PERMISSION_KEYS, MANAGER_PERMISSION_PRIORITY } from 'renderer/models';
import { DATE_FORMAT, DATE_FORMAT_INCLUDE_TIME } from 'renderer/constants';
import Row from 'antd/lib/row';
import styled from 'styled-components';
import { useSaleModal } from 'renderer/stores';
import { useEntireLoader } from 'renderer/components';
export var SALES_TABLE_COLUMN_OBJECT = {
    paidAt: {
        title: '판매일시',
        dataIndex: 'paidAt',
        key: 'paidAt',
        width: 140,
        sorter: true,
        render: function (text) { return dayjs(text).format(DATE_FORMAT_INCLUDE_TIME); }
    },
    type: {
        title: '매출 유형',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: function (_, record) { return (record.typeString === '예약금' ? '상품' : record.typeString); }
    },
    // location: {
    //   title: '구매장소',
    //   dataIndex: 'location',
    //   key: 'location',
    //   width: 80,
    //   render: (_, record) => record.locationToString
    // },
    manager: {
        title: '담당자',
        dataIndex: 'manager',
        key: 'manager',
        width: 100,
        render: function (_, record) { return record.managerNames; }
    },
    customerName: {
        title: '고객명',
        width: 120,
        dataIndex: 'customerName',
        key: 'customerName',
        render: function (_, record) { return (React.createElement(CustomerTextButton, { type: "caption1", customer: record.customer, isDeleted: !!record.customerId })); }
    },
    ticket: {
        title: '판매목록',
        dataIndex: 'ticket',
        key: 'ticket',
        width: 200,
        render: function (_, record) { var _a; return record.saleListString || ((_a = record.data) === null || _a === void 0 ? void 0 : _a.prevSalesContent) || record.typeString; }
    },
    totalPrice: {
        title: '총 영업액',
        dataIndex: 'totalPrice',
        width: 100,
        key: 'totalPrice',
        render: function (_, record) { return KRWFomatToString({ value: record.usingTotalPrice }); }
    },
    deduction: {
        title: '차감',
        dataIndex: 'deduction',
        width: 100,
        key: 'deduction',
        render: function (_, record) { return record.deductionString; }
    },
    totalPriceForTicket: {
        title: '이용료 합계',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        width: 120,
        render: function (_, record) { return KRWFomatToString({ value: record.usingTotalPrice }); }
    },
    deductionPriceByTicket: {
        title: '정기권 차감',
        dataIndex: 'deductionPriceByTicket',
        key: 'deductionPriceByTicket',
        width: 100,
        render: function (_, record) { return KRWFomatToString({ value: record.deductionPriceByTicket }); }
    },
    usedPrepaidPoint: {
        title: '선불액 차감',
        dataIndex: 'usedPrepaidPoint',
        key: 'usedPrepaidPoint',
        width: 100,
        render: function (_, record) { return KRWFomatToString({ value: record.usedPrepaidPoint }); }
    },
    usedCustomerPoint: {
        title: '포인트 차감',
        dataIndex: 'usedCustomerPoint',
        key: 'usedCustomerPoint',
        width: 100,
        render: function (_, record) { return KRWFomatToString({ value: record.usedCustomerPoint }); }
    },
    paidPrice: {
        title: '실매출액',
        width: 100,
        dataIndex: 'amount',
        key: 'amount',
        render: function (_, record) { return KRWFomatToString({ value: record.paidPrice || 0 }); }
    },
    methods: {
        title: '결제수단',
        dataIndex: 'methods',
        key: 'methods',
        width: 120,
        render: function (_, record) { return record.methodsString; }
    },
    createCustomerPoint: {
        title: '적립포인트',
        dataIndex: 'createCustomerPoint',
        key: 'createCustomerPoint',
        width: 100,
        render: function (_, record) { return KRWFomatToString({ value: record.createCustomerPoint }); }
    },
    memo: {
        title: '메모',
        dataIndex: 'memo',
        key: 'memo',
        width: 200,
        render: function (memo, record) { return memo; }
    },
    ticketStatus: {
        title: '정기권 상태',
        key: 'ticketStatus',
        width: 120,
        render: function (_, record) { var _a; return (_a = record.ticket) === null || _a === void 0 ? void 0 : _a.getStatusToString(); }
    },
    ticketType: {
        title: '정기권 유형',
        key: 'ticketType',
        width: 120,
        render: function (_, record) { var _a, _b; return (_b = (_a = record.ticket) === null || _a === void 0 ? void 0 : _a.copiedTicketTemplate) === null || _b === void 0 ? void 0 : _b.getTypeToString(); }
    },
    ticketExpiredAt: {
        title: '유효기간',
        key: 'ticketExpiredAt',
        width: 100,
        render: function (_, record) { var _a; return dayjs((_a = record.ticket) === null || _a === void 0 ? void 0 : _a.expiredAt).format(DATE_FORMAT); }
    },
    ticketRemain: {
        title: '잔여정보',
        key: 'ticketRemain',
        width: 100,
        render: function (_, record) { var _a; return (_a = record.ticket) === null || _a === void 0 ? void 0 : _a.getTicketDataToString(); }
    }
};
export var getSalesTableColumns = function (columns, deadLine) {
    return columns.map(function (columnKey) {
        var originalColumn = SALES_TABLE_COLUMN_OBJECT[columnKey];
        var originalRender = originalColumn === null || originalColumn === void 0 ? void 0 : originalColumn.render;
        if (originalRender) {
            return __assign(__assign({}, SALES_TABLE_COLUMN_OBJECT[columnKey]), { render: function (_, record) {
                    if (deadLine && dayjs(record.paidAt).isBefore(deadLine) && !['paidAt', 'manager'].includes(columnKey)) {
                        return '****';
                    }
                    return originalRender(_, record);
                } });
        }
    });
};
export var PaymentHistoryTable = function (props) {
    var store = StoreQuery.getMyStoreInCache();
    var loginManager = ManagerQuery.getLoginManagerInCache();
    var workingManagers = ManagerQuery.getWorkingManagersInCache();
    var priority = loginManager.getPermissionPriority({
        key: MANAGER_PERMISSION_KEYS.SALES_LIST,
        action: MANAGER_PERMISSION_ACTIONS.READ
    });
    var duration = loginManager.getPermissionDurationDate({
        key: MANAGER_PERMISSION_KEYS.SALES_LIST,
        action: MANAGER_PERMISSION_ACTIONS_DURATION.READ_DURATION
    });
    var shouldPagePrevent = priority === MANAGER_PERMISSION_PRIORITY.NOTHING && !duration;
    var deadLineDate = duration;
    var isMine = priority === MANAGER_PERMISSION_PRIORITY.MINE;
    var defaultOptions = {
        page: 1,
        limit: 10,
        storeId: props.params.storeId,
        customerId: props.params.customerId,
        methods: props.params.unpaid && ['unpaid'],
        managerIds: isMine ? [loginManager.id] : undefined,
        sortBy: 'paidAt',
        sortDir: 'DESC'
    };
    var _a = PaymentHistoryQuery.usePaymentHistoryPager(defaultOptions, {
        enabled: !!props.params.storeId && !shouldPagePrevent
    }), pagerResult = _a.pagerResult, getPaginateObject = _a.getPaginateObject, setNewPagerOptions = _a.setNewPagerOptions, pagerOptions = _a.pagerOptions;
    React.useEffect(function () {
        setNewPagerOptions(__assign({}, props.params));
    }, [props.params]);
    var saleModal = useSaleModal();
    var loader = useEntireLoader();
    var handleEditClick = function (data, isUnpaid) { return __awaiter(void 0, void 0, void 0, function () {
        var detail, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loader.show();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, PaymentHistoryQuery.getSalesById({
                            queryKey: ['', { storeId: data.storeId, salesHistoryId: data.id }],
                            meta: {}
                        })];
                case 2:
                    detail = _a.sent();
                    saleModal.add({
                        fixedCustomerId: !!detail.customerId,
                        id: detail === null || detail === void 0 ? void 0 : detail.id,
                        type: data.type,
                        data: detail,
                        controllerKey: SALES_CONTROLLER_KEYS.SELECT_MENU
                    });
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    return [3 /*break*/, 4];
                case 4:
                    loader.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var _b = React.useState({ keyword: '' }), filterValue = _b[0], setFilterValue = _b[1];
    var _c = React.useState(false), setterVisible = _c[0], setSetterVisible = _c[1];
    var columns = getSalesTableColumns(props.columns, deadLineDate);
    var handleTableChange = function (pagination, filters, sorter) {
        setNewPagerOptions({
            sortBy: (sorter === null || sorter === void 0 ? void 0 : sorter.columnKey) || 'paidAt',
            sortDir: (sorter === null || sorter === void 0 ? void 0 : sorter.order) || 'DESC',
            page: pagination.current,
            limit: pagination.pageSize
        });
    };
    var availableFilter = !props.params.unpaid;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { style: { marginBottom: '10px' }, wrap: false },
            availableFilter && (React.createElement(FilterWithSearcher, { searcherPlaceholder: "\uACE0\uAC1D\uBA85/\uB9E4\uCD9C\uBA54\uBAA8 \uBE60\uB978\uAC80\uC0C9", filter: [
                    {
                        key: 'paidAt',
                        type: 'dateRange',
                        title: '판매일시'
                    },
                    {
                        key: 'type',
                        type: 'checkbox',
                        title: '매출 유형',
                        items: [
                            { label: '상품', value: 'product' },
                            { label: '선불액∙정기권', value: 'ticket' },
                            { label: '환불', value: 'refund' }
                        ]
                    },
                    {
                        key: 'managerIds',
                        type: 'checkbox',
                        title: '담당자',
                        items: workingManagers.map(function (item) { return ({
                            label: item.displayName,
                            value: item.id
                        }); })
                    },
                    {
                        key: 'unregistered',
                        type: 'checkbox',
                        title: '고객구분',
                        items: [
                            { label: '등록 고객', value: 'registered' },
                            { label: '미등록 고객', value: 'unregistered' }
                        ]
                    },
                    {
                        key: 'deduction',
                        type: 'checkbox',
                        title: '차감',
                        items: [
                            { label: '선불액 차감', value: 'prepaidPoint' },
                            { label: '정기권 차감', value: 'deductionPriceByTicket' },
                            { label: '포인트 차감', value: 'customerPoint' },
                            { label: '공유선불액 차감', value: 'sharedPrepaidPoint' }
                        ]
                    },
                    {
                        key: 'methods',
                        type: 'checkbox',
                        title: '결제수단',
                        items: Object.keys((store === null || store === void 0 ? void 0 : store.paymentMethods) || {})
                            .map(function (key) {
                            var _a;
                            var methods = store === null || store === void 0 ? void 0 : store.paymentMethods;
                            if (!((_a = methods[key]) === null || _a === void 0 ? void 0 : _a.active) ||
                                ['prepaidPoint', 'deductionPriceByTicket', 'customerPoint', 'sharedPrepaidPoint'].includes(key)) {
                                return;
                            }
                            return {
                                label: methods[key].name,
                                value: key
                            };
                        })
                            .filter(Boolean)
                    }
                ], value: filterValue, onChange: function (value) {
                    var _a, _b;
                    setFilterValue(value);
                    setNewPagerOptions({
                        page: 1,
                        startedAt: (_a = value.paidAt) === null || _a === void 0 ? void 0 : _a.start,
                        endedAt: (_b = value.paidAt) === null || _b === void 0 ? void 0 : _b.end,
                        type: value.type ? (value.type.length === 2 ? null : value.type[0]) : null,
                        unregistered: !(value === null || value === void 0 ? void 0 : value.unregistered)
                            ? null
                            : value.unregistered.length === 2
                                ? null
                                : value.unregistered[0] === 'unregistered',
                        managerIds: isMine ? [loginManager.id] : value.managerIds || null,
                        methods: (value.deduction || []).concat(value.methods || []).filter(Boolean),
                        keyword: value.keyword || ''
                    });
                } })),
            props.availableColumnSettings && React.createElement(StyleTableSetterDefaultTrigger, { onClick: function () { return setSetterVisible(true); } })),
        React.createElement(ManagerPermissionContent, { isPrevent: shouldPagePrevent, descriptions: ['매출 조회 권한이 없습니다.'], children: React.createElement(XScrollableTable, { resizable: {
                    additionalColumnWidth: 0
                }, id: props.tableId, columns: columns, rowKey: function (row) { return row.id; }, dataSource: pagerResult.items, pagination: getPaginateObject(), loading: false, availableColumnSettings: props.availableColumnSettings, columnSetterVisible: setterVisible, onCloseSetter: function () {
                    setSetterVisible(false);
                }, onRow: function (record) { return ({
                    onClick: function () { return handleEditClick(record); }
                }); }, onChange: handleTableChange }) })));
};
var StyleTableSetterDefaultTrigger = styled(TableSetterDefaultTrigger)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  left: 320px;\n"], ["\n  position: absolute;\n  left: 320px;\n"])));
var templateObject_1;
