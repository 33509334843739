var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import Spin from 'antd/lib/spin';
import ReactDom from 'react-dom';
import styled from 'styled-components';
var styles = {
    backdrop: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: 9999999,
        left: 0,
        top: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        overflow: 'hidden'
    }
};
export var EntireBackdrop = function (props) { return (props.open ? React.createElement("div", { style: styles.backdrop }, props.children) : null); };
export function EntireLoader(props) {
    var modalPlaceholderElement = document.getElementById('root');
    return ReactDom.createPortal(React.createElement(EntireBackdrop, { open: props.open },
        React.createElement(Spin, { size: "large" })), modalPlaceholderElement);
}
export var ContentLoader = function () {
    return (React.createElement(Loading, null,
        React.createElement(Spin, null)));
};
var Loading = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var templateObject_1;
