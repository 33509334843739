var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { StyledFormItem, AntdForm, ColorTag, DrawerComponent, DrawerFooter, Icon, CustomFieldEditor, ButtonWithPlusCircle, TextComponent, ManagerPermissionContent, AntLeftIconButton } from 'renderer/components';
import Form from 'antd/lib/form';
import { PhoneNumberValidator, CustomerNumberValidator, FIELDS, LOCAL_STORAGE_KEY_DEFAULT_COUNTRY } from 'renderer/constants';
import { CustomerGroupQuery, CustomerQuery, ManagerQuery, StoreQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import { MANAGER_PERMISSION_ACTIONS, MANAGER_PERMISSION_KEYS, MANAGER_PERMISSION_PRIORITY } from 'renderer/models';
import { Col, Row } from 'antd/lib/grid';
import dayjs from 'dayjs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ko from 'react-phone-input-2/lang/ko.json';
import { COLORS, MediaQuery } from 'renderer/styles';
import { isValidDateString, useLocalStorage, useWindowSize } from 'renderer/utils';
import { CustomerRelationModal } from 'renderer/components';
import { ShadowBox } from 'common-ui/Card';
import { Layout } from 'common-ui';
import { ClickableIcon } from 'common-ui/Icon';
var BaseDrawer = function (props) {
    var _a = React.useState(props.open), open = _a[0], setOpen = _a[1];
    var handleOpen = function () {
        setOpen(true);
        if (props.onOpen) {
            props.onOpen();
        }
    };
    var handleClose = function () {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    React.useEffect(function () {
        if (open !== props.open) {
            setOpen(props.open);
        }
    }, [props.open]);
    var trigger = props.trigger, onClose = props.onClose, onOpen = props.onOpen, rest = __rest(props, ["trigger", "onClose", "onOpen"]);
    return (React.createElement(React.Fragment, null,
        props.trigger ? React.cloneElement(props.trigger, { onClick: handleOpen }) : React.createElement("div", null),
        React.createElement(DrawerComponent, __assign({}, rest, { open: open, onClose: handleClose }))));
};
export var CustomerRegister = function (props) {
    var _a, _b, _c, _d, _e;
    var data = props.data, storeId = props.storeId, rest = __rest(props, ["data", "storeId"]);
    var storeQuery = StoreQuery.useStore();
    var form = Form.useForm()[0];
    var isPC = useWindowSize().isPC;
    var groupQuery = CustomerGroupQuery.useCustomerGroups(storeId, { enabled: !!storeId });
    var loginManager = ManagerQuery.getLoginManagerInCache();
    var updatePriority = loginManager.getPermissionPriority({
        key: MANAGER_PERMISSION_KEYS.CUSTOMER_INFO,
        action: MANAGER_PERMISSION_ACTIONS.UPDATE
    });
    var isPrevent = updatePriority === MANAGER_PERMISSION_PRIORITY.NOTHING ||
        (updatePriority === MANAGER_PERMISSION_PRIORITY.MINE && loginManager.id !== (data === null || data === void 0 ? void 0 : data.managerId));
    var tagQuery = CustomerQuery.useCustomerTags(storeId, { enabled: !!storeId });
    var getColorById = function (tagId) {
        var _a;
        var tag = (_a = tagQuery.data) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.id === tagId; });
        return tag === null || tag === void 0 ? void 0 : tag.color;
    };
    var groupOptions = (_a = groupQuery.data) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({ label: item.name, value: item.id }); });
    var managerQptions = ManagerQuery.getManagerDropdownOptions();
    var tagOptions = (_b = tagQuery.data) === null || _b === void 0 ? void 0 : _b.map(function (item) { return ({ label: item.name, value: item.id }); });
    var handleClose = function () {
        if (props.onClose) {
            props.onClose();
        }
    };
    var create = CustomerQuery.useCreateCustomerMutation();
    var update = CustomerQuery.useUpdateCustomerMutation();
    var lastNumberQuery = CustomerQuery.useLastCustomerNumber(storeId, {
        enabled: !!storeId && !!data,
        refetchOnMount: 'always'
    });
    var isUpdated = !!(data === null || data === void 0 ? void 0 : data.id);
    var _f = useLocalStorage(LOCAL_STORAGE_KEY_DEFAULT_COUNTRY, {
        countryCode: 'kr',
        dialCode: 82
    }), defaultCountry = _f[0], setDefaultCountry = _f[1];
    var getInitialValues = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var customData = {};
        if (isUpdated) {
            Object.keys(((_a = data.data) === null || _a === void 0 ? void 0 : _a.custom) || {}).map(function (key) {
                var value = data.data.custom[key];
                if (isValidDateString(value)) {
                    value = dayjs(value);
                }
                customData[key] = value;
            });
        }
        var defaultGroupId = ((_c = (_b = groupQuery.data) === null || _b === void 0 ? void 0 : _b.find(function (item) { return item.priority === 0; })) === null || _c === void 0 ? void 0 : _c.id) ||
            (groupQuery.data && groupQuery.data[0] && ((_d = groupQuery.data[0]) === null || _d === void 0 ? void 0 : _d.id));
        return isUpdated
            ? __assign(__assign({}, data), { birth: data.birth && dayjs(data.birth), tagIds: (_e = data.tags) === null || _e === void 0 ? void 0 : _e.map(function (tag) { return tag.id; }), customerGroupId: ((_g = (_f = groupQuery.data) === null || _f === void 0 ? void 0 : _f.find(function (item) { return item.id === data.customerGroupId; })) === null || _g === void 0 ? void 0 : _g.id) || defaultGroupId, data: data ? __assign(__assign({ phone: defaultCountry }, data.data), { custom: customData }) : { phone: defaultCountry } }) : {
            customerGroupId: defaultGroupId,
            // gender: GENDER_ENUM.FEMALE,
            managerId: loginManager === null || loginManager === void 0 ? void 0 : loginManager.id,
            phone: data === null || data === void 0 ? void 0 : data.phone,
            data: { phone: defaultCountry }
        };
    };
    var lastCustonerNumber = (_c = lastNumberQuery.data) === null || _c === void 0 ? void 0 : _c.customerNumber;
    var prevCustomerNumber = data === null || data === void 0 ? void 0 : data.customerNumber;
    React.useEffect(function () {
        form.setFieldsValue(getInitialValues());
        forceUpdate();
    }, [data]);
    React.useEffect(function () {
        if (lastCustonerNumber) {
            form.setFieldsValue({
                customerNumber: isUpdated ? prevCustomerNumber : lastCustonerNumber + 1
            });
        }
    }, [lastCustonerNumber, data]);
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isUpdated) return [3 /*break*/, 2];
                    return [4 /*yield*/, update
                            .mutateAsync(__assign(__assign({}, data), values))
                            .then(function (data) {
                            notification.success({ message: "\uACE0\uAC1D ".concat(isUpdated ? '수정' : '등록', " \uC644\uB8CC") });
                            form.resetFields();
                            if (props.onClose) {
                                props.onClose(data);
                            }
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, create
                        .mutateAsync(__assign(__assign({}, values), { storeId: props.storeId }))
                        .then(function (data) {
                        notification.success({ message: "\uACE0\uAC1D ".concat(isUpdated ? '수정' : '등록', " \uC644\uB8CC") });
                        form.resetFields();
                        if (props.onClose) {
                            props.onClose(data);
                        }
                    })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _g = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _g[1];
    var countryCode = form.getFieldValue(['data', 'phone', 'countryCode']);
    var _h = React.useState(), customerRelation = _h[0], setCustomerRelation = _h[1];
    var getFieldByNameOrType = function (field) {
        var _a, _b, _c, _d;
        var nameOrType = field.key;
        switch (nameOrType) {
            case 'name':
                return {
                    type: 'text',
                    itemProps: {
                        name: 'name',
                        label: '이름',
                        rules: [
                            { type: 'string', required: true },
                            { max: 255, message: '255자 이내로 입력해주세요.' }
                        ]
                    },
                    inputProps: { placeholder: '고객명(필수)', autoFocus: true }
                };
            case 'phone':
                return [
                    {
                        element: (React.createElement(PhoneFormItem, { name: "phone", label: "\uC5F0\uB77D\uCC98", labelCol: { span: 6 }, wrapperCol: { span: isPC ? 18 : 16 }, rules: [
                                {
                                    validator: function (_, value) {
                                        return PhoneNumberValidator(value, storeId, data === null || data === void 0 ? void 0 : data.id, countryCode);
                                    }
                                }
                            ] },
                            React.createElement(StyledPhoneInput, { localization: ko, country: countryCode, placeholder: "\uC804\uD654\uBC88\uD638\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.", preferredCountries: [defaultCountry, 'kr'], enableSearch: true, disableCountryCode: true, disableCountryGuess: true, disableInitialCountryGuess: true, onChange: function (value, data, event) {
                                    form.setFieldsValue({
                                        data: __assign(__assign({}, form.getFieldValue('data')), { phone: {
                                                countryCode: data.countryCode,
                                                dialCode: data.dialCode
                                            } })
                                    });
                                    setDefaultCountry({
                                        countryCode: data.countryCode,
                                        dialCode: data.dialCode
                                    });
                                    forceUpdate();
                                    return data;
                                } })))
                    },
                    {
                        type: 'checkbox',
                        itemProps: {
                            style: { marginTop: '-10px' },
                            wrapperCol: { offset: isPC ? 6 : 8, span: 18 },
                            name: ['data', 'rejectReceive'],
                            valuePropName: 'checked'
                        },
                        inputProps: {
                            children: '수신거부',
                            value: true
                        }
                    }
                ];
            case 'gender':
                return FIELDS.gender;
            case 'birth':
                return FIELDS.birth;
            case 'address':
                return [
                    {
                        type: 'address',
                        itemProps: {
                            name: ['address', 'address'],
                            label: '주소',
                            className: 'address'
                        },
                        inputProps: {
                            placeholder: '주소 입력',
                            onComplete: function (addressResult) {
                                form.setFieldsValue({ address: addressResult.address });
                            }
                        }
                    },
                    {
                        type: 'text',
                        itemProps: {
                            wrapperCol: { offset: isPC ? 6 : 8, span: isPC ? 18 : 16 },
                            name: ['address', 'detailAddress'],
                            rules: [{ type: 'string', message: '상세주소를 입력해주세요.' }]
                        },
                        inputProps: { placeholder: '상세주소 입력' }
                    }
                ];
            case 'data.email':
                return {
                    type: 'text',
                    itemProps: {
                        label: '이메일',
                        name: ['data', 'email'],
                        rules: [{ type: 'email', message: '올바른 이메일주소를 입력해주세요.' }]
                    },
                    inputProps: { placeholder: '이메일주소' }
                };
            case 'managerId':
                return {
                    type: 'select',
                    itemProps: {
                        name: 'managerId',
                        label: '담당자',
                        rules: [{ type: 'number' }]
                    },
                    inputProps: { placeholder: '담당자 선택', options: managerQptions }
                };
            case 'customerGroupId':
                return {
                    type: 'select',
                    itemProps: {
                        name: 'customerGroupId',
                        label: '등급',
                        rules: [{ type: 'number', required: true }]
                    },
                    inputProps: {
                        placeholder: '등급 선택',
                        options: groupOptions
                    }
                };
            case 'customerNumber':
                return [
                    {
                        type: 'text',
                        itemProps: {
                            name: 'customerNumber',
                            label: '고객번호',
                            rules: [
                                {
                                    type: {
                                        value: 'number',
                                        message: '숫자만 입력 가능합니다.'
                                    },
                                    validator: function (_, value) {
                                        return CustomerNumberValidator(value, storeId, lastCustonerNumber, data === null || data === void 0 ? void 0 : data.id);
                                    }
                                }
                            ]
                        },
                        inputProps: {
                            placeholder: "".concat(Number(((_a = lastNumberQuery === null || lastNumberQuery === void 0 ? void 0 : lastNumberQuery.data) === null || _a === void 0 ? void 0 : _a.customerNumber) || 0) + 1, " \uBD80\uD130 \uC785\uB825\uAC00\uB2A5\uD569\uB2C8\uB2E4.")
                        }
                    },
                    {
                        element: (React.createElement(Row, null,
                            React.createElement(Col, { offset: 6 },
                                React.createElement(TextComponent, { marginTop: -16, children: "\n                  \uC0AC\uC6A9\uAC00\uB2A5 \uACE0\uAC1D\uBC88\uD638: ".concat(Number(((_b = lastNumberQuery === null || lastNumberQuery === void 0 ? void 0 : lastNumberQuery.data) === null || _b === void 0 ? void 0 : _b.customerNumber) || 0) + 1, " \uC774\uC0C1\n                  "), type: "caption1", color: COLORS.gray2 }))))
                    }
                ];
            case 'customerTags':
                return {
                    type: 'select',
                    itemProps: {
                        name: 'customerTags',
                        label: '태그',
                        rules: [{ type: 'array' }]
                    },
                    //   rowClassName: Styles.input,
                    inputProps: {
                        placeholder: '태그를 선택',
                        options: tagOptions,
                        mode: 'multiple',
                        tagRender: function (props) {
                            var color = getColorById(props.value);
                            return color && React.createElement(ColorTag, { color: color, children: props.label });
                        }
                    }
                };
            case 'memo':
                return FIELDS.memo;
            case 'customerRelations':
                return {
                    element: (React.createElement(PhoneFormItem, { name: "mainCustomerRelations", label: "\uACE0\uAC1D \uC5F0\uACB0", labelCol: { span: 6 }, wrapperCol: { span: isPC ? 18 : 16 } },
                        React.createElement("div", null, (_c = form.getFieldValue('mainCustomerRelations')) === null || _c === void 0 ? void 0 :
                            _c.map(function (item, index) {
                                var _a;
                                return (React.createElement(ShadowBox, { key: "".concat(index) },
                                    React.createElement(Layout.FluidInline, { gutter: 16, justify: "start", align: "center", style: { paddingLeft: '12px', paddingRight: '12px' } },
                                        React.createElement(TextComponent, { style: { flex: 1 } }, "".concat((_a = item.otherCustomer) === null || _a === void 0 ? void 0 : _a.name, "(").concat(item.otherCustomerTitle, ")")),
                                        React.createElement(Layout.Inline, { gutter: 4 },
                                            React.createElement(ClickableIcon, { name: "edit3", size: 14, style: { padding: 0 }, onClick: function () {
                                                    var _a;
                                                    return setCustomerRelation(__assign(__assign({}, item), { mainCustomerId: (_a = props.data) === null || _a === void 0 ? void 0 : _a.id, mainCustomer: props.data }));
                                                } }),
                                            React.createElement(ClickableIcon, { name: "x", color: COLORS.calendarRedActive, size: 14, style: { padding: 0 }, onClick: function () {
                                                    form.setFieldValue('mainCustomerRelations', form.getFieldValue('mainCustomerRelations').filter(function (relation) { return item.id !== relation.id; }));
                                                    forceUpdate();
                                                } })))));
                            }),
                            React.createElement(ButtonWithPlusCircle, { children: "\uC5F0\uACB0\uD560 \uACE0\uAC1D \uCD94\uAC00", onClick: function () {
                                    setCustomerRelation({
                                        mainCustomerId: form.getFieldValue('id'),
                                        mainCustomer: {
                                            id: form.getFieldValue('id'),
                                            name: form.getFieldValue('name')
                                        }
                                    });
                                } }))))
                };
            default:
                if (field.type === 'text') {
                    return {
                        type: 'text',
                        itemProps: {
                            name: ['data', 'custom', field.key],
                            label: field.label
                        },
                        inputProps: { placeholder: "".concat(field.label, "\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694.") }
                    };
                }
                else if (field.type === 'textarea') {
                    return {
                        type: 'textarea',
                        itemProps: {
                            name: ['data', 'custom', field.key],
                            label: field.label
                        },
                        inputProps: { placeholder: "".concat(field.label, "\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694.") }
                    };
                }
                else if (field.type === 'select') {
                    return {
                        type: 'select',
                        itemProps: {
                            name: ['data', 'custom', field.key],
                            label: field.label
                        },
                        inputProps: {
                            placeholder: "".concat(field.label, "\uC744 \uC120\uD0DD\uD574\uC8FC\uC138\uC694."),
                            options: (_d = field.options) === null || _d === void 0 ? void 0 : _d.map(function (item) { return ({ value: item, label: item }); })
                        }
                    };
                }
                else if (field.type === 'date') {
                    return {
                        type: 'datepicker',
                        itemProps: {
                            name: ['data', 'custom', field.key],
                            label: field.label
                        },
                        inputProps: {
                            value: dayjs(form.getFieldValue(['data', 'custom', field.key])),
                            placeholder: "".concat(field.label, "\uC744 \uC120\uD0DD\uD574\uC8FC\uC138\uC694.")
                        }
                    };
                }
        }
    };
    var fields = [];
    if (storeQuery.data) {
        (_e = (_d = storeQuery.data) === null || _d === void 0 ? void 0 : _d.customFields) === null || _e === void 0 ? void 0 : _e.customers.map(function (item) {
            var field = getFieldByNameOrType(item);
            fields = fields.concat(field);
        });
    }
    return (React.createElement(BaseDrawer, __assign({ title: isUpdated ? '고객 수정' : '고객 등록' }, rest, { open: !!data, children: React.createElement(ManagerPermissionContent
        // 수정만 권한 제한이 있고 수정은 따로 없음
        , { 
            // 수정만 권한 제한이 있고 수정은 따로 없음
            isPrevent: isUpdated && isPrevent, descriptions: ['고객정보 수정 권한이 없습니다.'], children: React.createElement(Wrapper, null,
                React.createElement(CustomerRelationModal, { storeId: storeId, customerRelation: customerRelation, onClose: function () {
                        setCustomerRelation(null);
                    }, onDelete: function () {
                        form.setFieldValue('mainCustomerRelations', form
                            .getFieldValue('mainCustomerRelations')
                            .filter(function (relation) { return (customerRelation === null || customerRelation === void 0 ? void 0 : customerRelation.id) !== relation.id; }));
                        forceUpdate();
                    }, onSubmit: function (values) {
                        if (values.id) {
                            form.setFieldValue('mainCustomerRelations', (form.getFieldValue('mainCustomerRelations') || []).map(function (item) {
                                return item.id === values.id ? values : item;
                            }));
                        }
                        else {
                            form.setFieldValue('mainCustomerRelations', (form.getFieldValue('mainCustomerRelations') || []).concat(values));
                        }
                        setCustomerRelation(null);
                    } }),
                React.createElement(CustomFieldEditor, { store: storeQuery.data, dataKey: "customers" }),
                React.createElement(StyledAntdForm, { allowEnterSubmit: true, formProps: {
                        form: form,
                        initialValues: getInitialValues(),
                        onFinish: handleSubmit
                    }, onSubmit: handleSubmit, fields: __spreadArray(__spreadArray([], fields, true), [
                        {
                            type: 'text',
                            itemProps: {
                                name: ['data', 'phone'],
                                hidden: true
                            }
                        },
                        {
                            type: 'address',
                            itemProps: {
                                name: ['address'],
                                label: '주소',
                                hidden: true
                            },
                            inputProps: {
                                placeholder: '주소검색',
                                hidden: true
                            }
                        }
                    ], false) })) }), footer: isUpdated ? (React.createElement(Row, { justify: "space-between", wrap: false },
            React.createElement(Col, { flex: 1 },
                React.createElement(DrawerFooter, { onConfirm: form.submit, onCancel: handleClose, confirmText: "\uC218\uC815" })),
            React.createElement(AntLeftIconButton, { danger: true, ghost: true, icon: React.createElement(Icon, { name: "trash" }), children: "\uD68C\uC6D0\uC815\uBCF4\uC0AD\uC81C", onClick: function () {
                    if (props.onDelete) {
                        props.onDelete(Number(data.id));
                        handleClose();
                    }
                } }))) : (React.createElement(DrawerFooter, { onConfirm: form.submit, onCancel: handleClose, confirmText: "\uB4F1\uB85D" })) })));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n"])));
export var ProfilePlaceholder = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 54px;\n  height: 54px;\n  margin-bottom: ", "px;\n"], ["\n  width: 54px;\n  height: 54px;\n  margin-bottom: ", "px;\n"])), function (props) { return props.marginBottom || 40; });
var StyledAntdForm = styled(AntdForm)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  & .address {\n    margin-bottom: 0px !important;\n\n    & > .ant-col {\n      padding: 0;\n    }\n  }\n"], ["\n  width: 100%;\n  & .address {\n    margin-bottom: 0px !important;\n\n    & > .ant-col {\n      padding: 0;\n    }\n  }\n"])));
var PhoneFormItem = styled(StyledFormItem)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  & > .ant-form-item-control {\n    max-width: 0px;\n  }\n\n  & .ant-form-item-explain {\n    width: 210px;\n  }\n"], ["\n  & > .ant-form-item-control {\n    max-width: 0px;\n  }\n\n  & .ant-form-item-explain {\n    width: 210px;\n  }\n"])));
var StyledPhoneInput = styled(PhoneInput)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  & .form-control.invalid-number {\n    border: 1px solid ", ";\n    background-color: ", ";\n    border-left-color: ", ";\n  }\n  & .form-control {\n    border-radius: 2px;\n    height: 32px;\n  }\n\n  & .invalid-number-message {\n    position: absolute;\n    z-index: 1;\n    left: 3px;\n    top: 35px;\n    color: ", ";\n  }\n  width: 208px;\n\n  & input {\n    width: 226px !important;\n\n    ", "\n    font-size: 12px !important;\n  }\n"], ["\n  & .form-control.invalid-number {\n    border: 1px solid ", ";\n    background-color: ", ";\n    border-left-color: ", ";\n  }\n  & .form-control {\n    border-radius: 2px;\n    height: 32px;\n  }\n\n  & .invalid-number-message {\n    position: absolute;\n    z-index: 1;\n    left: 3px;\n    top: 35px;\n    color: ", ";\n  }\n  width: 208px;\n\n  & input {\n    width: 226px !important;\n\n    ", "\n    font-size: 12px !important;\n  }\n"])), COLORS.calendarRedActive, COLORS.white, COLORS.calendarRedActive, COLORS.calendarRedActive, MediaQuery.tabletWithMobile(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 202px !important;\n  "], ["\n    width: 202px !important;\n  "]))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
