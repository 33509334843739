var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import React from 'react';
import { FluidRow, KRWFomatToString, getPrepaidMessageBody, ImageUploader, SALES_CONTROLLER_KEYS, SignaturePad, TextAreaWithByteChecker, AntPrimaryTextButton, useEntireLoader, TextComponent } from 'renderer/components';
import { TICKET_TYPE, EXTENDED_SALES_METHODS } from 'renderer/models';
import { MessageTemplateQuery, PaymentHistoryQuery, StorePictureQuery } from 'renderer/queries';
import { set, isEmpty, sum, groupBy, max, S3Client } from 'renderer/utils';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
import { useForm, useFormState } from 'react-hook-form';
import { PaymentInputs } from './PaymentInputs';
import { isSubmittable } from '../../Forms/HookForm';
import { SalesDetailContent } from './SalesDetailContents';
export var SalesAdditional = function (props) {
    var sales = props.data.data;
    var controllerKey = props.controllerKey, store = props.store, isPC = props.isPC;
    var _a = React.useState(), changed = _a[0], setChanged = _a[1];
    var handleChange = function (key, value) {
        setChanged(set({}, key, value));
    };
    var sendMessage = MessageTemplateQuery.useSendMessage();
    var postSalesPictures = StorePictureQuery.useCreateStorePictures(store === null || store === void 0 ? void 0 : store.id, {
        salesHistoryId: sales === null || sales === void 0 ? void 0 : sales.id,
        customerId: sales === null || sales === void 0 ? void 0 : sales.customerId
    });
    React.useEffect(function () {
        setChanged(undefined);
    }, [sales]);
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (props.controllerKey === SALES_CONTROLLER_KEYS.PREPAID) {
            var groupByCustomerId_1 = groupBy(sales.prepaidHistory, 'ticket.customerId');
            var hasShared = (_a = sales === null || sales === void 0 ? void 0 : sales.methods) === null || _a === void 0 ? void 0 : _a.sharedPrepaidPoint;
            // 가고객님 (본인) 선불액 사용 30,000 원, 잔액 811,000 원 / (공유) 선불액 사용 30,000 원, 잔액 811,000 원 입니다. 감사합니다. -팅커뷰네일-
            var content = Object.keys(groupByCustomerId_1)
                .map(function (customerId) {
                var _a, _b, _c;
                var prepaid = max(groupByCustomerId_1[customerId], function (item) { return item.id; });
                console.log(prepaid, groupByCustomerId_1, customerId);
                return "".concat(((_a = prepaid.data) === null || _a === void 0 ? void 0 : _a.isShared) ? '(공유)' : '(본인)', " \uC120\uBD88\uC561 \uC0AC\uC6A9 ").concat(KRWFomatToString({
                    value: (_b = prepaid.data) === null || _b === void 0 ? void 0 : _b.totalDeduction
                }), ", \uC794\uC561 ").concat(KRWFomatToString({
                    value: (_c = prepaid.data) === null || _c === void 0 ? void 0 : _c.totalRemain
                }));
            })
                .join(' / ');
            var customerName = (_b = sales === null || sales === void 0 ? void 0 : sales.customer) === null || _b === void 0 ? void 0 : _b.name;
            var storeName = (_c = props.store) === null || _c === void 0 ? void 0 : _c.name;
            setChanged({
                prepaidMessage: hasShared
                    ? "".concat(customerName, "\uB2D8 ").concat(content, "\uC785\uB2C8\uB2E4. \uAC10\uC0AC\uD569\uB2C8\uB2E4. -").concat(storeName, "-")
                    : getPrepaidMessageBody({
                        customerName: customerName,
                        storeName: storeName,
                        usedPrepaidPoint: (_d = sales === null || sales === void 0 ? void 0 : sales.methods) === null || _d === void 0 ? void 0 : _d.prepaidPoint,
                        remainPrepaidPoint: sales === null || sales === void 0 ? void 0 : sales.getPointRemainAfterUsing('prepaid')
                    })
            });
        }
        else if (props.controllerKey === SALES_CONTROLLER_KEYS.TICKETS) {
            var hasShare_1 = (_e = sales.ticketHistory) === null || _e === void 0 ? void 0 : _e.find(function (history) { var _a, _b; return ((_a = history.ticket) === null || _a === void 0 ? void 0 : _a.type) !== TICKET_TYPE.CHARGING_MONEY && ((_b = history === null || history === void 0 ? void 0 : history.data) === null || _b === void 0 ? void 0 : _b.isShared); });
            var used = (_f = sales.ticketHistory) === null || _f === void 0 ? void 0 : _f.filter(function (item) { var _a; return item.type === 'used' && ((_a = item.ticket) === null || _a === void 0 ? void 0 : _a.type) !== TICKET_TYPE.CHARGING_MONEY; }).map(function (ticket) {
                var _a, _b, _c, _d;
                return "".concat(hasShare_1 ? "".concat(((_a = ticket.data) === null || _a === void 0 ? void 0 : _a.isShared) ? '(공유)' : '본인') : '').concat((_b = ticket.ticket) === null || _b === void 0 ? void 0 : _b.name, " \uC0AC\uC6A9 ").concat((_c = ticket.data) === null || _c === void 0 ? void 0 : _c.deduction, "\uD68C, \uC794\uC5EC ").concat((_d = ticket.data) === null || _d === void 0 ? void 0 : _d.remain, "\uD68C");
            }).join(' / ');
            setChanged({
                prepaidMessage: "".concat((_g = sales === null || sales === void 0 ? void 0 : sales.customer) === null || _g === void 0 ? void 0 : _g.name, "\uB2D8 ").concat(used, "\uC785\uB2C8\uB2E4. \uAC10\uC0AC\uD569\uB2C8\uB2E4. - ").concat((_h = props.store) === null || _h === void 0 ? void 0 : _h.name)
            });
        }
        else {
            setChanged(undefined);
        }
    }, [props.controllerKey]);
    var entireLoader = useEntireLoader();
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, messageSendInfo, error_1, pictures, error_2;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!changed &&
                        ![SALES_CONTROLLER_KEYS.SEND_QR, SALES_CONTROLLER_KEYS.PREPAID, SALES_CONTROLLER_KEYS.TICKETS].includes(props.controllerKey)) {
                        return [2 /*return*/];
                    }
                    payload = __assign({}, sales);
                    if (!(props.controllerKey === SALES_CONTROLLER_KEYS.SEND_QR)) return [3 /*break*/, 1];
                    return [2 /*return*/, handleSendQR(Number((_a = sales.ticket) === null || _a === void 0 ? void 0 : _a.id))];
                case 1:
                    if (!(props.controllerKey === SALES_CONTROLLER_KEYS.PREPAID ||
                        props.controllerKey === SALES_CONTROLLER_KEYS.TICKETS)) return [3 /*break*/, 9];
                    _d.label = 2;
                case 2:
                    _d.trys.push([2, 6, 7, 8]);
                    entireLoader.show();
                    messageSendInfo = props.store.messageSendInfo;
                    if (!(messageSendInfo === null || messageSendInfo === void 0 ? void 0 : messageSendInfo.senderNumber)) return [3 /*break*/, 4];
                    if (!(changed === null || changed === void 0 ? void 0 : changed.prepaidMessage)) {
                        return [2 /*return*/, notification.error({
                                message: '메시지를 입력해주세요.'
                            })];
                    }
                    return [4 /*yield*/, sendMessage.mutateAsync({
                            target: { isAll: false, customerIds: [sales === null || sales === void 0 ? void 0 : sales.customerId] },
                            sendAtMode: 'now',
                            content: { body: changed === null || changed === void 0 ? void 0 : changed.prepaidMessage },
                            storeId: props.store.id,
                            sender: messageSendInfo === null || messageSendInfo === void 0 ? void 0 : messageSendInfo.senderNumber
                        })];
                case 3:
                    _d.sent();
                    notification.success({ message: '문자가 발송되었습니다.' });
                    return [3 /*break*/, 5];
                case 4:
                    // TODO: 문자 포인트 충전 및 서비스 가입 버튼 추가
                    notification.error({
                        message: '문자 서비스에 가입되어있지 않습니다.'
                    });
                    _d.label = 5;
                case 5: return [3 /*break*/, 8];
                case 6:
                    error_1 = _d.sent();
                    notification.error({
                        message: error_1.message
                    });
                    return [3 /*break*/, 8];
                case 7:
                    entireLoader.hide();
                    setChanged(undefined);
                    return [2 /*return*/, props.onClickGoBack()];
                case 8: return [3 /*break*/, 17];
                case 9:
                    if (!(props.controllerKey === SALES_CONTROLLER_KEYS.PICUTRES)) return [3 /*break*/, 16];
                    entireLoader.show();
                    _d.label = 10;
                case 10:
                    _d.trys.push([10, 13, 14, 15]);
                    return [4 /*yield*/, S3Client.uploadImagesToS3(((_b = props.images) === null || _b === void 0 ? void 0 : _b.map(function (item) { return item.url; })) || [], changed.images || [], {
                            dirName: "store_sales_pictures/".concat(sales.storeId)
                        })];
                case 11:
                    pictures = _d.sent();
                    return [4 /*yield*/, postSalesPictures.mutateAsync(pictures.map(function (item) {
                            return {
                                url: item,
                                salesHistoryId: sales.id,
                                customerId: sales.customerId,
                                storeId: store.id
                            };
                        }))];
                case 12:
                    _d.sent();
                    if (pictures.length > 0) {
                        notification.info({
                            message: "".concat((_c = changed.images) === null || _c === void 0 ? void 0 : _c.length, "\uC7A5 \uC0AC\uC9C4 \uC5C5\uB85C\uB4DC \uC911 ").concat(pictures.length, "\uC7A5\uC758 \uC0AC\uC9C4 \uC5C5\uB85C\uB4DC\uAC00 \uC131\uACF5\uD558\uC600\uC2B5\uB2C8\uB2E4.")
                        });
                    }
                    payload = { meta: __assign(__assign({}, payload.meta), { pictures: pictures }) };
                    return [3 /*break*/, 15];
                case 13:
                    error_2 = _d.sent();
                    notification.error({
                        message: '이미지 업로드 중 에러 발생'
                    });
                    return [3 /*break*/, 15];
                case 14:
                    notification.success({ message: '이미지 업로드 완료' });
                    entireLoader.hide();
                    return [7 /*endfinally*/];
                case 15: return [3 /*break*/, 17];
                case 16:
                    payload = __assign(__assign({}, payload), changed);
                    _d.label = 17;
                case 17:
                    props.onSubmit(payload);
                    return [2 /*return*/];
            }
        });
    }); };
    var getData = function (controllerKey) {
        switch (controllerKey) {
            case SALES_CONTROLLER_KEYS.DETAILS:
                return {
                    title: '결제내역서',
                    body: (React.createElement(SalesDetailContent, { store: props.store, data: props.data, salesPermissions: props.salesPermissions, onChange: props.disabledUpdate ? null : function (memo) { return props.onSubmit({ memo: memo }); } }))
                };
            case SALES_CONTROLLER_KEYS.PICUTRES:
                return {
                    title: '시술사진 등록',
                    confirmButton: '등록완료',
                    body: (React.createElement(UploadPictures, { images: props.images || [], onChange: function (value) {
                            setChanged({ images: value });
                        } }))
                };
            case SALES_CONTROLLER_KEYS.PREPAID:
                return {
                    title: '선불액차감 문자발송',
                    confirmButton: '발송',
                    body: (React.createElement(PrepaidMessage, { value: (changed === null || changed === void 0 ? void 0 : changed.prepaidMessage) || '', onChange: function (value) { return handleChange('prepaidMessage', value); } }))
                };
            case SALES_CONTROLLER_KEYS.TICKETS:
                return {
                    title: '횟수권차감 문자발송',
                    confirmButton: '발송',
                    body: (React.createElement(PrepaidMessage, { value: (changed === null || changed === void 0 ? void 0 : changed.prepaidMessage) || '', onChange: function (value) { return handleChange('prepaidMessage', value); } }))
                };
            case SALES_CONTROLLER_KEYS.SEND_QR:
                return {
                    title: '정기권 QR발송',
                    confirmButton: '발송',
                    body: (React.createElement("div", null,
                        React.createElement(TextComponent, { children: "\uC815\uAE30\uAD8C QR\uBC1C\uC1A1", textAlign: "center", type: "headline3" }),
                        React.createElement(TextComponent, { children: "\uC54C\uB9BC\uD1A1\uC744 \uD1B5\uD574 \uBAA8\uBC14\uC77C \uC815\uAE30\uAD8C\uC744 \uBC1C\uC1A1\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?", textAlign: "center", color: COLORS.gray1 })))
                };
            case SALES_CONTROLLER_KEYS.SIGNATURE:
                return {
                    title: '서명',
                    confirmButton: '등록완료',
                    body: React.createElement(Signature, { sales: sales, onChange: function (value) { return handleChange('signature', value); } })
                };
            case SALES_CONTROLLER_KEYS.UNPAID:
                return {
                    title: '미수금 정산',
                    confirmButton: '등록완료',
                    body: (React.createElement(UnpaidInputs, { sales: sales, store: props.store, onSubmit: props.onSubmit, onClickGoBack: props.onClickGoBack }))
                };
        }
    };
    var handleSendQR = function (ticketId) { return __awaiter(void 0, void 0, void 0, function () {
        var modal;
        return __generator(this, function (_a) {
            modal = Modal.confirm({
                title: '정기권 발송',
                centered: true,
                cancelText: '취소',
                okText: '전송',
                content: '알림톡을 통해 모바일정기권을 발송하시겠습니까?',
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var error_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!ticketId) {
                                    notification.error({ message: '티켓 아이디를 입력해주세요.' });
                                }
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, 4, 5]);
                                return [4 /*yield*/, PaymentHistoryQuery.sendTicketQR({ storeId: sales.storeId, ticketId: ticketId })];
                            case 2:
                                _a.sent();
                                notification.success({ message: '모바일 정기권 발송이 완료되었습니다.' });
                                return [3 /*break*/, 5];
                            case 3:
                                error_3 = _a.sent();
                                notification.error({ message: error_3.message });
                                return [3 /*break*/, 5];
                            case 4: return [2 /*return*/, props.onClickGoBack()];
                            case 5: return [2 /*return*/];
                        }
                    });
                }); },
                onCancel: function () { return modal.destroy(); }
            });
            return [2 /*return*/];
        });
    }); };
    var disabled = (!changed && ![SALES_CONTROLLER_KEYS.SEND_QR, SALES_CONTROLLER_KEYS.PREPAID].includes(props.controllerKey)) ||
        (props.controllerKey === SALES_CONTROLLER_KEYS.SEND_QR && !sales.ticket);
    if (controllerKey === SALES_CONTROLLER_KEYS.SELECT_MENU || !controllerKey) {
        return React.createElement("div", null);
    }
    else {
        return (React.createElement(SalesAdditionalWrapper, null,
            getData(controllerKey).body,
            props.controllerKey !== SALES_CONTROLLER_KEYS.UNPAID && (React.createElement(React.Fragment, null,
                React.createElement(FluidRow, { style: { marginTop: '15px' }, justify: "center", children: React.createElement(Button, { type: "primary", style: { width: '100%' }, children: getData(controllerKey).confirmButton, onClick: handleSubmit, disabled: disabled }) }),
                React.createElement(FluidRow, { justify: "center", children: React.createElement(AntPrimaryTextButton, { children: '< 이전으로 돌아가기' }), onClick: props.onClickGoBack })))));
    }
};
var SalesAdditionalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  top: 0px;\n  width: 100%;\n  height: 100%;\n  right: 0;\n  background-color: #fff;\n"], ["\n  top: 0px;\n  width: 100%;\n  height: 100%;\n  right: 0;\n  background-color: #fff;\n"])));
var UploadPictures = function (props) {
    var _a;
    return (React.createElement("div", null,
        React.createElement(TextComponent, { children: "\uC2DC\uC220\uC0AC\uC9C4 \uB4F1\uB85D", type: "headline3", textAlign: "center", marginBottom: 10 }),
        React.createElement(ImageUploader, { defaultItems: ((_a = props.images) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item.url; })) || [], onChange: props.onChange })));
};
var Signature = function (props) {
    return (React.createElement("div", null,
        React.createElement(TextComponent, { children: "\uC11C\uBA85", type: "headline3", textAlign: "center", marginBottom: 10 }),
        React.createElement(TextComponent, { children: "\uACF5\uC911\uC704\uC0DD\uAD00\uB9AC\uBC95 \uC2DC\uD589\uADDC\uCE59\uC5D0 \uB530\uB77C", color: COLORS.gray1, textAlign: "center" }),
        React.createElement(TextComponent, { children: "3\uAC00\uC9C0 \uC774\uC0C1\uC758 \uC0C1\uD488\uC744 \uC774\uC6A9\uD55C \uACE0\uAC1D\uC5D0\uAC8C", color: COLORS.gray1, textAlign: "center" }),
        React.createElement(TextComponent, { children: "\uCD5C\uC885 \uBE44\uC6A9 \uC0AC\uC804\uACE0\uC9C0 \uBC0F \uB3D9\uC758 \uC11C\uBA85\uC744 \uBC1B\uC544\uC57C \uD569\uB2C8\uB2E4.", color: COLORS.gray1, textAlign: "center" }),
        React.createElement(SignaturePad, { onChange: props.onChange, value: props.sales.signature })));
};
var PrepaidMessage = function (props) {
    return (React.createElement("div", null,
        React.createElement(TextAreaWithByteChecker, { value: props.value, onChange: function (e) { return props.onChange(e.target.value); } })));
};
var UnpaidInputs = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var type = ((_a = props.sales) === null || _a === void 0 ? void 0 : _a.isUsingHistory) ? 'product' : 'ticket';
    var methods = useForm({
        mode: 'onChange',
        defaultValues: __assign({ paidAt: new Date(), type: type }, (_b = props.sales) === null || _b === void 0 ? void 0 : _b.paymentHistoryForm)
    });
    React.useEffect(function () {
        var _a;
        methods.reset(__assign({ paidAt: new Date(), type: type }, (_a = props.sales) === null || _a === void 0 ? void 0 : _a.paymentHistoryForm));
    }, [props.sales]);
    var onSubmit = methods.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            payload = __assign(__assign(__assign({}, props.sales), { ticket: (_a = props.sales) === null || _a === void 0 ? void 0 : _a.ticket }), values);
            if (isEmpty(values.usingHistory)) {
                payload.ticket.salesPerManagers = (_c = (_b = payload.ticket) === null || _b === void 0 ? void 0 : _b.salesPerManagers) === null || _c === void 0 ? void 0 : _c.map(function (item) {
                    return __assign(__assign({}, item), { sharePrice: sum(Object.keys(item.methods).map(function (key) { return item.methods[key] || 0; })) });
                });
            }
            else {
                payload.usingHistory = payload.usingHistory.map(function (using) {
                    var _a;
                    using.salesPerManagers =
                        ((_a = using === null || using === void 0 ? void 0 : using.salesPerManagers) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                            return __assign(__assign({}, item), { sharePrice: sum(Object.keys(item.methods).map(function (key) { return item.methods[key] || 0; })) });
                        })) || [];
                    return using;
                });
            }
            props.onSubmit(payload);
            return [2 /*return*/];
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var formState = useFormState({ control: methods.control });
    return (React.createElement("div", null,
        React.createElement(PaymentInputs, { parentType: "unpaid", methods: methods, customer: (_c = props.sales) === null || _c === void 0 ? void 0 : _c.customer, store: props.store, data: { type: ((_d = props.sales) === null || _d === void 0 ? void 0 : _d.isUsingHistory) ? 'product' : 'ticket', data: props.sales }, hideMethods: ((_f = (_e = props.sales) === null || _e === void 0 ? void 0 : _e.ticket) === null || _f === void 0 ? void 0 : _f.type) === TICKET_TYPE.CHARGING_MONEY
                ? [EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT, EXTENDED_SALES_METHODS.PREPAID_POINT]
                : [], onSubmit: function (e) {
                console.log(e);
            }, onCancel: function () { } }),
        React.createElement(FluidRow, { style: { marginTop: '15px' }, justify: "center", children: React.createElement(Button, { type: "primary", style: { width: '100%' }, children: "\uC218\uC815", onClick: onSubmit, disabled: !isSubmittable(formState) }) }),
        React.createElement(FluidRow, { justify: "center", children: React.createElement(AntPrimaryTextButton, { children: '< 이전으로 돌아가기' }), onClick: props.onClickGoBack })));
};
var templateObject_1;
// const QRSender = (props: {sales: SalesHistoryModel}) => {
//     return <Text
// }
