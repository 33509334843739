var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Row } from 'antd/lib/grid';
import dayjs from 'dayjs';
import React from 'react';
import { FluidRow, Icon, KRWFomatToString, Marginer, TextComponent, useEntireLoader, ManagerPermissionContent } from 'renderer/components';
import { useSaleModal } from 'renderer/stores';
import { DATE_FORMAT, DATE_FORMAT_INCLUDE_TIME } from 'renderer/constants';
import { EXTENDED_SALES_METHODS, SalesHistoryModel, EXTENDED_SALES_MEHOD_KOREAN, TICKET_TYPE, SALES_METHODS_KEYS, SALES_TYPES } from 'renderer/models';
import { PaymentHistoryQuery } from 'renderer/queries';
import { BOX_SHADOW, COLORS } from 'renderer/styles';
import { isEmpty, orderBy, sum, uniqBy } from 'renderer/utils';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { Typhography } from 'common-ui';
export var SalesDetailContent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var payment = props.data.data;
    if (!payment) {
        return React.createElement("div", null);
    }
    var renderItem = function (usingOrTicket) {
        var _a, _b, _c, _d, _e, _f;
        var isUsing = usingOrTicket.isUsingHistory;
        if (isUsing) {
            var using = usingOrTicket;
            return (React.createElement("div", { key: using.id, style: { width: '100%', marginBottom: '14px' } },
                React.createElement(Row, { style: { width: '100%' }, justify: "space-between" },
                    React.createElement(TextComponent, null, (_a = using.product) === null || _a === void 0 ? void 0 : _a.nameWithGroupName),
                    React.createElement(TextComponent, null, KRWFomatToString({ value: using.finalPrice }))),
                React.createElement(Row, null,
                    React.createElement(TextComponent, { color: COLORS.gray2 }, "".concat(KRWFomatToString({ value: using.productPrice }), " x ").concat(using.amount, " ").concat(using.adjustPrice
                        ? "".concat(using.adjustPrice > 0
                            ? " + ".concat(KRWFomatToString({ value: using.adjustPrice }))
                            : " - ".concat(KRWFomatToString({ value: -using.adjustPrice })))
                        : ''))),
                !props.hideMemo && (React.createElement(Row, null,
                    React.createElement(TextComponent, { color: COLORS.gray2, children: (_b = using.salesPerManagers) === null || _b === void 0 ? void 0 : _b.map(function (spm) {
                            var _a;
                            return "".concat(((_a = spm.manager) === null || _a === void 0 ? void 0 : _a.displayName) || '담당자 없음', " ").concat(KRWFomatToString({
                                value: sum(Object.keys(spm.methods).map(function (key) { return spm.methods[key] || 0; }))
                            }));
                        }).join(' / ') })))));
        }
        else {
            var ticket = usingOrTicket;
            return (React.createElement("div", { key: ticket.id, style: { width: '100%', marginBottom: '14px' } },
                React.createElement(Row, { style: { width: '100%' }, justify: "space-between" },
                    React.createElement(TextComponent, null,
                        "[", (_c = ticket.copiedTicketTemplate) === null || _c === void 0 ? void 0 :
                        _c.getTypeToString(),
                        "]",
                        ticket.name),
                    React.createElement(TextComponent, null, KRWFomatToString({ value: (_d = ticket.copiedTicketTemplate) === null || _d === void 0 ? void 0 : _d.totalPrice }))),
                React.createElement(Row, null,
                    React.createElement(TextComponent, { color: COLORS.gray2 }, "".concat(KRWFomatToString({
                        value: (_e = ticket.copiedTicketTemplate) === null || _e === void 0 ? void 0 : _e.totalPrice
                    })))),
                !props.hideMemo && (React.createElement(Row, null,
                    React.createElement(TextComponent, { color: COLORS.gray2, children: (_f = ticket.salesPerManagers) === null || _f === void 0 ? void 0 : _f.map(function (spm) {
                            var _a;
                            return "".concat(((_a = spm.manager) === null || _a === void 0 ? void 0 : _a.displayName) || '담당자 없음', " ").concat(KRWFomatToString({
                                value: sum(Object.keys(spm.methods).map(function (key) { return spm.methods[key] || 0; }))
                            }));
                        }).join(' / ') })))));
        }
    };
    var renderReservationFee = function () {
        var _a;
        return (React.createElement("div", { style: { width: '100%', marginBottom: '14px' } },
            React.createElement(Row, { style: { width: '100%' }, justify: "space-between" },
                React.createElement(TextComponent, null, "\uC608\uC57D\uAE08"),
                React.createElement(TextComponent, null, KRWFomatToString({ value: payment === null || payment === void 0 ? void 0 : payment.totalPrice }))),
            !props.hideMemo && (React.createElement(Row, null,
                React.createElement(TextComponent, { color: COLORS.gray2, children: (_a = payment === null || payment === void 0 ? void 0 : payment.salesPerManagers) === null || _a === void 0 ? void 0 : _a.map(function (spm) {
                        var _a;
                        return "".concat(((_a = spm.manager) === null || _a === void 0 ? void 0 : _a.displayName) || '담당자 없음', " ").concat(KRWFomatToString({
                            value: sum(Object.keys(spm.methods).map(function (key) { return spm.methods[key] || 0; }))
                        }));
                    }).join(' / ') })))));
    };
    var renderRefund = function () {
        var _a;
        return (React.createElement("div", { style: { width: '100%', marginBottom: '14px' } },
            React.createElement(Row, { style: { width: '100%' }, justify: "space-between" },
                React.createElement(TextComponent, null, "\uCD1D \uD658\uBD88\uAE08\uC561"),
                React.createElement(TextComponent, null, KRWFomatToString({ value: payment.totalPrice }))),
            React.createElement(Row, null,
                React.createElement(TextComponent, { color: COLORS.gray2, children: (_a = payment.data) === null || _a === void 0 ? void 0 : _a.prevSalesContent }))));
    };
    var _k = React.useState(), memo = _k[0], setMemo = _k[1];
    var _l = React.useState(false), editable = _l[0], setEditable = _l[1];
    React.useEffect(function () {
        setMemo((payment === null || payment === void 0 ? void 0 : payment.memo) || null);
        setEditable(false);
    }, [payment]);
    var methodKeys = SALES_METHODS_KEYS;
    var salesMethods = payment.methods || {};
    var readPermission = props.salesPermissions.read;
    var isRefund = payment.type === SALES_TYPES.REFUND;
    var saleModal = useSaleModal();
    var entireLoader = useEntireLoader();
    var updateRefund = PaymentHistoryQuery.useUpdateRefund();
    var handleChangeSales = function (salesHistoryId) { return __awaiter(void 0, void 0, void 0, function () {
        var newSales;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    entireLoader.show();
                    return [4 /*yield*/, PaymentHistoryQuery.getSalesById({
                            queryKey: [
                                '',
                                {
                                    storeId: props.store.id,
                                    salesHistoryId: salesHistoryId
                                }
                            ],
                            meta: {}
                        })];
                case 1:
                    newSales = _a.sent();
                    entireLoader.hide();
                    saleModal.replaceFromLastItem({
                        id: newSales === null || newSales === void 0 ? void 0 : newSales.id,
                        data: newSales,
                        type: newSales.type
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    return !readPermission ? (React.createElement("div", { style: { position: 'relative' } },
        React.createElement(ManagerPermissionContent, { isPrevent: !readPermission, descriptions: ['매출 조회 권한이 없습니다.'], children: React.createElement("div", null) }))) : (React.createElement("div", null,
        React.createElement(SalesDetailContentWrapper, { "$hideBoxShadow": props.hideBoxShadow },
            React.createElement(SalesDetailTitle, { borderColor: COLORS.gray5 },
                React.createElement(TextComponent, { children: props.title || "".concat(isRefund ? '환불' : '결제', " \uB0B4\uC5ED\uC11C"), type: "headline2" }),
                React.createElement(TextComponent, { children: dayjs(payment === null || payment === void 0 ? void 0 : payment.paidAt).format(DATE_FORMAT_INCLUDE_TIME), marginBottom: 14 }),
                isRefund ? (React.createElement(Typhography.ClickableText, { color: COLORS.primary1, children: '결제내역서보기 >', marginBottom: 14, onClick: function () {
                        var _a;
                        handleChangeSales((_a = payment === null || payment === void 0 ? void 0 : payment.data) === null || _a === void 0 ? void 0 : _a.prevSalesId);
                    } })) : ((_a = payment === null || payment === void 0 ? void 0 : payment.data) === null || _a === void 0 ? void 0 : _a.refundId) ? (React.createElement(Typhography.ClickableText, { onClick: function () {
                        var _a;
                        handleChangeSales((_a = payment === null || payment === void 0 ? void 0 : payment.data) === null || _a === void 0 ? void 0 : _a.refundId);
                    }, color: COLORS.primary1, children: '환불내역서보기 >', marginBottom: 14 })) : (React.createElement("div", null))),
            React.createElement(SalesDetailTitle, null, !isEmpty(payment.usingHistory)
                ? (_b = payment.usingHistory) === null || _b === void 0 ? void 0 : _b.map(renderItem)
                : payment.type === SALES_TYPES.RESERVATION_FEE
                    ? renderReservationFee()
                    : payment.type === SALES_TYPES.REFUND
                        ? renderRefund()
                        : (_c = payment.tickets) === null || _c === void 0 ? void 0 : _c.map(renderItem)),
            !isRefund && (React.createElement(SalesDetailTitle, null,
                React.createElement(FluidRow, { justify: "space-between" },
                    React.createElement(TextComponent, { children: "\uCD1D ".concat(isRefund ? '환불액' : '영업액') }),
                    React.createElement(TextComponent, { children: KRWFomatToString({
                            value: sum(Object.keys(salesMethods).map(function (key) { return salesMethods[key] || 0; }))
                        }) })))),
            payment.type !== SALES_TYPES.REFUND ? (React.createElement(SalesDetailTitle, null, methodKeys
                .map(function (key) {
                var _a, _b;
                if (key === EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT) {
                    return;
                }
                if (key === EXTENDED_SALES_METHODS.PREPAID_POINT) {
                    if (!salesMethods[EXTENDED_SALES_METHODS.PREPAID_POINT] &&
                        !salesMethods[EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT]) {
                        return;
                    }
                    // totalRemain은 같기때문에 하나만 찾으면됌
                    var prepaid = uniqBy((_a = payment === null || payment === void 0 ? void 0 : payment.ticketHistory) === null || _a === void 0 ? void 0 : _a.filter(function (item) { var _a; return ((_a = item.ticket) === null || _a === void 0 ? void 0 : _a.type) === TICKET_TYPE.CHARGING_MONEY; }), 'ticket.customerId');
                    if (!!prepaid) {
                        return (React.createElement(React.Fragment, null,
                            React.createElement(FluidRow, { justify: "space-between", key: key },
                                React.createElement(TextComponent, { children: "[\uCC28\uAC10] ".concat(EXTENDED_SALES_MEHOD_KOREAN[key]) }),
                                React.createElement(TextComponent, { children: KRWFomatToString({
                                        value: (salesMethods[key] || 0) +
                                            (salesMethods[EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT] || 0)
                                    }) })),
                            React.createElement("div", { style: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    width: '100%'
                                } }, prepaid.map(function (history) {
                                var _a;
                                return (React.createElement(TextComponent, { type: "caption1", color: COLORS.gray2, children: "- ".concat(history.data.isShared ? '(공유)' : '(본인)', " \uCC28\uAC10 ").concat(KRWFomatToString({
                                        value: history.data.totalDeduction
                                    }), " / \uC794\uC5EC ").concat(KRWFomatToString({ value: ((_a = history === null || history === void 0 ? void 0 : history.data) === null || _a === void 0 ? void 0 : _a.totalRemain) || 0 })) }));
                            }))));
                    }
                }
                if (!salesMethods[key]) {
                    return;
                }
                if (key === EXTENDED_SALES_METHODS.CUSTOMER_POINT) {
                    var lastPonint = orderBy(payment === null || payment === void 0 ? void 0 : payment.customerPoints, ['id'], ['desc'])[0];
                    if (!!lastPonint) {
                        return (React.createElement(React.Fragment, null,
                            React.createElement(FluidRow, { justify: "space-between", key: key },
                                React.createElement(TextComponent, { children: "[\uCC28\uAC10] ".concat(EXTENDED_SALES_MEHOD_KOREAN[key]) }),
                                React.createElement(TextComponent, { children: KRWFomatToString({
                                        value: salesMethods[key]
                                    }) })),
                            React.createElement(FluidRow, { justify: "space-between", key: key },
                                React.createElement(TextComponent, { type: "caption1", color: COLORS.gray2, children: "- \uC794\uC5EC ".concat(KRWFomatToString({ value: lastPonint.latestRemain || 0 })) }))));
                    }
                }
                if (key === EXTENDED_SALES_METHODS.DEDUCTION_PRICE_BY_TICKET) {
                    var histories = (_b = payment === null || payment === void 0 ? void 0 : payment.ticketHistory) === null || _b === void 0 ? void 0 : _b.filter(function (item) { var _a; return ((_a = item.ticket) === null || _a === void 0 ? void 0 : _a.type) !== TICKET_TYPE.CHARGING_MONEY; });
                    if (!isEmpty(histories)) {
                        return (React.createElement("div", { style: { width: '100%' } },
                            React.createElement(FluidRow, { justify: "space-between", key: key },
                                React.createElement(TextComponent, { children: "[\uCC28\uAC10] ".concat(EXTENDED_SALES_MEHOD_KOREAN[key]) }),
                                React.createElement(TextComponent, { children: KRWFomatToString({
                                        value: salesMethods[key]
                                    }) })),
                            histories.map(function (item) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                                var ticketDesc = ((_a = item.ticket) === null || _a === void 0 ? void 0 : _a.type) === TICKET_TYPE.COUNT
                                    ? "- ".concat(((_b = item.ticket) === null || _b === void 0 ? void 0 : _b.name) || '-', " (\uCC28\uAC10 ").concat(((_c = item.data) === null || _c === void 0 ? void 0 : _c.deduction) || 0, "\uD68C / \uC794\uC5EC ").concat(((_d = item.data) === null || _d === void 0 ? void 0 : _d.remain) || 0, "\uD68C)")
                                    : "- ".concat(((_e = item.ticket) === null || _e === void 0 ? void 0 : _e.name) || '-', " (~").concat(((_g = (_f = item.ticket) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.expiredAt)
                                        ? dayjs((_j = (_h = item.ticket) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.expiredAt).format(DATE_FORMAT)
                                        : '-', ")");
                                var label = ((_k = item.data) === null || _k === void 0 ? void 0 : _k.isShared) ? '공유 : ' : '본인 : ';
                                return (React.createElement(FluidRow, { justify: "space-between", key: "".concat(item.id, "_").concat(key) },
                                    React.createElement(TextComponent, { type: "caption1", color: COLORS.gray2, children: "".concat(label).concat(ticketDesc) })));
                            })));
                    }
                }
                return (React.createElement(FluidRow, { justify: "space-between", key: key },
                    React.createElement(TextComponent, { children: "[".concat([EXTENDED_SALES_METHODS.PREPAID_POINT, EXTENDED_SALES_METHODS.CUSTOMER_POINT].includes(key)
                            ? '차감'
                            : '결제', "] ").concat(SalesHistoryModel.getSalesMethodLabel(key, props.store)) }),
                    React.createElement(TextComponent, { children: KRWFomatToString({
                            value: salesMethods[key]
                        }) })));
            })
                .filter(Boolean))) : (React.createElement(SalesDetailTitle, null, (_d = payment === null || payment === void 0 ? void 0 : payment.salesPerManagers) === null || _d === void 0 ? void 0 : _d.map(function (item) {
                var _a;
                return (React.createElement("div", { key: item.id, style: { width: '100%' } },
                    !props.hideMemo && (React.createElement(FluidRow, { justify: "space-between" },
                        React.createElement(TextComponent, { children: ((_a = item.manager) === null || _a === void 0 ? void 0 : _a.displayName) || '담당자 없음' }),
                        React.createElement(TextComponent, { children: KRWFomatToString({
                                value: item.totalPrice
                            }) }))),
                    methodKeys
                        .map(function (key) {
                        var value = item.methods[key];
                        if (!value) {
                            return;
                        }
                        return (React.createElement(FluidRow, { key: "refund_".concat(key) },
                            React.createElement(TextComponent, { children: "[".concat(EXTENDED_SALES_MEHOD_KOREAN[key], "]"), color: COLORS.gray2 }),
                            React.createElement(TextComponent, { color: COLORS.gray2, marginLeft: 10, children: KRWFomatToString({
                                    value: value
                                }) })));
                    })
                        .filter(Boolean)));
            }))),
            (payment === null || payment === void 0 ? void 0 : payment.createCustomerPoint) ? (React.createElement(SalesDetailTitle, null,
                React.createElement(FluidRow, { justify: "space-between" },
                    React.createElement(TextComponent, { children: "[\uC801\uB9BD] \uD3EC\uC778\uD2B8" }),
                    React.createElement(TextComponent, { children: KRWFomatToString({ value: payment.createCustomerPoint }) })))) : (React.createElement("div", null)),
            React.createElement(Footer, null,
                React.createElement(TextComponent, { color: COLORS.gray1, marginBottom: 10 }, (_e = props.store) === null || _e === void 0 ? void 0 : _e.name),
                ((_f = props.store) === null || _f === void 0 ? void 0 : _f.address) && (React.createElement(TextComponent, { color: COLORS.gray2, type: "caption1" }, (_g = props.store) === null || _g === void 0 ? void 0 : _g.getAddressString(true))),
                React.createElement(TextComponent, { color: COLORS.gray2, type: "caption1" },
                    "[\uC804\uD654] ",
                    ((_h = props.store) === null || _h === void 0 ? void 0 : _h.tel) || ((_j = props.store) === null || _j === void 0 ? void 0 : _j.phone) || '-'),
                React.createElement(TextComponent, { color: COLORS.gray2, type: "caption1" },
                    "[\uC0AC\uC5C5\uC790\uBC88\uD638] ",
                    props.store.businessLicenseNumber || '-')),
            React.createElement(SalesDetailTitle, { borderColor: COLORS.gray5 }),
            !props.hideMemo && (React.createElement(Footer, null,
                React.createElement(FluidRow, { justify: "space-between" },
                    React.createElement(TextComponent, { children: "".concat(isRefund ? '환불' : '결제', " \uBA54\uBAA8") }),
                    props.onChange && (React.createElement(Row, { align: "middle", style: { cursor: 'pointer' }, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var result, err_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!editable) return [3 /*break*/, 6];
                                        if (!((payment === null || payment === void 0 ? void 0 : payment.type) === SALES_TYPES.REFUND)) return [3 /*break*/, 5];
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, updateRefund.mutateAsync({
                                                id: payment.id,
                                                memo: memo,
                                                storeId: payment.storeId
                                            })];
                                    case 2:
                                        result = _a.sent();
                                        setSalesModal({
                                            salesHistory: {
                                                data: result,
                                                type: result.type
                                            }
                                        });
                                        return [3 /*break*/, 4];
                                    case 3:
                                        err_1 = _a.sent();
                                        return [3 /*break*/, 4];
                                    case 4: return [3 /*break*/, 6];
                                    case 5:
                                        props.onChange(memo);
                                        _a.label = 6;
                                    case 6:
                                        setEditable(!editable);
                                        return [2 /*return*/];
                                }
                            });
                        }); } },
                        React.createElement(Icon, { name: "edit3" }),
                        React.createElement(TextComponent, { marginLeft: 5, children: !editable ? '메모 수정' : '수정 완료' })))),
                React.createElement(Marginer, { height: 10 }),
                React.createElement(FluidRow, { justify: "start" }, !editable ? (React.createElement(TextComponent, { color: COLORS.gray2, children: (payment === null || payment === void 0 ? void 0 : payment.memo) || '메모가 없습니다.' })) : (React.createElement(TextArea, { autoFocus: true, onChange: function (ev) { return setMemo(ev.target.value); }, value: memo }))))))));
};
var Footer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-items: start;\n  flex-direction: column;\n  padding-top: 16px;\n"], ["\n  display: flex;\n  justify-items: start;\n  flex-direction: column;\n  padding-top: 16px;\n"])));
export var SalesDetailContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-shadow: ", ";\n  padding: 30px;\n  border: solid 1px var(--gray-7);\n  background-color: var(--white);\n"], ["\n  box-shadow: ", ";\n  padding: 30px;\n  border: solid 1px var(--gray-7);\n  background-color: var(--white);\n"])), function (props) { return (props.$hideBoxShadow ? 0 : "".concat(BOX_SHADOW)); });
var SalesDetailTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border-bottom: solid 1px ", ";\n  padding-top: 16px;\n  padding-bottom: 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border-bottom: solid 1px ", ";\n  padding-top: 16px;\n  padding-bottom: 16px;\n"])), function (props) { return props.borderColor || COLORS.gray7; });
var templateObject_1, templateObject_2, templateObject_3;
