var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useForm } from 'react-hook-form';
import { get, isEmpty, times, floor, sum, isNumber, useLocalStorage } from 'renderer/utils';
import { ManagerQuery, TicketTemplateQuery } from 'renderer/queries';
import { AlertSentenceWithIcon, AntButton, ButtonWithPlusCircle, Icon, Marginer, NumberFormatInput } from 'renderer/components';
import Row from 'antd/lib/row';
import { Col } from 'antd/lib/grid';
import { TextComponent } from 'renderer/components/Typography';
import { BOX_SHADOW, COLORS } from 'renderer/styles';
import { KRWFomat, KRWFomatToString, KRWFomatWithoutWon, BigTitleModal } from 'renderer/components';
import Dropdown from 'antd/lib/dropdown';
import { TICKET_TYPE, TicketModel, getOtherDataFromUsingData, getOtherDataFromTicket } from 'renderer/models';
import styled from 'styled-components';
import { handleClearPrepaidInputs } from './SalesForm';
import { IMAGES } from 'renderer/assets';
import { HookFormInputs } from 'renderer/components/Forms/Inputs';
import Menu from 'antd/lib/menu';
import dayjs from 'dayjs';
import { Select } from 'antd/lib';
import { LOCAL_STORAGE_PRODUCT_ADJUST_OPTION_AND_VALUE } from 'renderer/constants';
import debounce from 'lodash/debounce';
export var TicketInput = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    // FIXME: type
    var ticket = props.methods.watch('ticket');
    var templateQuery = TicketTemplateQuery.useTicketTemplates((_a = props.store) === null || _a === void 0 ? void 0 : _a.id, { enabled: !!((_b = props.store) === null || _b === void 0 ? void 0 : _b.id) });
    var ticketTemplate = ticket && ((_c = templateQuery.data) === null || _c === void 0 ? void 0 : _c.find(function (item) { var _a; return item.id === ((_a = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate) === null || _a === void 0 ? void 0 : _a.id); }));
    var targetCompleteData = getOtherDataFromTicket(ticket);
    var isUpdated = (_e = (_d = props.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.isUpdated;
    React.useEffect(function () {
        var _a;
        props.methods.setValue('ticket.copiedTicketTemplate.totalPrice', ((_a = targetCompleteData === null || targetCompleteData === void 0 ? void 0 : targetCompleteData.copiedTicketTemplate) === null || _a === void 0 ? void 0 : _a.totalPrice) || 0);
        props.methods.trigger();
    }, [(_f = targetCompleteData === null || targetCompleteData === void 0 ? void 0 : targetCompleteData.copiedTicketTemplate) === null || _f === void 0 ? void 0 : _f.totalPrice]);
    React.useEffect(function () {
        props.methods.trigger(['ticket']);
    }, [ticket === null || ticket === void 0 ? void 0 : ticket.expiredAt, (_g = ticket === null || ticket === void 0 ? void 0 : ticket.data) === null || _g === void 0 ? void 0 : _g.startedAt, (_h = ticket === null || ticket === void 0 ? void 0 : ticket.data) === null || _h === void 0 ? void 0 : _h.expiredAt]);
    var renderByType = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        switch ((_a = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate) === null || _a === void 0 ? void 0 : _a.type) {
            case TICKET_TYPE.CHARGING_MONEY:
                return (React.createElement(React.Fragment, null,
                    React.createElement(MarginBottomCol, { xs: 12, sm: 12, md: 6 },
                        React.createElement(HookFormInputs.NumberInput, { label: "\uCDA9\uC804\uAE08\uC561", inputProps: {
                                suffix: '원',
                                disabled: isUpdated
                            }, controlProps: {
                                name: "ticket.data.total",
                                control: props.methods.control,
                                rules: {
                                    deps: ['ticket'],
                                    min: { value: 1, message: '충전금액은 1원이상이어야합니다.' },
                                    required: { value: true, message: '충전금액을 입력해주세요.' }
                                }
                            } })),
                    React.createElement(MarginBottomCol, { xs: 12, sm: 12, md: 6 },
                        React.createElement(HookFormInputs.NumberInput, { label: "\uC794\uC5EC\uAE08\uC561", inputProps: {
                                suffix: '원',
                                disabled: isUpdated
                            }, controlProps: {
                                name: "ticket.data.remain",
                                control: props.methods.control,
                                rules: isUpdated
                                    ? {}
                                    : {
                                        deps: ['ticket'],
                                        min: { value: 0, message: '잔여금액은 0원이상이어야합니다.' },
                                        max: {
                                            value: ((_b = ticket.data) === null || _b === void 0 ? void 0 : _b.total) || 1,
                                            message: "\uC794\uC5EC\uAE08\uC561\uC740 ".concat(((_c = ticket.data) === null || _c === void 0 ? void 0 : _c.total) || 1, "\uC6D0 \uC774\uD558\uB85C \uC785\uB825\uD574\uC8FC\uC138\uC694.")
                                        },
                                        required: { value: true, message: '잔여금액을 입력해주세요.' }
                                    }
                            } }))));
            case TICKET_TYPE.COUNT:
                return (React.createElement(React.Fragment, null,
                    React.createElement(MarginBottomCol, { xs: 12, sm: 12, md: 6 },
                        React.createElement(HookFormInputs.NumberInput, { label: "\uCDA9\uC804\uD69F\uC218", inputProps: {
                                suffix: '회',
                                disabled: isUpdated
                            }, controlProps: {
                                name: "ticket.data.total",
                                control: props.methods.control,
                                rules: {
                                    deps: ['ticket'],
                                    min: { value: 1, message: '충전횟수는 1회 이상이어야합니다.' },
                                    required: { value: true, message: '충전횟수를 입력해주세요.' }
                                }
                            } })),
                    React.createElement(MarginBottomCol, { xs: 12, sm: 12, md: 6 },
                        React.createElement(HookFormInputs.NumberInput, { label: "\uC794\uC5EC\uD69F\uC218", inputProps: {
                                suffix: '회',
                                disabled: isUpdated
                            }, controlProps: {
                                name: "ticket.data.remain",
                                control: props.methods.control,
                                rules: {
                                    deps: ['ticket'],
                                    min: { value: 1, message: '잔여횟수는 1회 이상으로 입력해주세요.' },
                                    max: {
                                        value: ((_d = ticket.data) === null || _d === void 0 ? void 0 : _d.total) || 1,
                                        message: "\uC794\uC5EC\uD69F\uC218\uB294 ".concat(((_e = ticket.data) === null || _e === void 0 ? void 0 : _e.total) || 1, "\uD68C \uC774\uD558\uB85C \uC785\uB825\uD574\uC8FC\uC138\uC694.")
                                    },
                                    required: { value: true, message: '잔여횟수를 입력해주세요.' }
                                }
                            } }))));
            case TICKET_TYPE.TERM:
                return (React.createElement(React.Fragment, null,
                    React.createElement(MarginBottomCol, { xs: 12, sm: 12, md: 6 },
                        React.createElement(HookFormInputs.DatePicker, { label: "\uC2DC\uC791\uC77C\uC2DC", inputProps: {
                                disabled: isUpdated,
                                value: dayjs((_f = ticket === null || ticket === void 0 ? void 0 : ticket.data) === null || _f === void 0 ? void 0 : _f.startedAt)
                            }, controlProps: {
                                name: "ticket.data.startedAt",
                                control: props.methods.control,
                                rules: {
                                    required: {
                                        value: true,
                                        message: '시작일을 입력해주세요.'
                                    },
                                    validate: function (value) {
                                        var _a;
                                        var ticketExpiredAt = ticket === null || ticket === void 0 ? void 0 : ticket.expiredAt;
                                        if (ticketExpiredAt) {
                                            if (dayjs(value).isAfter(ticketExpiredAt, 'day')) {
                                                return '시작일과 종료일은 티켓유효기간 이전이어야합니다.';
                                            }
                                        }
                                        var expiredAt = (_a = ticket.data) === null || _a === void 0 ? void 0 : _a.expiredAt;
                                        if (dayjs(value).isAfter(expiredAt, 'hour') || dayjs(value).isSame(expiredAt, 'day')) {
                                            return '시작일을 종료일보다 이전으로 지정해주세요.';
                                        }
                                        return true;
                                    }
                                }
                            } })),
                    React.createElement(MarginBottomCol, { xs: 12, sm: 12, md: 6 },
                        React.createElement(HookFormInputs.DatePicker, { label: "\uC885\uB8CC\uC77C\uC2DC", inputProps: {
                                disabled: isUpdated,
                                value: dayjs((_g = ticket === null || ticket === void 0 ? void 0 : ticket.data) === null || _g === void 0 ? void 0 : _g.expiredAt)
                            }, controlProps: {
                                name: "ticket.data.expiredAt",
                                control: props.methods.control,
                                rules: {
                                    required: {
                                        value: true,
                                        message: '종료일을 입력해주세요.'
                                    },
                                    validate: function (value) {
                                        var _a;
                                        var ticketExpiredAt = ticket === null || ticket === void 0 ? void 0 : ticket.expiredAt;
                                        if (ticketExpiredAt) {
                                            if (dayjs(value).isAfter(ticketExpiredAt, 'day')) {
                                                return '시작일과 종료일은 티켓유효기간 이전이어야합니다.';
                                            }
                                        }
                                        var startedAt = (_a = ticket.data) === null || _a === void 0 ? void 0 : _a.startedAt;
                                        if (dayjs(value).isBefore(startedAt, 'hour') || dayjs(value).isSame(startedAt, 'day')) {
                                            return '종료일을 시작일 이후로 지정해주세요.';
                                        }
                                        return true;
                                    }
                                }
                            } }))));
            default:
                return React.createElement("div", null);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'none' } },
            React.createElement(HookFormInputs.NumberInput, { controlProps: {
                    name: "ticket.ticketTemplateId",
                    control: props.methods.control,
                    rules: {
                        deps: ['ticket'],
                        required: true
                    }
                }, inputProps: {} })),
        !!(ticket === null || ticket === void 0 ? void 0 : ticket.ticketTemplateId) ? (React.createElement("div", null,
            React.createElement(BuyingItemCard, null,
                React.createElement(Row, { justify: "space-between", gutter: 16 },
                    React.createElement(MarginBottomCol, null,
                        React.createElement(Row, null,
                            React.createElement(TextComponent, { children: ticketTemplate === null || ticketTemplate === void 0 ? void 0 : ticketTemplate.name, type: "subtitle2" }),
                            React.createElement(TextComponent, { marginLeft: 4, children: ticketTemplate === null || ticketTemplate === void 0 ? void 0 : ticketTemplate.additional, type: "caption1", color: COLORS.gray2, ellipsis: true })),
                        React.createElement(TextComponent, { children: KRWFomatToString({ value: ticketTemplate === null || ticketTemplate === void 0 ? void 0 : ticketTemplate.price }), color: COLORS.gray2 })),
                    React.createElement(MarginBottomCol, null,
                        React.createElement(Row, { align: "middle" },
                            React.createElement(TextComponent, { children: KRWFomatToString({ value: ticketTemplate === null || ticketTemplate === void 0 ? void 0 : ticketTemplate.totalPrice }), type: "subtitle2" }),
                            !isUpdated && (React.createElement("div", { style: { padding: '10px' } },
                                React.createElement(Icon, { name: "x", color: COLORS.calendarRedActive, onClick: function () {
                                        props.methods.setValue('ticket', { ticketTemplateId: null });
                                        handleClearPrepaidInputs(props.methods);
                                    } })))))),
                React.createElement(Marginer, { height: 15 }),
                React.createElement(Row, { gutter: 16, style: { width: '100%' } },
                    React.createElement(MarginBottomCol, { xs: 8, sm: 8, md: 4 },
                        React.createElement(HookFormInputs.NumberInputWithButtons, { label: "\uC218\uB7C9", inputProps: {
                                disabled: true
                            }, controlProps: {
                                name: "ticket.amount",
                                control: props.methods.control,
                                rules: {
                                    // required: { value: true, message: '수량을 입력해주세요.' },
                                    deps: ['ticket']
                                }
                            } })),
                    React.createElement(MarginBottomCol, { xs: 16, sm: 16, md: 8 },
                        React.createElement(HookFormInputs.NumberInput, { label: "\uAC00\uACA9", controlProps: {
                                name: "ticket.copiedTicketTemplate.price",
                                control: props.methods.control,
                                rules: {
                                    required: { value: true, message: '가격을 입력해주세요.' },
                                    min: { value: 0, message: '가격은 0원 이상이어야합니다.' },
                                    deps: ['ticket']
                                }
                            }, inputProps: {
                                suffix: '원'
                            } }),
                        React.createElement(HookFormInputs.NumberInput, { controlProps: {
                                name: "ticket.copiedTicketTemplate.totalPrice",
                                control: props.methods.control,
                                rules: {
                                    deps: ['ticket']
                                }
                            }, inputProps: {
                                style: { display: 'none' },
                                value: ((_j = targetCompleteData === null || targetCompleteData === void 0 ? void 0 : targetCompleteData.copiedTicketTemplate) === null || _j === void 0 ? void 0 : _j.totalPrice) || 0
                            } })),
                    React.createElement(MarginBottomCol, { xs: 24, sm: 24, md: 12 },
                        React.createElement(TextComponent, { children: "\uC81C\uACF5 \uD61C\uD0DD", marginBottom: 5 }),
                        React.createElement(TextBox, { children: (_k = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate) === null || _k === void 0 ? void 0 : _k.benefitString, disabled: true }))),
                React.createElement(Row, { gutter: 16, style: { width: '100%' } },
                    renderByType(),
                    React.createElement(MarginBottomCol, { xs: 24, sm: 24, md: 12 },
                        React.createElement(HookFormInputs.DatePicker, { label: "\uC720\uD6A8\uAE30\uAC04", inputProps: {
                                disabled: isUpdated,
                                value: (ticket === null || ticket === void 0 ? void 0 : ticket.expiredAt) ? dayjs(ticket.expiredAt) : null
                            }, controlProps: {
                                name: "ticket.expiredAt",
                                control: props.methods.control
                            } }))),
                React.createElement(Row, { gutter: 16, style: { width: '100%' } },
                    React.createElement(MarginBottomCol, { xs: 24, sm: 24, md: 12 },
                        React.createElement(SalesPerManagerInput, { store: props.store, completeData: targetCompleteData, methods: props.methods, name: "ticket" })),
                    React.createElement(MarginBottomCol, { xs: 24, sm: 24, md: 12 }))))) : (React.createElement(EmptyItem, { messages: ['+ 버튼을 클릭해', '판매할 선불액∙정기권을 선택해주세요.'], onClick: props.onClickEmpty }))));
};
export var ProductInputList = function (props) {
    var _a;
    // FIXME: type
    var customer = props.customer;
    var _b = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _b[1];
    var watchFieldArray = props.methods.watch('usingHistory');
    var customerId = props.methods.watch('customerId');
    React.useEffect(function () {
        var _a;
        var newUsings = (_a = (watchFieldArray || [])) === null || _a === void 0 ? void 0 : _a.map(function (item, index) {
            return __assign(__assign({}, item), { ticketHistory: { ticketId: '' } });
        });
        props.methods.setValue('usingHistory', newUsings);
        props.methods.trigger();
    }, [customerId]);
    var debouncedCalculation = debounce(function () {
        props.methods.trigger(['usingHistory', 'leftPayment']);
    }, 500);
    var activeTickets = ((_a = props.customer) === null || _a === void 0 ? void 0 : _a.activeAvailableTicketsWithoutCharging) || [];
    var handleDeleteItems = React.useCallback(function (index) { return function () {
        var _a;
        var newUsings = (_a = (watchFieldArray || [])) === null || _a === void 0 ? void 0 : _a.filter(function (item, itemIndex) {
            return itemIndex !== index;
        });
        props.methods.setValue('usingHistory', isEmpty(newUsings) ? null : newUsings, { shouldValidate: true });
        setTimeout(function () {
            forceUpdate();
        });
    }; }, [watchFieldArray, debouncedCalculation, props.methods]);
    return isEmpty(watchFieldArray) ? (React.createElement(EmptyItem, { onClick: props.onClickEmpty })) : (React.createElement("div", null, watchFieldArray.map(function (field, index) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var prevProduct = field.product || (((_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.usingHistory) && ((_e = (_d = (_c = props.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.usingHistory[index]) === null || _e === void 0 ? void 0 : _e.product));
        var isEqualPrevProductId = prevProduct && prevProduct.id === field.productId;
        var completeData = getOtherDataFromUsingData(__assign(__assign({}, field), { 
            // FIXME: 업데이트 일때만
            product: isEqualPrevProductId ? prevProduct : null }), customer, props.products);
        var product = completeData.product;
        var adjustOption = completeData.adjustOption;
        var hasTicketHistory = (_f = completeData.ticketHistory) === null || _f === void 0 ? void 0 : _f.ticketId;
        var totalPrice = completeData.finalPrice + (completeData.deductionPriceByTicket || 0);
        var currentDeduction = completeData.amount;
        // 기존 사용티켓이 있는경우 이것을 보여주며 사용량을 실제 remain에 더해줌
        var prevUsing = (_j = (_h = (_g = props.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.usingHistory) === null || _j === void 0 ? void 0 : _j.find(function (item) { return item.id === field.id; });
        var prevDeduction = prevUsing === null || prevUsing === void 0 ? void 0 : prevUsing.amount;
        var prevUsedTicket = (_k = prevUsing === null || prevUsing === void 0 ? void 0 : prevUsing.ticketHistory) === null || _k === void 0 ? void 0 : _k.ticket;
        var productTickets = (activeTickets === null || activeTickets === void 0 ? void 0 : activeTickets.filter(function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.copiedTicketTemplate) === null || _a === void 0 ? void 0 : _a.storeProductId) === completeData.productId; })) || [];
        if (prevUsedTicket) {
            // 과거에 사용한 티켓이 있다면 +해줌
            productTickets = [
                prevUsedTicket.type === TICKET_TYPE.COUNT
                    ? new TicketModel(__assign(__assign({}, prevUsedTicket), { data: __assign(__assign({}, prevUsedTicket.data), { remain: prevUsedTicket.data.remain + prevDeduction }) }))
                    : prevUsedTicket
            ].concat(productTickets.filter(function (item) { return item.id !== (prevUsedTicket === null || prevUsedTicket === void 0 ? void 0 : prevUsedTicket.id); }));
        }
        var ticketOptions = [
            { label: '보유정기권', options: [] },
            { label: '공유정기권', options: [] }
        ];
        productTickets.map(function (item) {
            var _a, _b, _c;
            var remainInfo = (item === null || item === void 0 ? void 0 : item.type) === TICKET_TYPE.COUNT ? "".concat(currentDeduction, "\uD68C / ").concat(item.data.remain, "\uD68C") : '기간권 사용';
            var isShared = customerId !== item.customerId;
            var options = isShared ? ticketOptions[1].options : ticketOptions[0].options;
            options.push({
                value: item.id,
                label: (React.createElement(React.Fragment, null,
                    React.createElement(Row, { align: "middle", justify: "space-between" },
                        React.createElement(TextComponent, { children: item.name, type: "caption1", marginTop: 6 })),
                    React.createElement(Row, { align: "middle", justify: "space-between" },
                        React.createElement(TextComponent, { marginTop: 3, marginBottom: 6, children: "".concat(remainInfo, "  ").concat(item === null || item === void 0 ? void 0 : item.getRelationDescription(Number((_a = props.customer) === null || _a === void 0 ? void 0 : _a.id), __spreadArray(__spreadArray([], (_b = props.customer) === null || _b === void 0 ? void 0 : _b.mainCustomerRelations, true), (_c = props.customer) === null || _c === void 0 ? void 0 : _c.otherCustomerRelations, true))), type: "caption1", color: COLORS.gray2 }))))
            });
        });
        var hasTickets = !isEmpty(productTickets);
        return !product ? (React.createElement("div", null)) : (React.createElement(BuyingItemCard, { key: "".concat(field.id, "_").concat(index) },
            React.createElement(Row, { justify: "space-between", gutter: 16 },
                React.createElement(MarginBottomCol, null,
                    React.createElement(TextComponent, { children: product.nameWithGroupName, type: "subtitle2" }),
                    React.createElement(TextComponent, { children: product.priceWithTime, color: COLORS.gray2 })),
                React.createElement(MarginBottomCol, null,
                    React.createElement(Row, { align: "middle" },
                        React.createElement(TextComponent, { children: KRWFomatToString({ value: completeData.finalPrice }), type: "subtitle2" }),
                        React.createElement("div", { style: { padding: '10px' } },
                            React.createElement(Icon, { name: "x", color: COLORS.calendarRedActive, onClick: handleDeleteItems(index) }))),
                    hasTicketHistory && (React.createElement(TextComponent, { type: "caption1", style: { textDecoration: 'line-through' }, color: COLORS.gray2, children: KRWFomatToString({ value: totalPrice }) })))),
            React.createElement(Marginer, { height: 15 }),
            React.createElement(Row, { gutter: 16, style: { width: '100%' } },
                React.createElement(MarginBottomCol, { xs: 8, sm: 8, md: 4 },
                    React.createElement(HookFormInputs.NumberInputWithButtons, { label: "\uC218\uB7C9", inputProps: {
                            onChange: debouncedCalculation
                        }, controlProps: {
                            name: "usingHistory.".concat(index, ".amount"),
                            control: props.methods.control,
                            rules: {
                                deps: ['usingHistory', 'leftPayment']
                            }
                        } })),
                React.createElement(MarginBottomCol, { xs: 16, sm: 16, md: 8 },
                    React.createElement(HookFormInputs.NumberInput, { label: "\uAC00\uACA9", controlProps: {
                            name: "usingHistory.".concat(index, ".price"),
                            control: props.methods.control,
                            rules: {
                                required: { value: true, message: '가격을 입력해주세요.' },
                                min: { value: 0, message: '가격은 0원 이상이어야합니다.' },
                                deps: ['usingHistory', 'leftPayment']
                            }
                        }, inputProps: {
                            suffix: '원',
                            onChange: debouncedCalculation
                        } })),
                React.createElement(MarginBottomCol, { xs: 12, sm: 12, md: 6 },
                    React.createElement(FluidSelect, { label: "\uAC00\uACA9\uC870\uC815", controlProps: {
                            name: "usingHistory.".concat(index, ".adjustOption"),
                            control: props.methods.control,
                            defaultValue: '',
                            rules: {
                                validate: function (value) {
                                    return hasTicketHistory
                                        ? !['minusPercent', 'minusAmount'].includes(value)
                                            ? true
                                            : '정기권 차감의 경우 할인옵션을 적용할 수 없습니다.'
                                        : true;
                                },
                                deps: ['usingHistory', 'leftPayment']
                            }
                        }, inputProps: {
                            onChange: function (value) {
                                props.methods.setValue("usingHistory.".concat(index, ".adjustValue"), null, { shouldValidate: true });
                                debouncedCalculation();
                                // props.methods.trigger(['usingHistory', 'leftPayment']);
                            },
                            options: [
                                { label: '없음', value: '' },
                                { label: '% 할인', value: 'minusPercent' },
                                { label: '정액 할인', value: 'minusAmount' },
                                { label: '% 추가', value: 'plusPercent' },
                                { label: '정액 추가', value: 'plusAmount' }
                            ]
                        } })),
                React.createElement(MarginBottomCol, { xs: 12, sm: 12, md: 6 },
                    React.createElement(HookFormInputs.NumberInput, { label: "\uD560\uC778/\uCD94\uAC00", controlProps: {
                            name: "usingHistory.".concat(index, ".adjustValue"),
                            control: props.methods.control,
                            rules: {
                                validate: function (value) {
                                    var _a, _b;
                                    var max = (adjustOption === null || adjustOption === void 0 ? void 0 : adjustOption.includes('Percent'))
                                        ? 100
                                        : ((_a = props.methods) === null || _a === void 0 ? void 0 : _a.getValues("usingHistory.".concat(index, ".price"))) *
                                            ((_b = props.methods) === null || _b === void 0 ? void 0 : _b.getValues("usingHistory.".concat(index, ".amount")));
                                    return value > max ? "\uD560\uC778/\uCD94\uAC00\uB294 ".concat(max, "\uC774\uD558\uC5EC\uC57C \uD569\uB2C8\uB2E4.") : true;
                                },
                                min: {
                                    value: 0,
                                    message: '조정량은 0이상이어야합니다.'
                                },
                                deps: ['usingHistory', 'leftPayment']
                            }
                        }, inputProps: {
                            disabled: !adjustOption,
                            suffix: (adjustOption === null || adjustOption === void 0 ? void 0 : adjustOption.includes('Percent')) ? '%' : '원',
                            onChange: debouncedCalculation
                        } }))),
            React.createElement(Row, { gutter: 16, style: { width: '100%' } },
                React.createElement(MarginBottomCol, { xs: 24, sm: 24, md: 12 },
                    React.createElement(StyledTicketInput, { memoDeps: [productTickets], label: "\uC815\uAE30\uAD8C \uCC28\uAC10", controlProps: {
                            name: "usingHistory.".concat(index, ".ticketHistory.ticketId"),
                            control: props.methods.control,
                            // FIXME: 이 코드로 부작용 없는지 봐야함
                            // shouldUnregister: true,
                            rules: {
                                validate: function (value) {
                                    var _a;
                                    if (!hasTicketHistory) {
                                        return true;
                                    }
                                    else {
                                        var targetTicket_1 = productTickets.find(function (ticket) { var _a, _b; return ticket.id === ((_b = (_a = completeData === null || completeData === void 0 ? void 0 : completeData.ticketHistory) === null || _a === void 0 ? void 0 : _a.ticket) === null || _b === void 0 ? void 0 : _b.id); });
                                        var targetAmount = sum(watchFieldArray.map(function (history, currentIndex) {
                                            var _a;
                                            var targetCompleteData = getOtherDataFromUsingData(history, customer, props.products);
                                            var ticketInHistory = (_a = targetCompleteData === null || targetCompleteData === void 0 ? void 0 : targetCompleteData.ticketHistory) === null || _a === void 0 ? void 0 : _a.ticket;
                                            if ((ticketInHistory === null || ticketInHistory === void 0 ? void 0 : ticketInHistory.id) && (targetTicket_1 === null || targetTicket_1 === void 0 ? void 0 : targetTicket_1.id) && targetTicket_1.id === ticketInHistory.id) {
                                                return history.amount;
                                            }
                                            else {
                                                return 0;
                                            }
                                        }));
                                        if ((targetTicket_1 === null || targetTicket_1 === void 0 ? void 0 : targetTicket_1.type) === TICKET_TYPE.TERM && targetAmount > 1) {
                                            return '1일 2회이상 차감이 불가능합니다.';
                                        }
                                        else if ((targetTicket_1 === null || targetTicket_1 === void 0 ? void 0 : targetTicket_1.type) === TICKET_TYPE.COUNT &&
                                            targetAmount > (((_a = targetTicket_1 === null || targetTicket_1 === void 0 ? void 0 : targetTicket_1.data) === null || _a === void 0 ? void 0 : _a.remain) || 0)) {
                                            return '차감량이 잔여량을 초과합니다.';
                                        }
                                        else {
                                            return true;
                                        }
                                    }
                                },
                                deps: ['usingHistory', 'leftPayment']
                            }
                        }, inputProps: {
                            disabled: !customer,
                            onChange: debouncedCalculation,
                            dropdownRender: function (menu) {
                                return (React.createElement(DropdownWrapper, null,
                                    React.createElement(ButtonWithPlusCircle, { children: "\uC815\uAE30\uAD8C \uD310\uB9E4", style: { width: '100%' }, onClick: function () { return props.onClickPurchaseTicket(Number(customer.id)); } }),
                                    menu));
                            },
                            placeholder: (customer === null || customer === void 0 ? void 0 : customer.id) ? (hasTickets ? (React.createElement(TextComponent, { children: "\uC815\uAE30\uAD8C \uC788\uC74C(\uD074\uB9AD\uD574 \uC815\uAE30\uAD8C\uC744 \uCC28\uAC10\uD558\uC138\uC694) ", type: "caption1", marginTop: 7 })) : (React.createElement(TextComponent, { children: "\uC815\uAE30\uAD8C \uC5C6\uC74C(\uD074\uB9AD\uD574 \uC815\uAE30\uAD8C\uC744 \uD310\uB9E4\uD558\uC138\uC694)", type: "caption1", marginTop: 7 }))) : (React.createElement(TextComponent, { children: "\uACE0\uAC1D\uC744 \uC120\uD0DD\uD558\uC2DC\uBA74 \uC815\uAE30\uAD8C\uC744 \uCC28\uAC10\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.", type: "caption1", marginTop: 7 })),
                            options: [
                                {
                                    value: '',
                                    label: React.createElement(TextComponent, { children: "\uCC28\uAC10\uC548\uD568", type: "caption1" })
                                }
                            ]
                                .concat(ticketOptions)
                                .filter(Boolean)
                        } })),
                React.createElement(MarginBottomCol, { xs: 24, sm: 24, md: 12 },
                    React.createElement(SalesPerManagerInput, { store: props.store, completeData: completeData, methods: props.methods, name: "usingHistory.".concat(index) }))),
            React.createElement(HookFormInputs.NumberInput, { controlProps: {
                    name: "usingHistory.".concat(index, ".id"),
                    control: props.methods.control
                }, inputProps: {
                    disabled: !adjustOption,
                    style: { display: 'none' }
                } })));
    })));
};
var StyledTicketInput = styled(HookFormInputs.SelectInput)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n\n  & span.ant-select-selection-item > .ant-row:nth-child(1) {\n    height: 16px;\n  }\n"], ["\n  width: 100%;\n\n  & span.ant-select-selection-item > .ant-row:nth-child(1) {\n    height: 16px;\n  }\n"])));
var DropdownWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var ReservationFeeCard = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var price = ((_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.usingHistory) && ((_e = (_d = (_c = props.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.usingHistory[0]) === null || _e === void 0 ? void 0 : _e.price);
    var completeData = (_f = props.methods) === null || _f === void 0 ? void 0 : _f.watch('usingHistory.0');
    var salesPerManagers = (_g = completeData === null || completeData === void 0 ? void 0 : completeData.salesPerManagers) === null || _g === void 0 ? void 0 : _g.filter(function (item) { return item.managerId; });
    return completeData ? (React.createElement(React.Fragment, null,
        React.createElement(AlertSentenceWithIcon, { descriptions: ['예약금만 매출등록한 경우 방문횟수가 추가되지 않습니다. '], style: { marginBottom: '10px' } }),
        React.createElement(BuyingItemCard, null,
            React.createElement(Row, { justify: "space-between", gutter: 16 },
                React.createElement(MarginBottomCol, null, "\uC608\uC57D\uAE08"),
                React.createElement(MarginBottomCol, null,
                    React.createElement(Row, { align: "middle" },
                        React.createElement(TextComponent, { children: KRWFomatToString({ value: price }), type: "subtitle2" })))),
            React.createElement(Row, { gutter: 16 },
                React.createElement(MarginBottomCol, { xs: 24, sm: 24, md: 12 },
                    React.createElement(HookFormInputs.NumberInput, { label: "\uAC00\uACA9", controlProps: {
                            name: "usingHistory.0.price",
                            control: props.methods.control,
                            rules: {
                                required: { value: true, message: '가격을 입력해주세요.' },
                                min: { value: 0, message: '가격은 0원 이상이어야합니다.' },
                                deps: ['usingHistory', 'leftPayment']
                            }
                        }, inputProps: {
                            suffix: '원'
                        } })),
                React.createElement(MarginBottomCol, { xs: 24, sm: 24, md: 12 },
                    React.createElement(SalesPerManagerInput, { store: props.store, completeData: __assign(__assign({}, completeData), { finalPrice: (completeData === null || completeData === void 0 ? void 0 : completeData.price) || price, salesPerManagers: salesPerManagers }), methods: props.methods, name: "usingHistory.0" })))))) : (React.createElement("div", null));
};
var SalesPerManagerInput = function (props) {
    var _a, _b;
    var managers = ManagerQuery.getManagerDropdownOptions();
    var store = props.store;
    var _c = React.useState(false), visible = _c[0], setVisible = _c[1];
    var finalPrice = props.completeData.finalPrice || 0;
    var deductionPriceByTicket = props.completeData.deductionPriceByTicket || 0;
    var totalPrice = finalPrice + deductionPriceByTicket;
    var _d = React.useState(0), prevTotalPrice = _d[0], setTotalPrice = _d[1];
    var setOptions = {
        shouldDirty: true,
        shouldValidate: true
    };
    var debouncedCalculation = debounce(function () {
        var shareRate = get(props.completeData, "salesPerManagers.0.shareRate");
        if (prevTotalPrice !== totalPrice) {
            var newSalesPerManager = void 0;
            if (shareRate) {
                newSalesPerManager = handleChangeOnAutoMode(0, shareRate);
            }
            else {
                var firstSharePrice = get(props.completeData, "salesPerManagers.0.sharePrice") || 0;
                newSalesPerManager = handleChangeOnManualMode(0, firstSharePrice);
            }
            if (newSalesPerManager) {
                setValueToFormInModal(newSalesPerManager, totalPrice);
            }
        }
    }, 300);
    React.useEffect(function () {
        debouncedCalculation();
        return function () {
            debouncedCalculation.cancel();
        };
    }, [totalPrice]);
    var setValueToFormInModal = function (value, totalPrice) {
        var _a;
        (_a = props.methods) === null || _a === void 0 ? void 0 : _a.setValue("".concat(props.name, ".salesPerManagers"), value, setOptions);
        formInModal.setValue("salesPerManagers", value);
        setTotalPrice(totalPrice);
    };
    var formInModal = useForm({ mode: 'onChange' });
    /**
     * 가격으로 조절
     * @param index
     * @param value
     * @returns
     */
    var handleChangeOnManualMode = function (index, value) {
        var _a, _b;
        var newSalesPerManager = ((_a = formInModal.getValues()) === null || _a === void 0 ? void 0 : _a.salesPerManagers) || ((_b = props.completeData) === null || _b === void 0 ? void 0 : _b.salesPerManagers);
        // prevSalesPerManager;
        var otherKey = index === 0 ? 1 : 0;
        if (newSalesPerManager[index]) {
            newSalesPerManager[index].shareRate = undefined;
            newSalesPerManager[index].sharePrice = value;
        }
        if (newSalesPerManager[otherKey]) {
            newSalesPerManager[otherKey].shareRate = undefined;
            newSalesPerManager[otherKey].sharePrice = totalPrice - value;
        }
        return newSalesPerManager;
    };
    /**
     * rate로 조절
     * @param index
     * @param value
     * @returns
     */
    var handleChangeOnAutoMode = function (index, value) {
        var _a, _b;
        var newSalesPerManager = ((_a = formInModal.getValues()) === null || _a === void 0 ? void 0 : _a.salesPerManagers) || ((_b = props.completeData) === null || _b === void 0 ? void 0 : _b.salesPerManagers);
        // prevSalesPerManager;
        var otherKey = index === 0 ? 1 : 0;
        var otherRate = 100 - value;
        if (newSalesPerManager.length === 1) {
            newSalesPerManager = [
                {
                    managerId: newSalesPerManager[0].managerId,
                    shareRate: 100,
                    sharePrice: totalPrice
                }
            ];
        }
        else {
            if (newSalesPerManager[index] && newSalesPerManager[otherKey]) {
                newSalesPerManager[index].shareRate = value;
                newSalesPerManager[index].sharePrice = floor((totalPrice * value) / 100);
                newSalesPerManager[otherKey].shareRate = otherRate;
                newSalesPerManager[otherKey].sharePrice = floor(totalPrice - newSalesPerManager[index].sharePrice);
                if (newSalesPerManager[index].sharePrice + newSalesPerManager[otherKey].sharePrice !== totalPrice) {
                    var diff = totalPrice - (newSalesPerManager[index].sharePrice + newSalesPerManager[otherKey].sharePrice);
                    times(diff).map(function (diffKey) {
                        var index = diffKey % 2;
                        newSalesPerManager[index].sharePrice = newSalesPerManager[index].sharePrice + 1;
                    });
                }
            }
        }
        return newSalesPerManager;
    };
    var menus = (React.createElement(Menu, { mode: "vertical" },
        React.createElement(ButtonWithPlusCircle, { children: "\uB2F4\uB2F9\uC790 \uBCF5\uC218 \uC120\uD0DD", disabled: !store.hasSubscription, style: { width: '100%' }, onClick: function () {
                var _a;
                setVisible(true);
                formInModal.reset({ salesPerManagers: (_a = props.completeData) === null || _a === void 0 ? void 0 : _a.salesPerManagers });
            } }),
        managers.map(function (manager) {
            return (React.createElement(Menu.Item, { disabled: manager.disabled, onClick: function () {
                    var newData = [
                        {
                            managerId: manager.value,
                            shareRate: 100,
                            sharePrice: totalPrice
                        },
                        {
                            managerId: undefined,
                            shareRate: 0,
                            sharePrice: 0
                        }
                    ];
                    formInModal.setValue("salesPerManagers", newData, setOptions);
                    props.methods.setValue("".concat(props.name, ".salesPerManagers"), newData, setOptions);
                }, key: manager.value }, manager.label || '삭제된 직원'));
        })));
    var formValues = formInModal.watch();
    var formState = formInModal.formState;
    var managerText = (_b = (_a = props.completeData) === null || _a === void 0 ? void 0 : _a.salesPerManagers) === null || _b === void 0 ? void 0 : _b.map(function (salesPerManager) {
        var _a;
        return "".concat(((_a = ManagerQuery.getManagerByIdInCache(salesPerManager.managerId)) === null || _a === void 0 ? void 0 : _a.displayName) || '삭제된 직원', " ").concat(KRWFomatWithoutWon({
            value: salesPerManager.sharePrice
        }));
    }).join(' / ');
    var spms = props.completeData.salesPerManagers;
    var handleValidate = React.useCallback(function () {
        var spmTotal = sum(spms.map(function (item) { return item.sharePrice || 0; }));
        if (spmTotal !== totalPrice) {
            return '직원의 분배금액을 확인해주세요.';
        }
        else {
            return true;
        }
    }, [spms]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { overlay: menus, placement: "bottomLeft", trigger: ['click'] },
            React.createElement("div", null,
                React.createElement(TextComponent, { children: "\uB2F4\uB2F9\uC790", marginBottom: 5, type: "caption1" }),
                React.createElement(TextBox, { children: managerText }))),
        React.createElement(ErrorWithoutInput, null,
            React.createElement(HookFormInputs.NumberInput, { inputProps: {
                    suffix: '원'
                }, controlProps: {
                    name: "".concat(props.name, ".salesPerManagers.0.sharePrice"),
                    control: props.methods.control,
                    rules: {
                        validate: handleValidate
                    }
                } })),
        React.createElement("div", { style: { display: 'none' } }, [0, 1].map(function (index) {
            return (React.createElement(Row, { key: "".concat(index) },
                React.createElement(HookFormInputs.SelectInput, { label: "\uB2F4\uB2F9\uC790", controlProps: {
                        name: "".concat(props.name, ".salesPerManagers.").concat(index, ".managerId"),
                        control: props.methods.control
                    }, inputProps: {
                        placeholder: '담당자 선택',
                        options: managers
                    } }),
                React.createElement(HookFormInputs.SelectInput, { inputProps: {
                        placeholder: '할당율',
                        options: times(21).map(function (item) { return ({ label: "".concat(item * 5, "%"), value: item * 5 }); })
                    }, controlProps: {
                        name: "".concat(props.name, ".salesPerManagers.").concat(index, ".shareRate"),
                        control: props.methods.control
                    } }),
                React.createElement(HookFormInputs.NumberInput, { inputProps: {
                        suffix: '원'
                    }, controlProps: {
                        name: "".concat(props.name, ".salesPerManagers.").concat(index, ".sharePrice"),
                        control: props.methods.control
                    } })));
        })),
        React.createElement(BigTitleModal, { open: visible, onCancel: function () {
                setVisible(false);
            }, onOk: function () {
                var _a, _b;
                var newData = (_a = formInModal
                    .getValues()
                    .salesPerManagers) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.managerId && (isNumber(item.shareRate) || isNumber(item.sharePrice)) && item.sharePrice; });
                if (newData.length === 0 && totalPrice === 0) {
                    var first = ((_b = formInModal.getValues()) === null || _b === void 0 ? void 0 : _b.salesPerManagers) && formInModal.getValues().salesPerManagers[0];
                    if (first) {
                        newData = [first];
                    }
                }
                formInModal.setValue("salesPerManagers", newData, setOptions);
                props.methods.setValue("".concat(props.name, ".salesPerManagers"), newData, setOptions);
                setVisible(false);
            }, okButtonProps: {
                disabled: !isEmpty(formState.errors)
            }, title: "\uB2F4\uB2F9\uC790 \uBCF5\uC218 \uC120\uD0DD", children: React.createElement("div", { style: { padding: '20px' } },
                React.createElement("form", null,
                    React.createElement(TextComponent, { type: "headline3", children: "\uC0C1\uD488\uAE08\uC561 : ".concat(KRWFomat({ value: totalPrice })), marginBottom: 20 }),
                    [0, 1].map(function (index) {
                        var _a, _b;
                        var isLast = index === 1;
                        var disabled = !get(formValues, "salesPerManagers.1.managerId");
                        return (React.createElement(Row, { key: "".concat(index), gutter: 8, style: { width: '100%', marginBottom: '10px' } },
                            React.createElement(Col, { span: 8 },
                                React.createElement(FluidSelect, { inputProps: {
                                        placeholder: '담당자 선택',
                                        onChange: function (value) {
                                            if (!value) {
                                                formInModal.setValue("salesPerManagers.1.managerId", null);
                                                formInModal.setValue("salesPerManagers.1.sharePrice", 0);
                                                formInModal.setValue("salesPerManagers.1.shareRate", 0);
                                                formInModal.setValue("salesPerManagers.0.sharePrice", props.completeData.finalPrice);
                                                formInModal.setValue("salesPerManagers.0.shareRate", 100);
                                            }
                                        },
                                        options: managers
                                            // .filter((manager) => {
                                            //   // 이미 선택된 직원 제거
                                            //   return !(props.methods.getValues(`${props.name}.salesPerManager`)||[]).map((item) => item.managerId)?.includes(manager.id);
                                            // })
                                            .concat(isLast ? { label: '담당자 없음', value: '' } : undefined)
                                            .concat((((_a = props.completeData) === null || _a === void 0 ? void 0 : _a.salesPerManagers) || []).map(function (spm) {
                                            return !spm.managerId || managers.map(function (manager) { return manager.value; }).includes(spm.managerId)
                                                ? null
                                                : { label: '삭제된 직원', value: spm.managerId };
                                        }))
                                            .filter(Boolean)
                                    }, controlProps: {
                                        name: "salesPerManagers.".concat(index, ".managerId"),
                                        control: formInModal.control,
                                        rules: {
                                            deps: ['salesPerManagers'],
                                            validate: function (value) {
                                                var otherManager = formValues.salesPerManagers[index === 0 ? 1 : 0];
                                                if ((otherManager === null || otherManager === void 0 ? void 0 : otherManager.managerId) && otherManager.managerId === value) {
                                                    return '담당자가 중복 될 수 없습니다.';
                                                }
                                                return true;
                                            }
                                        }
                                    } })),
                            React.createElement(Col, { span: 8 },
                                React.createElement(FluidSelect, { inputProps: {
                                        placeholder: '할당율',
                                        options: times(21)
                                            .map(function (item) { return ({ label: "".concat(item * 5, "%"), value: item * 5 }); })
                                            .concat({ label: '직접입력', value: '' }),
                                        disabled: disabled,
                                        onChange: function (value) {
                                            formInModal.setValue("salesPerManagers", handleChangeOnAutoMode(index, value));
                                        }
                                    }, controlProps: {
                                        name: "salesPerManagers.".concat(index, ".shareRate"),
                                        control: formInModal.control
                                    } })),
                            React.createElement(Col, { span: 8 },
                                React.createElement(HookFormInputs.NumberInput, { inputProps: {
                                        suffix: '원',
                                        disabled: disabled,
                                        onChange: function (value) {
                                            if (isNumber(value)) {
                                                formInModal.setValue("salesPerManagers", handleChangeOnManualMode(index, value));
                                            }
                                        }
                                    }, controlProps: {
                                        name: "salesPerManagers.".concat(index, ".sharePrice"),
                                        control: formInModal.control,
                                        rules: {
                                            deps: ['salesPerManagers'],
                                            required: { value: !!((_b = formValues[index]) === null || _b === void 0 ? void 0 : _b.managerId), message: '금액을 입력해주세요.' },
                                            min: { value: 0, message: '0원 이상 입력해주세요.' }
                                        }
                                    } }))));
                    }))) })));
};
export var PriceAdjustInput = function (props) {
    var _a, _b;
    var _c = useLocalStorage(LOCAL_STORAGE_PRODUCT_ADJUST_OPTION_AND_VALUE, {
        adjustOption: 'minusPercent',
        adjustValue: 0
    }), initialValue = _c[0], setInitialValue = _c[1];
    var handleChange = function (key, value) {
        var _a;
        var data;
        if (key === 'adjustValue') {
            data = __assign(__assign({}, initialValue), (_a = {}, _a[key] = value, _a));
        }
        else {
            data = { adjustOption: value, adjustValue: 0 };
        }
        setInitialValue(data);
    };
    var handleClick = function () {
        var usingHistory = props.usingHistory;
        props.methods.setValue('usingHistory', usingHistory === null || usingHistory === void 0 ? void 0 : usingHistory.map(function (item) {
            return __assign(__assign({}, item), { adjustOption: initialValue.adjustOption, adjustValue: initialValue.adjustValue });
        }));
        setTimeout(function () {
            props.methods.trigger(['usingHistory', 'leftPayment']);
        });
    };
    var isPercent = (_a = initialValue.adjustOption) === null || _a === void 0 ? void 0 : _a.includes('Percent');
    var isValid = !!initialValue.adjustValue && (isPercent ? initialValue.adjustValue <= 100 : true);
    return ((_b = props.usingHistory) === null || _b === void 0 ? void 0 : _b.length) > 1 ? (React.createElement(BuyingItemCard, null,
        React.createElement(Row, { justify: "end", wrap: false },
            React.createElement(StyledSelect, { value: initialValue.adjustOption, onChange: function (value) { return handleChange('adjustOption', value); }, options: [
                    { label: '% 할인', value: 'minusPercent' },
                    { label: '정액 할인', value: 'minusAmount' },
                    { label: '% 추가', value: 'plusPercent' },
                    { label: '정액 추가', value: 'plusAmount' }
                ] }),
            React.createElement(StyledNumberFormatInput, { suffix: isPercent ? '%' : '원', value: initialValue.adjustValue, onChange: function (value) { return handleChange('adjustValue', value); }, onFocus: function (ev) {
                    ev.target.select();
                } }),
            React.createElement(AntButton, { children: "\uC77C\uAD04\uC801\uC6A9", type: "primary", disabled: !isValid, onClick: handleClick })))) : (React.createElement("div", null));
};
var StyledSelect = styled(Select)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100px;\n  margin-right: 8px;\n"], ["\n  width: 100px;\n  margin-right: 8px;\n"])));
var StyledNumberFormatInput = styled(NumberFormatInput)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 120px !important;\n  margin-right: 20px;\n  & input {\n    line-height: 20px !important;\n  }\n"], ["\n  width: 120px !important;\n  margin-right: 20px;\n  & input {\n    line-height: 20px !important;\n  }\n"])));
var FluidSelect = styled(HookFormInputs.SelectInput)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var MarginBottomCol = styled(Col)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-bottom: 5px;\n"], ["\n  margin-bottom: 5px;\n"])));
var BuyingItemCard = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border-radius: 4px;\n  box-shadow: ", ";\n  border: solid 1px var(--gray-7);\n  background-color: var(--white);\n  padding: 8px 16px;\n  margin-bottom: 20px;\n"], ["\n  border-radius: 4px;\n  box-shadow: ", ";\n  border: solid 1px var(--gray-7);\n  background-color: var(--white);\n  padding: 8px 16px;\n  margin-bottom: 20px;\n"])), BOX_SHADOW);
var TextBox = styled(TextComponent)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n  background-color: ", ";\n  width: 100%;\n  display: flex;\n  justify: end;\n  height: 32px;\n  padding: 0px 10px;\n  align-items: center;\n  font-size: 12px;\n"], ["\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n  background-color: ", ";\n  width: 100%;\n  display: flex;\n  justify: end;\n  height: 32px;\n  padding: 0px 10px;\n  align-items: center;\n  font-size: 12px;\n"])), function (props) { return (props.disabled ? COLORS.gray7 : COLORS.white); });
var EmptyItem = function (props) {
    var _a = props.messages, messages = _a === void 0 ? ['+ 버튼을 클릭해', '상품을 선택하거나 정기권을 차감하세요.'] : _a, onClick = props.onClick;
    return (React.createElement(EmptyItemWrapper, { onClick: onClick },
        React.createElement("img", { src: IMAGES.empty_cart }),
        React.createElement("div", { style: { marginTop: '10px' } }, messages.map(function (message, index) { return (React.createElement(TextComponent, { type: "subtitle2", key: "".concat(index), children: message, textAlign: "center" })); }))));
};
var EmptyItemWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 170px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 4px;\n  border: solid 1px var(--gray-5);\n  background-color: var(--gray-7);\n  flex-direction: column;\n  ", "\n"], ["\n  height: 170px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 4px;\n  border: solid 1px var(--gray-5);\n  background-color: var(--gray-7);\n  flex-direction: column;\n  ", "\n"])), function (props) { return (props.onClick ? 'cursor: pointer' : ''); });
var ErrorWithoutInput = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  & .ant-input-number-input {\n    display: none;\n  }\n  & > .ant-input-number-affix-wrapper {\n    display: none;\n  }\n  & .ant-input-number {\n    border: 0px !important;\n  }\n"], ["\n  & .ant-input-number-input {\n    display: none;\n  }\n  & > .ant-input-number-affix-wrapper {\n    display: none;\n  }\n  & .ant-input-number {\n    border: 0px !important;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
