var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import dayjs from 'dayjs';
import React from 'react';
import styled, { css } from 'styled-components';
import Select from 'antd/lib/select';
import TimePicker from 'antd/lib/time-picker';
import { Icon, TextComponent, NumberFormatInput, DatePickerAutoaccept, CustomerSearchInput, DialogComponent, DaumMap, NumberInputWithController, seperateNumber, typographyByName } from 'renderer/components';
import { REGEX_NUMBER_PARSER, REGEX_NUMBER_PARSER_ALLOW_NEGATIVE } from 'renderer/constants';
import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';
import { COLORS } from 'renderer/styles';
import { Col, Row } from 'antd/lib/grid';
import { useController } from 'react-hook-form';
import TextArea from 'antd/lib/input/TextArea';
import DaumPostcode from 'react-daum-postcode';
import Switch from 'antd/lib/switch';
import DatePicker from 'antd/lib/date-picker';
import Radio from 'antd/lib/radio';
import debounce from 'lodash/debounce';
export var ControllerWrapper = function (props) {
    var _a, _b, _c;
    var error = (_a = props.fieldState) === null || _a === void 0 ? void 0 : _a.error;
    var label = props.label && typeof props.label === 'string' ? (props.isHorizontal ? (React.createElement("label", null, React.createElement(TextComponent, { type: "caption1", children: props.label, color: COLORS.black }))) : (React.createElement("label", null,
        React.createElement(TextComponent, { children: props.label, marginBottom: 5, type: "caption1" })))) : (props.label || React.createElement("div", null));
    var element = props.isHorizontal ? (React.createElement(Row, { align: "middle", gutter: 4, wrap: false },
        React.createElement(Col, { span: 10 }, label),
        React.createElement(Col, { flex: 1 }, props.input))) : (React.createElement(React.Fragment, null,
        label,
        props.input));
    // FIXME: 이제 메모는 없어도 되지않을까??
    return React.useMemo(function () { return (React.createElement(React.Fragment, null,
        element,
        (error === null || error === void 0 ? void 0 : error.message) && React.createElement(InputErrorMessage, { message: error.message }))); }, __spreadArray(__spreadArray([], (props.memoDeps || []), true), [
        label,
        props.fieldState.isDirty,
        props.fieldState.error,
        props.field.value,
        (_b = props.input.props) === null || _b === void 0 ? void 0 : _b.disabled,
        (_c = props.input.props) === null || _c === void 0 ? void 0 : _c.checked
    ], false));
};
export var InputErrorMessage = function (props) {
    return props.message ? (React.createElement(Row, { wrap: false, align: "middle", justify: props.justify || 'end' },
        React.createElement(Icon, { name: "alert-triangle", color: COLORS.calendarRedActive }),
        React.createElement(TextComponent, { type: "caption1", children: props === null || props === void 0 ? void 0 : props.message, marginBottom: 5, color: COLORS.calendarRedActive, marginLeft: 5, textAlign: "right" }))) : (React.createElement("div", null));
};
var DatePickerComponent = function (props) {
    var _a = useController(props.controlProps), field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
    return (React.createElement(ControllerWrapper, { field: field, fieldState: fieldState, isHorizontal: props.isHorizontal, label: props.label, input: React.createElement(DatePickerAutoaccept, __assign({ inputReadOnly: true, showNow: false }, props.inputProps, field, { value: field.value ? dayjs(field.value) : undefined })) }));
};
var RangePickerComponent = function (props) {
    var _a = useController(props.controlProps), field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
    return (React.createElement(ControllerWrapper, { field: field, fieldState: fieldState, isHorizontal: props.isHorizontal, label: props.label, input: React.createElement(DatePicker.RangePicker, __assign({ inputReadOnly: true, showNow: false }, props.inputProps, field)) }));
};
var NumberInputWithButtons = function (props) {
    var _a;
    var _b = useController(props.controlProps), field = _b.field, fieldState = _b.fieldState, formState = _b.formState;
    return (React.createElement(ControllerWrapper, { isHorizontal: props.isHorizontal, label: props.label, field: field, fieldState: fieldState, input: React.createElement(NumberInputWithController, __assign({}, field, { disabled: (_a = props.inputProps) === null || _a === void 0 ? void 0 : _a.disabled, onChange: function () {
                var _a;
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                field.onChange.apply(field, args);
                if (props.inputProps.onChange) {
                    (_a = props.inputProps).onChange.apply(_a, args);
                }
            } })) }));
};
var NumberInput = function (props) {
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps, className = props.className;
    var _a = useController(controlProps), field = _a.field, fieldState = _a.fieldState;
    return (React.createElement(ControllerWrapper, { label: label, isHorizontal: props.isHorizontal, field: field, fieldState: fieldState, input: React.createElement(NumberFormatInput, __assign({ className: className, parser: function (value) {
                var _a;
                return value.replace(((_a = props.inputProps) === null || _a === void 0 ? void 0 : _a.allowNegative) ? REGEX_NUMBER_PARSER_ALLOW_NEGATIVE : REGEX_NUMBER_PARSER, '');
            }, formatter: seperateNumber, onFocus: function (ev) {
                ev.target.select();
            } }, inputProps, field, { placeholder: 
            // FIXME:
            inputProps.placeholder && inputProps.suffix ? "".concat(inputProps.placeholder, " ") : inputProps.placeholder, onChange: function (ev) {
                var value;
                if (!isNaN(Number(ev))) {
                    value = ev;
                }
                else {
                    value = null;
                }
                field.onChange(value);
                if (inputProps.onChange) {
                    inputProps.onChange(value);
                }
            } })) }));
};
var SelectInput = function (props) {
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps, className = props.className;
    var _a = useController(controlProps), field = _a.field, fieldState = _a.fieldState;
    return (React.createElement(ControllerWrapper, { label: label, isHorizontal: props.isHorizontal, field: field, fieldState: fieldState, memoDeps: props.memoDeps, input: React.createElement(Select, __assign({ showSearch: false }, inputProps, field, { className: className, onChange: function (ev) {
                field.onChange(ev);
                if (inputProps.onChange) {
                    inputProps.onChange(ev, {});
                }
            } })) }));
};
var GraySelector = function (props) {
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps, className = props.className;
    var _a = useController(controlProps), field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
    return (React.createElement(ControllerWrapper, { label: label, isHorizontal: props.isHorizontal, field: field, fieldState: fieldState, input: React.createElement(StyledGraySelector, __assign({ showSearch: false }, inputProps, field, { className: className, onChange: function (ev) {
                field.onChange(ev);
                if (inputProps.onChange) {
                    inputProps.onChange(ev, {});
                }
            } })) }));
};
var CheckboxInput = function (props) {
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps;
    var _a = useController(controlProps), field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
    return (React.createElement(ControllerWrapper, { label: label, isHorizontal: props.isHorizontal, field: field, fieldState: fieldState, memoDeps: props.memoDeps, input: React.createElement(Checkbox, __assign({ className: props.className }, inputProps, field, { onChange: function (ev) {
                field.onChange(ev.target.checked);
                if (inputProps.onChange) {
                    inputProps.onChange(ev);
                }
            } })) }));
};
var SwitchInput = function (props) {
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps;
    var _a = useController(controlProps), field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
    return (React.createElement(ControllerWrapper, { label: label, isHorizontal: props.isHorizontal, field: field, fieldState: fieldState, input: React.createElement(Switch, __assign({ className: props.className }, inputProps, field, { onChange: function (checked) {
                field.onChange(checked);
                if (inputProps.onChange) {
                    inputProps.onChange({ target: { value: checked, checked: checked } });
                }
            } })) }));
};
var Text = function (props) {
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps, className = props.className;
    var _a = useController(controlProps), field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
    var normalizer = inputProps.normalizer, inputRest = __rest(inputProps, ["normalizer"]);
    return (React.createElement(ControllerWrapper, { label: label, isHorizontal: props.isHorizontal, field: field, fieldState: fieldState, input: React.createElement(Input, __assign({ className: className }, inputRest, field, { onChange: function (value) {
                if (normalizer) {
                    value.target.value = normalizer(value.target.value);
                }
                field.onChange(value);
                if (inputProps.onChange) {
                    inputProps.onChange(value);
                }
            } })) }));
};
var Textarea = function (props) {
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps, className = props.className;
    var _a = useController(controlProps), field = _a.field, fieldState = _a.fieldState;
    return (React.createElement(ControllerWrapper, { label: label, isHorizontal: props.isHorizontal, field: field, fieldState: fieldState, input: React.createElement(TextArea, __assign({ autoSize: { minRows: 5, maxRows: 10 }, className: className }, inputProps, field, { onChange: function (value) {
                field.onChange(value);
                if (inputProps.onChange) {
                    inputProps.onChange(value);
                }
            } })) }));
};
var Address = function (props) {
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps, className = props.className;
    var _a = useController(controlProps), field = _a.field, fieldState = _a.fieldState;
    // onComplete?: (result: AddressInputResult) => void;
    // withoutButton?: boolean;
    return (React.createElement(React.Fragment, null,
        React.createElement(ControllerWrapper, { label: label, isHorizontal: props.isHorizontal, field: field, fieldState: fieldState, input: React.createElement(AddressInputForHookForm, __assign({ className: className }, inputProps, field, { onComplete: function (value) {
                    field.onChange({ target: { value: value } });
                    if (inputProps.onChange) {
                        inputProps.onChange({ target: { value: value } });
                    }
                } })) })));
};
var RadioGroup = function (props) {
    var _a, _b;
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps;
    var _c = useController(controlProps), field = _c.field, fieldState = _c.fieldState, formState = _c.formState;
    return (React.createElement(ControllerWrapper, { field: field, fieldState: fieldState, isHorizontal: props.isHorizontal, label: label, input: React.createElement(Radio.Group, { value: field.value, disabled: (_a = props.inputProps) === null || _a === void 0 ? void 0 : _a.disabled, onChange: function (ev) {
                var value = ev.target.value;
                field.onChange({ target: { value: value } });
                if (inputProps.onChange) {
                    inputProps.onChange({ target: { value: value } });
                }
            } }, (_b = inputProps.options) === null || _b === void 0 ? void 0 : _b.map(function (option) {
            return (React.createElement(Radio, { key: "".concat(option.value), value: option.value }, option.label));
        })) }));
};
// TODO: Error표시
var DateSelector = function (props) {
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps;
    var _a = useController(controlProps), field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
    var handleChange = function (type, newValue) {
        var date = dayjs(type === 'time' ? field.value : newValue).format('YYYY-MM-DD');
        var time = dayjs(type === 'time' ? newValue : field.value).format('HH:mm');
        return field.onChange(dayjs("".concat(date, " ").concat(time)).toDate());
    };
    return (React.createElement("div", { className: props.className },
        React.createElement("div", { style: { width: '260px' } },
            React.createElement(StyledDatePicker, __assign({}, inputProps, { inputReadOnly: true, disabled: inputProps.disabled, allowClear: false, value: field.value ? dayjs(field.value) : undefined, format: "YYYY-MM-DD(ddd)", suffixIcon: React.createElement(Icon, { name: "down-chevron", size: 24 }), onChange: function (value) { return handleChange('date', value); }, needConfirm: false })),
            React.createElement(StyledTimePicker, __assign({}, inputProps, { inputReadOnly: true, disabled: inputProps.disabled, allowClear: false, showNow: false, value: field.value ? dayjs(field.value) : undefined, format: "HH:mm", suffixIcon: React.createElement(Icon, { name: "down-chevron", size: 20 }), popupClassName: "hideOk", onChange: function (value) { return handleChange('time', value); }, needConfirm: false })))));
};
var StyledGraySelector = styled(Select).attrs({
    suffixIcon: (React.createElement(Icon, { name: "down-chevron", color: COLORS.black, size: 18 }))
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  & .ant-select-selector {\n    min-width: 130px;\n    background-color: ", " !important;\n    border-radius: 2px !important;\n    border: 1px solid ", " !important;\n\n    & .ant-select-selection-item {\n      ", "} \n    }\n    }\n"], ["\n  & .ant-select-selector {\n    min-width: 130px;\n    background-color: ", " !important;\n    border-radius: 2px !important;\n    border: 1px solid ", " !important;\n\n    & .ant-select-selection-item {\n      ", "} \n    }\n    }\n"])), COLORS.gray7, COLORS.gray7, css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), typographyByName('body2')));
var CustomerSelector = function (props) {
    var inputProps = props.inputProps, label = props.label, controlProps = props.controlProps, className = props.className;
    var _a = useController(controlProps), field = _a.field, fieldState = _a.fieldState;
    return (React.createElement(ControllerWrapper, { label: label, field: field, fieldState: fieldState, isHorizontal: props.isHorizontal, input: React.createElement(CustomerSearchInput, __assign({ allowClear: true, className: className, autoFocus: false }, inputProps, { onChange: function (value, options) {
                field.onChange(value);
                if (inputProps.onChange) {
                    inputProps.onChange(value, options);
                }
            }, onClear: function () {
                field.onChange('');
            }, value: field.value, hideControlButtons: true })) }));
};
var GrayNumberInput = styled(NumberInput)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 1px solid var(--gary-6) !important;\n  border-radius: 2px !important;\n  background-color: var(--gray-6) !important;\n"], ["\n  border: 1px solid var(--gary-6) !important;\n  border-radius: 2px !important;\n  background-color: var(--gray-6) !important;\n"])));
var GrayText = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 1px solid var(--gary-6) !important;\n  border-radius: 2px !important;\n  background-color: var(--gray-6) !important;\n"], ["\n  border: 1px solid var(--gary-6) !important;\n  border-radius: 2px !important;\n  background-color: var(--gray-6) !important;\n"])));
var GrayTextarea = styled(Textarea)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  &.ant-input:hover,\n  &.ant-input:focus,\n  &.ant-input-focused {\n    border-right-width: 0px !important;\n  }\n  border-radius: 2px !important;\n  background-color: var(--gray-6) !important;\n  border: 0px solid !important;\n"], ["\n  &.ant-input:hover,\n  &.ant-input:focus,\n  &.ant-input-focused {\n    border-right-width: 0px !important;\n  }\n  border-radius: 2px !important;\n  background-color: var(--gray-6) !important;\n  border: 0px solid !important;\n"])));
var GrayCustomerSelector = styled(CustomerSelector)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 300px !important;\n  & > .ant-select-selector {\n    border-radius: 2px !important;\n    border: 1px solid var(--gary-6) !important;\n    background-color: var(--gray-6) !important;\n  }\n  &.ant-btn:active {\n    background-color: ", " !important;\n  }\n  & .ant-select-clear {\n    background-color: var(--gray-6) !important;\n  }\n"], ["\n  width: 300px !important;\n  & > .ant-select-selector {\n    border-radius: 2px !important;\n    border: 1px solid var(--gary-6) !important;\n    background-color: var(--gray-6) !important;\n  }\n  &.ant-btn:active {\n    background-color: ", " !important;\n  }\n  & .ant-select-clear {\n    background-color: var(--gray-6) !important;\n  }\n"])), COLORS.gray6);
var DATE_PICKER_COMMON_STYLE = "\ncursor: pointer;\ndisplay: inline-block;\npadding: 0px;\n&.ant-picker {\n  border: 0px solid white !important;\n}\n&.ant-picker-focused {\n  box-shadow: 0 0 0 0px rgb(150 117 248 / 20%) !important;\n}\n& input {\n  font-size: 16px !important;\n  font-weight: 600 !important;\n  cursor: pointer;\n}\n& .ant-picker-suffix {\n  color: black;\n}\n";
var StyledDatePicker = styled(DatePicker)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 180px;\n  ", "\n"], ["\n  width: 180px;\n  ", "\n"])), DATE_PICKER_COMMON_STYLE);
var StyledTimePicker = styled(TimePicker)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 80px;\n  ", "\n"], ["\n  width: 80px;\n  ", "\n"])), DATE_PICKER_COMMON_STYLE);
var AddressInputForHookForm = function (props) {
    var _a, _b;
    var onComplete = props.onComplete, rest = __rest(props, ["onComplete"]);
    var _c = React.useState(false), visiblePostcode = _c[0], setVisiblePostcode = _c[1];
    var value = rest.value;
    var addressString = typeof ((_a = value === null || value === void 0 ? void 0 : value.address) === null || _a === void 0 ? void 0 : _a.address) === 'string'
        ? (_b = value === null || value === void 0 ? void 0 : value.address) === null || _b === void 0 ? void 0 : _b.address
        : typeof (value === null || value === void 0 ? void 0 : value.address) === 'string'
            ? value === null || value === void 0 ? void 0 : value.address
            : typeof value === 'string'
                ? value
                : '';
    var inputProps = __assign({ readOnly: true }, rest);
    var handleClick = function () {
        setVisiblePostcode(true);
    };
    var _d = React.useState(), inputValue = _d[0], setInputValue = _d[1];
    // React.useEffect(() => {
    //   if (inputValue !== props.value) {
    //     setInputValue(props.value);
    //   }
    // }, [props.value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Input, __assign({}, inputProps, { value: addressString, onClick: handleClick })),
        visiblePostcode && (React.createElement(DialogComponent, { zIndex: 9999, onClose: function () { return setVisiblePostcode(false); }, visible: true, title: "\uC8FC\uC18C\uCC3E\uAE30", content: React.createElement(DaumPostcode, { onComplete: function (addressResult) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        DaumMap.Geocoder().addressSearch(addressResult.address, function (result, status) {
                            var target = result && result[0];
                            if (status === 'OK' && target) {
                                if (onComplete) {
                                    onComplete({ address: addressResult, latLng: { latitude: target.y, longitude: target.x } });
                                }
                                else {
                                    if (props.onChange) {
                                        props.onChange({
                                            address: addressResult,
                                            latLng: { latitude: target.y, longitude: target.x }
                                        });
                                    }
                                }
                            }
                            setVisiblePostcode(false);
                        });
                        return [2 /*return*/];
                    });
                }); } }) }))));
};
export var TempDescriptionArea = function (props) {
    var defaultValue = props.methods.watch(props.name);
    var _a = React.useState(props.methods.getValues(props.name)), description = _a[0], setDescription = _a[1];
    var handleChange = debounce(function (ev) {
        props.methods.setValue(props.name, ev.target.value, {
            shouldDirty: true,
            shouldValidate: true
        });
    }, 200);
    var onBlur = React.useCallback(function (ev) {
        props.methods.setValue(props.name, ev.target.value, {
            shouldDirty: true,
            shouldValidate: true
        });
    }, [props.methods]);
    return (React.createElement(TempGrayTextarea, { onChange: handleChange, onBlur: onBlur, defaultValue: defaultValue, placeholder: "\uBA54\uBAA8\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.", rows: 8 }));
};
var TempGrayTextarea = styled.textarea(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  &.ant-input:hover,\n  &.ant-input:focus,\n  &.ant-input-focused {\n    border-right-width: 0px !important;\n  }\n  border-radius: 2px !important;\n  background-color: var(--gray-6) !important;\n  border: 0px solid !important;\n  resize: none;\n  width: 100%;\n"], ["\n  &.ant-input:hover,\n  &.ant-input:focus,\n  &.ant-input-focused {\n    border-right-width: 0px !important;\n  }\n  border-radius: 2px !important;\n  background-color: var(--gray-6) !important;\n  border: 0px solid !important;\n  resize: none;\n  width: 100%;\n"])));
export var HookFormInputs = {
    NumberInputWithButtons: NumberInputWithButtons,
    NumberInput: NumberInput,
    DateSelector: DateSelector,
    SelectInput: SelectInput,
    Text: Text,
    Textarea: Textarea,
    CheckboxInput: CheckboxInput,
    DatePicker: DatePickerComponent,
    CustomerSelector: CustomerSelector,
    GrayCustomerSelector: GrayCustomerSelector,
    GraySelector: GraySelector,
    GrayTextarea: GrayTextarea,
    GrayText: GrayText,
    GrayNumberInput: GrayNumberInput,
    Address: Address,
    SwitchInput: SwitchInput,
    RadioGroup: RadioGroup,
    RangePicker: RangePickerComponent
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
