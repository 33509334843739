var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useRecoilState } from 'recoil';
import { Atoms } from 'renderer/stores';
import { useNavigate, useLocation } from 'react-router';
import { getSearchParams, useWindowSize } from 'renderer/utils';
import { BasicInfo, EmployeeListInSettings, EntireBigTitleModal, Icon, TextComponent, typographyByName, ScrollBar, CalendarSetting, CustomerGroupSetting, TicketSetting, SenderInfoSetting, AutoMessageSetting, CustomerTagSetting, ProductSetting, ManagerPermissionContent, EntireLoader, PaymentMethodsSetting, ScrollBarWithMeasure, AdditionalCover, CustomerNumberSetting } from 'renderer/components';
import Row from 'antd/lib/row';
import styled from 'styled-components';
import { COLORS, MediaQuery } from 'renderer/styles';
import { ManagerQuery, StoreQuery } from 'renderer/queries';
import { MANAGER_PERMISSION_ACTIONS, MANAGER_PERMISSION_KEYS, MANAGER_PERMISSION_PRIORITY } from 'renderer/models';
import { HEADER_HEIGHT } from 'renderer/constants';
export var SettingModal = function (props) {
    var _a;
    var _b = useRecoilState(Atoms.globalSettingModalState), value = _b[0], openSettings = _b[1];
    var location = useLocation();
    var navigate = useNavigate();
    var _c = useWindowSize(), isPC = _c.isPC, height = _c.height, width = _c.width;
    var bodyHeight = height - HEADER_HEIGHT;
    React.useEffect(function () {
        if (value === null || value === void 0 ? void 0 : value.type) {
            if (!isPC && !modal) {
                navigate("".concat(location.pathname, "?modal=settings&type=list"));
            }
            navigate("".concat(location.pathname, "?modal=settings&type=").concat(value.type));
        }
    }, [value]);
    var params = getSearchParams(location.search);
    var modal = params.get('modal');
    var type = params.get('type');
    var groups = [
        {
            title: '상점관리',
            menus: [
                { type: 'basic', label: '상점 기본 설정' },
                { type: 'employee', label: '직원 추가 및 관리' }
            ]
        },
        {
            title: '기초 설정',
            menus: [
                { type: 'calendar', label: '예약설정', alert: true },
                { type: 'product', label: '상품 메뉴 등록' },
                { type: 'ticket', label: '선불액∙정기권 등록' },
                { type: 'paymentmethods', label: '기타 결제수단 설정' },
                { type: 'customerGroup', label: '고객 등급 등록' },
                { type: 'customerTag', label: '고객 태그 등록' },
                { type: 'customerNumber', label: '고객번호 설정' },
                { type: 'senderInfo', label: '문자 발신정보 등록' },
                { type: 'autoMessage', label: '문자 자동발송 설정' }
            ]
        }
    ];
    var Children = isPC ? (React.createElement(Wrapper, null,
        React.createElement(Menus, { groups: groups, type: type, onClick: function (type) {
                navigate("".concat(location.pathname, "?modal=settings&type=").concat(type));
            } }),
        React.createElement(Content, { type: type, height: bodyHeight, isPC: isPC, tab: value === null || value === void 0 ? void 0 : value.tab }))) : type === 'list' ? (React.createElement(Menus, { groups: groups, type: type, onClick: function (type) {
            navigate("".concat(location.pathname, "?modal=settings&type=").concat(type));
        } })) : (React.createElement(Content, { type: type, isPC: isPC, height: bodyHeight, tab: value === null || value === void 0 ? void 0 : value.tab }));
    var title = isPC
        ? '환경설정'
        : ((_a = __spreadArray(__spreadArray([], groups[0].menus, true), groups[1].menus, true).find(function (item) { return (item === null || item === void 0 ? void 0 : item.type) === type; })) === null || _a === void 0 ? void 0 : _a.label) || '환경설정';
    var Title = isPC ? (title) : (React.createElement(Row, { align: "middle" },
        React.createElement(Icon, { name: "left-chevron", onClick: function () { return navigate(-1); }, size: 24 }),
        React.createElement(TextComponent, { children: title, type: "headline3", marginLeft: 10 })));
    return (React.createElement(EntireBigTitleModal, { footer: null, open: !!modal, children: Children, title: Title, onCancel: function () {
            openSettings(null);
            navigate(location.pathname, { replace: true });
        } }));
};
var Menus = function (props) {
    return (React.createElement(MenuWrapper, null,
        React.createElement(ScrollBarWithMeasure, null, props.groups.map(function (group, index) {
            return (React.createElement(StyledUl, { key: index },
                React.createElement(TextComponent, { children: group.title, color: COLORS.gray2, type: "body2" }),
                group.menus.map(function (menu) {
                    return (React.createElement(StyledLi, { active: menu.type === props.type, key: menu.type, children: menu.label, onClick: function () { return props.onClick(menu.type); } }));
                })));
        }))));
};
var StyledUl = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n  margin-bottom: 20px;\n"], ["\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n  margin-bottom: 20px;\n"])));
var StyledLi = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 11px 24px 11px 6px;\n  border-radius: 2px;\n  ", "\n  ", ";\n  margin-top: 5px;\n"], ["\n  padding: 11px 24px 11px 6px;\n  border-radius: 2px;\n  ", "\n  ", ";\n  margin-top: 5px;\n"])), function (props) {
    return props.active
        ? "\n  background-color: var(--primary-5);\n  color: var(--primary-1);\n  "
        : "\n  color: var(--gray-1);\n  cursor: pointer;\n  ";
}, typographyByName('subtitle2'));
var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n"])));
var MenuWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 200px;\n  height: 100%;\n  border-right: 1px solid ", ";\n  padding: 20px;\n  ", "\n"], ["\n  width: 200px;\n  height: 100%;\n  border-right: 1px solid ", ";\n  padding: 20px;\n  ", "\n"])), COLORS.gray5, MediaQuery.tabletWithMobile(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  border-right: 0px solid ", ";\n  "], ["\n  width: 100%;\n  border-right: 0px solid ", ";\n  "])), COLORS.gray5));
var Content = function (props) {
    var storeQuery = StoreQuery.useStore();
    var store = storeQuery.data;
    var loginManager = ManagerQuery.getLoginManagerInCache();
    var commons = props.type === 'basic' || props.type === 'calendar'
        ? {
            key: MANAGER_PERMISSION_KEYS.STORE_MANAGEMENT,
            priority: MANAGER_PERMISSION_PRIORITY.ALL
        }
        : {
            key: MANAGER_PERMISSION_KEYS.EMPLOYEE_MANAGEMENT,
            priority: MANAGER_PERMISSION_PRIORITY.ALL
        };
    var readPerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commons), { action: MANAGER_PERMISSION_ACTIONS.READ }));
    var createPerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commons), { action: MANAGER_PERMISSION_ACTIONS.CREATE }));
    var updatePerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commons), { action: MANAGER_PERMISSION_ACTIONS.UPDATE }));
    var deletePerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commons), { action: MANAGER_PERMISSION_ACTIONS.DELETE }));
    var contentProps = function (type) {
        switch (type) {
            case 'employee':
                return (React.createElement(ManagerPermissionContent, { isPrevent: !readPerm, descriptions: ['직원관리 조회 권한이 없습니다.'], children: React.createElement(EmployeeListInSettings, { store: store, permission: {
                            create: createPerm,
                            update: updatePerm,
                            delete: deletePerm,
                            read: readPerm
                        } }) }));
            case 'basic':
                return (React.createElement(ManagerPermissionContent, { isPrevent: !readPerm, descriptions: ['상점관리 권한이 없습니다.'], children: React.createElement(BasicInfo, { store: store, permission: { update: updatePerm } }) }));
            case 'calendar':
                return (React.createElement(ManagerPermissionContent, { isPrevent: !readPerm, children: React.createElement(CalendarSetting, { tab: props === null || props === void 0 ? void 0 : props.tab, store: store, permission: { update: updatePerm }, height: props.height }) }));
            case 'customerTag':
                return (React.createElement(ManagerPermissionContent, { isPrevent: !readPerm, children: React.createElement(CustomerTagSetting, { storeId: store.id, permission: { update: updatePerm } }) }));
            case 'customerGroup':
                return (React.createElement(ManagerPermissionContent, { isPrevent: !readPerm, children: React.createElement(CustomerGroupSetting, { storeId: store.id, permission: { update: updatePerm } }) }));
            case 'customerNumber':
                return (React.createElement(ManagerPermissionContent, { isPrevent: !readPerm, children: React.createElement(CustomerNumberSetting, { storeId: store.id, permission: { update: updatePerm } }) }));
            case 'ticket':
                return React.createElement(TicketSetting, { store: store });
            case 'product':
                return React.createElement(ProductSetting, { store: store });
            case 'autoMessage':
                return React.createElement(AutoMessageSetting, null);
            case 'senderInfo':
                return React.createElement(SenderInfoSetting, null);
            case 'paymentmethods':
                return React.createElement(PaymentMethodsSetting, { storeId: store.id, permission: { update: updatePerm } });
        }
    };
    return store ? (React.createElement(React.Fragment, null,
        React.createElement(ContentWrapper, { "$padding": ['calendar', 'product'].includes(props.type) ? 0 : 20 },
            ['autoMessage'].includes(props.type) ? React.createElement(AdditionalCover, { store: store, type: props.type }) : React.createElement("div", null),
            props.type === 'calendar' ? (contentProps(props.type)) : (React.createElement(ScrollBar, { style: {
                    maxHeight: props.height ? props.height - 40 : '100%',
                    width: '100%'
                }, 
                // FIXME: https://github.com/KingSora/OverlayScrollbars/issues/195
                options: { scrollbars: { autoHide: 'never' }, overflowBehavior: { x: 'hidden' } } }, contentProps(props.type)))))) : (React.createElement(EntireLoader, { open: true }));
};
var ContentWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n  padding: ", "px;\n  overflow: hidden;\n  position: relative;\n  height: 100%;\n"], ["\n  flex: 1;\n  padding: ", "px;\n  overflow: hidden;\n  position: relative;\n  height: 100%;\n"])), function (props) { return props.$padding; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
