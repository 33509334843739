var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export * from './ScrollContainer';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react/dist/overlayscrollbars-react';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import React from 'react';
import { useMeasure } from 'react-use';
import { useWindowSize } from 'renderer/utils';
import styled from 'styled-components';
export var ScrollBar = function (props) {
    var options = props.options, rest = __rest(props, ["options"]);
    // options={{ scrollbars: { autoHide: 'never' }, overflowBehavior: { x: 'hidden' } }}
    // const is
    // FIXME: https://github.com/KingSora/OverlayScrollbars/issues/195
    var isIOS = useWindowSize().isIOS;
    var scrolOptions = __assign(__assign({}, options), { scrollbars: (options === null || options === void 0 ? void 0 : options.scrollbars)
            ? __assign({ autoHide: isIOS ? 'never' : 'move', autoHideDelay: 100 }, options.scrollbars) : {
            autoHide: isIOS ? 'never' : 'move',
            autoHideDelay: 100
        } });
    return React.createElement(OverlayScrollbarsComponent, __assign({ options: scrolOptions }, rest));
};
export var ScrollBarWithMeasure = function (props) {
    var _a = useMeasure(), wrapperRef = _a[0], _b = _a[1], x = _b.x, y = _b.y, width = _b.width, height = _b.height, top = _b.top, right = _b.right, bottom = _b.bottom, left = _b.left;
    return (React.createElement(Wrapper, { ref: wrapperRef },
        React.createElement(ScrollBar, __assign({ style: __assign({ height: "".concat(height - (props.additionalHeight || 0), "px") }, (props.style || {})) }, props))));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n"], ["\n  height: 100%;\n  width: 100%;\n"])));
var templateObject_1;
