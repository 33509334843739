var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useEntireLoader, EntireBigTitleModal, checkPermissionForOnClick } from 'renderer/components';
import { MANAGER_PERMISSION_KEYS, MANAGER_PERMISSION_PRIORITY, ScheduleModel, SALES_TYPES, MANAGER_PERMISSION_ACTIONS_DURATION } from 'renderer/models';
import { ManagerQuery, PaymentHistoryQuery, QUERY_CACHE, StoreQuery } from 'renderer/queries';
import { mapValues, useWindowSize, omitEmptyValues, isNilOrEmptyArray, isEmpty } from 'renderer/utils';
import notification from 'antd/lib/notification';
import { SalesFormContainer } from './SalesForm/SalesForm';
import { SalesRemoteController } from './SalesForm/SalesDetail';
import { useRecoilValue } from 'recoil';
import { Atoms, useGetScheduleModals, useSaleModal, useScheduleModal } from 'renderer/stores';
import Bugsnag from '@bugsnag/js';
import styled from 'styled-components';
import { MediaQuery } from 'renderer/styles';
import * as dayjs from 'dayjs';
export var SALES_CONTROLLER_KEYS;
(function (SALES_CONTROLLER_KEYS) {
    SALES_CONTROLLER_KEYS["SELECT_MENU"] = "select_menu";
    SALES_CONTROLLER_KEYS["DETAILS"] = "details";
    SALES_CONTROLLER_KEYS["PICUTRES"] = "pictures";
    SALES_CONTROLLER_KEYS["PREPAID"] = "prepaid";
    SALES_CONTROLLER_KEYS["TICKETS"] = "tickets";
    SALES_CONTROLLER_KEYS["SIGNATURE"] = "signature";
    SALES_CONTROLLER_KEYS["UPDATE_SALES"] = "update_sales";
    SALES_CONTROLLER_KEYS["SEND_QR"] = "send_qr";
    SALES_CONTROLLER_KEYS["UNPAID"] = "unpaid";
    SALES_CONTROLLER_KEYS["REFUND"] = "refund";
})(SALES_CONTROLLER_KEYS || (SALES_CONTROLLER_KEYS = {}));
export var RegistSalesModal = function (props) {
    var _a, _b, _c, _d, _e;
    var isPC = useWindowSize().isPC;
    var saleModals = useSaleModal();
    var store = StoreQuery.getMyStoreInCache();
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var ref = React.useRef();
    var loginManagerQuery = ManagerQuery.useMe(storeId, { enabled: !!storeId });
    var permissions = (_a = loginManagerQuery === null || loginManagerQuery === void 0 ? void 0 : loginManagerQuery.data) === null || _a === void 0 ? void 0 : _a.getPermission(MANAGER_PERMISSION_KEYS.SALES_LIST);
    var readDuration = (_b = loginManagerQuery === null || loginManagerQuery === void 0 ? void 0 : loginManagerQuery.data) === null || _b === void 0 ? void 0 : _b.getPermissionDurationDate({
        key: MANAGER_PERMISSION_KEYS.SALES_LIST,
        action: MANAGER_PERMISSION_ACTIONS_DURATION.READ_DURATION
    });
    var updateDuration = (_c = loginManagerQuery === null || loginManagerQuery === void 0 ? void 0 : loginManagerQuery.data) === null || _c === void 0 ? void 0 : _c.getPermissionDurationDate({
        key: MANAGER_PERMISSION_KEYS.SALES_LIST,
        action: MANAGER_PERMISSION_ACTIONS_DURATION.UPDATE_DURATION
    });
    var deleteDuration = (_d = loginManagerQuery === null || loginManagerQuery === void 0 ? void 0 : loginManagerQuery.data) === null || _d === void 0 ? void 0 : _d.getPermissionDurationDate({
        key: MANAGER_PERMISSION_KEYS.SALES_LIST,
        action: MANAGER_PERMISSION_ACTIONS_DURATION.DELETE_DURATION
    });
    React.useEffect(function () {
        var _a;
        if (ref.current) {
            ref.current.resetFields();
        }
        var data = saleModals.getLastItem();
        // 등록창이 열릴때
        if (!!data) {
            var isUpdated = !!((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.id);
            if (!isUpdated) {
                if (!checkPermissionForOnClick(permissions.create !== MANAGER_PERMISSION_PRIORITY.NOTHING, '매출등록 권한이없습니다.')) {
                    saleModals.removeLastItem();
                }
            }
        }
    }, [saleModals.items]);
    var createPaymentHistory = PaymentHistoryQuery.useCreateSales();
    var updatePaymentHistory = PaymentHistoryQuery.useUpdateSales();
    var _f = React.useState(), subDrawer = _f[0], setSubDrawer = _f[1];
    var replaceFromLastItem = useScheduleModal().replaceFromLastItem;
    var scheduleModals = useGetScheduleModals();
    var loader = useEntireLoader();
    var mode = useRecoilValue(Atoms.salesRegisterOffOptionState);
    var handleSubmit = function (values, drawerData) { return __awaiter(void 0, void 0, void 0, function () {
        var error, isUpdated, result, schedule, err_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    loader.show();
                    isUpdated = !!((_a = drawerData.data) === null || _a === void 0 ? void 0 : _a.id);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, commonHandleSalesSubmit(values, {
                            drawer: drawerData,
                            storeId: storeId,
                            createPaymentHistory: createPaymentHistory.mutateAsync,
                            updatePaymentHistory: updatePaymentHistory.mutateAsync
                        })];
                case 2:
                    result = _b.sent();
                    // 예약 모달이 켜져있는 경우 있는 경우
                    if (result === null || result === void 0 ? void 0 : result.id) {
                        schedule = scheduleModals === null || scheduleModals === void 0 ? void 0 : scheduleModals.pop();
                        if (schedule) {
                            replaceFromLastItem({
                                id: schedule.id,
                                type: 'details',
                                schedule: new ScheduleModel(__assign(__assign({}, schedule), { salesHistoryId: result.id }))
                            });
                        }
                    }
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _b.sent();
                    error = err_1;
                    return [3 /*break*/, 5];
                case 4:
                    loader.hide();
                    showNotice(isUpdated, !!values.ticket, error);
                    // props.onClose();
                    if (mode !== 'details') {
                        saleModals.removeLastItem();
                    }
                    else {
                        saleModals.replace(__assign(__assign({}, drawerData), { controllerKey: SALES_CONTROLLER_KEYS.SELECT_MENU, isNewData: true, data: result }));
                    }
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleClickPurchaseTicket = function (customerId) {
        setSubDrawer({ type: 'ticket', data: { customerId: customerId } });
    };
    var getPermission = function (sales) {
        var _a, _b, _c;
        var paidAt = (_a = sales === null || sales === void 0 ? void 0 : sales.data) === null || _a === void 0 ? void 0 : _a.paidAt;
        var isReadOverDuration = readDuration && dayjs(paidAt).isBefore(readDuration);
        var isUpdateOverDuration = updateDuration && dayjs(paidAt).isBefore(updateDuration);
        var isDeleteOverDuration = deleteDuration && dayjs(paidAt).isBefore(deleteDuration);
        return {
            create: (permissions === null || permissions === void 0 ? void 0 : permissions.create) !== MANAGER_PERMISSION_PRIORITY.NOTHING,
            update: (permissions === null || permissions === void 0 ? void 0 : permissions.update) !== MANAGER_PERMISSION_PRIORITY.NOTHING && !isUpdateOverDuration,
            delete: (permissions === null || permissions === void 0 ? void 0 : permissions.delete) !== MANAGER_PERMISSION_PRIORITY.NOTHING && !isDeleteOverDuration,
            read: (sales === null || sales === void 0 ? void 0 : sales.isNewData) ||
                ((permissions === null || permissions === void 0 ? void 0 : permissions.read) === MANAGER_PERMISSION_PRIORITY.ALL && !isReadOverDuration) ||
                (!!((_c = (_b = sales === null || sales === void 0 ? void 0 : sales.data) === null || _b === void 0 ? void 0 : _b.salesPerManagers) === null || _c === void 0 ? void 0 : _c.find(function (item) { return item.managerId === loginManagerQuery.data.id; })) &&
                    (permissions === null || permissions === void 0 ? void 0 : permissions.read) === MANAGER_PERMISSION_PRIORITY.MINE &&
                    !isReadOverDuration)
        };
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SubRegisterDrawer, { loginManager: loginManagerQuery.data, data: subDrawer, storeId: storeId, createPaymentHistory: createPaymentHistory.mutateAsync, onClose: function () {
                setSubDrawer(undefined);
            }, onComplete: function () {
                var _a;
                QUERY_CACHE.invalidateQueries(['customers', { id: (_a = subDrawer === null || subDrawer === void 0 ? void 0 : subDrawer.data) === null || _a === void 0 ? void 0 : _a.customerId, storeId: storeId }]);
            } }), (_e = saleModals === null || saleModals === void 0 ? void 0 : saleModals.items) === null || _e === void 0 ? void 0 :
        _e.map(function (item) {
            var _a;
            var controllerKey = (item === null || item === void 0 ? void 0 : item.controllerKey) || SALES_CONTROLLER_KEYS.SELECT_MENU;
            var handleChangeControllerKey = function (key) {
                saleModals.replace(__assign(__assign({}, item), { controllerKey: key }));
            };
            var handleClose = function () {
                var shouldClose = isPC || controllerKey === SALES_CONTROLLER_KEYS.SELECT_MENU;
                if (shouldClose) {
                    saleModals.removeLastItem();
                }
                else {
                    saleModals.replace(__assign(__assign({}, item), { controllerKey: SALES_CONTROLLER_KEYS.SELECT_MENU }));
                }
            };
            var handleChangeNewData = function (sales) {
                saleModals.replace(__assign(__assign({}, item), { controllerKey: SALES_CONTROLLER_KEYS.SELECT_MENU, type: sales.isUsingHistory ? 'product' : 'ticket', isNewData: true, data: sales }));
            };
            var salesPermissions = getPermission(item);
            var isUpdated = !!((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.id);
            return (React.createElement(EntireBigTitleModal, { open: !!item, key: item.id, title: getTitle(item, isUpdated, isPC, controllerKey), onCancel: handleClose, footer: null, children: React.createElement(React.Fragment, null, isUpdated ? (React.createElement(SalesRemoteController, { isPC: isPC, salesPermissions: salesPermissions, onChangeControlerKey: handleChangeControllerKey, onClickPurchaseTicket: handleClickPurchaseTicket, controllerKey: controllerKey, data: item, onClose: handleClose, onSubmit: function (values) { return handleSubmit(values, item); }, onComplete: handleChangeNewData })) : (React.createElement(SalesFormContainer, { data: item, onClickPurchaseTicket: handleClickPurchaseTicket, onCancel: handleClose, 
                    // onComplete={handleChangeNewData}
                    isDirty: false, onSubmit: function (values) { return handleSubmit(values, item); } }))) }));
        })));
};
var showNotice = function (isUpdated, isTicket, error) {
    var label = isTicket ? '선불액/정기권' : '상품';
    var what = isUpdated ? '수정' : '등록';
    if (error) {
        return notification.error({ message: (error === null || error === void 0 ? void 0 : error.message) || "".concat(label, " \uB9E4\uCD9C ").concat(what, " \uC911 \uC5D0\uB7EC\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4.") });
    }
    else {
        return notification.success({ message: "".concat(label, " \uB9E4\uCD9C ").concat(what, "\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.") });
    }
};
var getTitle = function (drawer, isUpdated, isPC, controllerKey) {
    if (isUpdated && !isPC) {
        switch (controllerKey) {
            case SALES_CONTROLLER_KEYS.SELECT_MENU:
                return '등록된 매출';
            case SALES_CONTROLLER_KEYS.DETAILS:
                return '결제내역서';
            case SALES_CONTROLLER_KEYS.PICUTRES:
                return '시술사진 등록';
            case SALES_CONTROLLER_KEYS.PREPAID:
                return '선불액차감 문자발송';
            case SALES_CONTROLLER_KEYS.TICKETS:
                return '횟수권차감 문자발송';
            case SALES_CONTROLLER_KEYS.SIGNATURE:
                return '서명';
            case SALES_CONTROLLER_KEYS.UNPAID:
                return '미수금 정산';
            case SALES_CONTROLLER_KEYS.SEND_QR:
                return 'QR 코드 전송';
            case SALES_CONTROLLER_KEYS.REFUND:
                return (drawer === null || drawer === void 0 ? void 0 : drawer.type) === 'ticket' ? '선불액∙정기권 환불' : '상품 환불';
        }
        return '등록된 매출';
    }
    else {
        var label = isUpdated ? '수정' : '등록';
        switch (drawer === null || drawer === void 0 ? void 0 : drawer.type) {
            case 'ticket':
                return controllerKey === SALES_CONTROLLER_KEYS.REFUND
                    ? '선불액∙정기권 환불'
                    : isUpdated
                        ? '등록된 정기권 매출'
                        : "\uC120\uBD88\uC561\u2219\uC815\uAE30\uAD8C \uB9E4\uCD9C \uB4F1\uB85D";
            case 'product':
                return controllerKey === SALES_CONTROLLER_KEYS.REFUND
                    ? '상품 환불'
                    : isUpdated
                        ? '등록된 상품 매출'
                        : "\uC0C1\uD488 \uB9E4\uCD9C \uB4F1\uB85D";
            case 'reservationFee':
                return isUpdated ? '예약금 매출 수정' : "\uC608\uC57D\uAE08 \uB9E4\uCD9C \uB4F1\uB85D";
            case 'refund':
                return isUpdated ? '등록된 환불 정보' : "\uB4F1\uB85D\uB41C \uD658\uBD88 \uC815\uBCF4";
        }
    }
};
export var SubRegisterDrawer = function (props) {
    var drawer = props.data, storeId = props.storeId;
    var ref = React.useRef();
    React.useEffect(function () {
        if (ref.current) {
            ref.current.resetFields();
        }
    }, [drawer]);
    var loader = useEntireLoader();
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var error, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    loader.show();
                    return [4 /*yield*/, commonHandleSalesSubmit(values, {
                            drawer: drawer,
                            storeId: storeId,
                            createPaymentHistory: props.createPaymentHistory
                        })];
                case 1:
                    _a.sent();
                    props.onComplete();
                    props.onClose();
                    return [3 /*break*/, 4];
                case 2:
                    err_2 = _a.sent();
                    error = err_2;
                    return [3 /*break*/, 4];
                case 3:
                    loader.hide();
                    showNotice(false, true, error);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function () {
        props.onClose();
    };
    return (React.createElement(SubRegisterDrwaer, { open: !!drawer, title: getTitle(drawer, false), onCancel: props.onClose, footer: null, children: props.loginManager && (React.createElement(SalesFormContainer, { data: drawer, 
            // onClickPurchaseTicket={handleClickPurchaseTicket}
            onCancel: handleClose, onComplete: function () { return handleClose(); }, onSubmit: handleSubmit })) }));
};
var SubRegisterDrwaer = styled(EntireBigTitleModal)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  & .ant-modal-content {\n    left: 15vw;\n    width: 85vw;\n    ", ";\n  }\n"], ["\n  & .ant-modal-content {\n    left: 15vw;\n    width: 85vw;\n    ", ";\n  }\n"])), MediaQuery.tabletWithMobile(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100vw;\n    left: 0;\n  "], ["\n    width: 100vw;\n    left: 0;\n  "]))));
export var commonHandleSalesSubmit = function (values, props) { return __awaiter(void 0, void 0, void 0, function () {
    var drawer, storeId, createPaymentHistory, updatePaymentHistory, isUpdated, ticketTemplateId, usingHistory, isReservationFee, methods, pictures, invalidPicture, payload;
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        drawer = props.drawer, storeId = props.storeId, createPaymentHistory = props.createPaymentHistory, updatePaymentHistory = props.updatePaymentHistory;
        isUpdated = !!((_a = drawer.data) === null || _a === void 0 ? void 0 : _a.id);
        ticketTemplateId = ((_c = (_b = values.ticket) === null || _b === void 0 ? void 0 : _b.copiedTicketTemplate) === null || _c === void 0 ? void 0 : _c.id) ||
            (values.tickets && values.tickets[0] && ((_d = values.tickets[0].copiedTicketTemplate) === null || _d === void 0 ? void 0 : _d.id));
        usingHistory = values.usingHistory;
        isReservationFee = values.salesPerManagers && values.salesPerManagers[0].type === SALES_TYPES.RESERVATION_FEE;
        if ((!ticketTemplateId && drawer.type === 'ticket') ||
            (drawer.type === 'product' && isNilOrEmptyArray(usingHistory) && !isReservationFee)) {
            throw new Error('매출 등록 할 상품 혹은 정기권을 선택해주세요.');
        }
        methods = omitEmptyValues(mapValues(values.methods, function (o) { return (o.active ? o.deduction || null : isFinite(o) ? o : null); }));
        pictures = ((_e = values.meta) === null || _e === void 0 ? void 0 : _e.pictures) || [];
        if (pictures.length > 0) {
            invalidPicture = pictures === null || pictures === void 0 ? void 0 : pictures.find(function (picture) {
                return !(picture === null || picture === void 0 ? void 0 : picture.startsWith('https://'));
            });
            if (invalidPicture) {
                Bugsnag.notify(new Error(JSON.stringify({ values: values, message: '유효하지 않은 사진이있습니다.' })));
                throw new Error('유효하지 않은 사진이있습니다. 사진을 확인해주세요.');
            }
        }
        payload = usingHistory
            ? __assign(__assign({}, values), { storeId: storeId, methods: methods, usingHistory: usingHistory
                    .map(function (item) {
                    return __assign(__assign({}, item), { salesPerManagers: (item.salesPerManagers || [])
                            .filter(function (item) { return !!item.managerId; })
                            .map(function (spm) { return ({
                            managerId: spm.managerId,
                            shareRate: spm.shareRate || 0,
                            sharePrice: spm.sharePrice || 0
                        }); }) });
                })
                    .filter(function (item) { return !isEmpty(item.salesPerManagers); }), createCustomerPoint: (values === null || values === void 0 ? void 0 : values.createCustomerPoint) || 0, prepaidPoints: (methods === null || methods === void 0 ? void 0 : methods.prepaidPoint) ? values.prepaidPoints : [] }) : __assign(__assign({}, values), { storeId: storeId, methods: methods, ticket: __assign(__assign({}, values.ticket), { salesPerManagers: (values.ticket.salesPerManagers || [])
                    .filter(function (item) { return !!item.managerId; })
                    .map(function (spm) { return ({
                    managerId: spm.managerId,
                    shareRate: spm.shareRate || 0,
                    sharePrice: spm.sharePrice || 0
                }); }) }), createCustomerPoint: (values === null || values === void 0 ? void 0 : values.createCustomerPoint) || 0, prepaidPoints: (methods === null || methods === void 0 ? void 0 : methods.prepaidPoint) ? values.prepaidPoints : [] });
        if (!isUpdated) {
            return [2 /*return*/, createPaymentHistory(payload)];
        }
        else {
            return [2 /*return*/, updatePaymentHistory(__assign(__assign({}, payload), { id: (_f = drawer.data) === null || _f === void 0 ? void 0 : _f.id }))];
        }
        return [2 /*return*/];
    });
}); };
var templateObject_1, templateObject_2;
