var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import dayjs from 'dayjs';
import React from 'react';
import { HookFormInputs, TextComponent } from 'renderer/components';
import { DATE_FORMAT } from 'renderer/constants';
import { TICKET_TYPE } from 'renderer/models';
import { COLORS } from 'renderer/styles';
import styled from 'styled-components';
import { Layout } from 'common-ui';
import { SalesDetailContentWrapper } from './SalesDetailContents';
import Row from 'antd/lib/row';
var FullWidth = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var TicketCardForRefund = function (props) {
    var _a, _b, _c;
    var ticket = (_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.ticket;
    if (!ticket) {
        return React.createElement("div", null);
    }
    var form = props.form;
    return (React.createElement(SalesDetailContentWrapper, { style: { marginTop: '20px' } },
        React.createElement(Row, null,
            React.createElement(TextComponent, { children: ticket === null || ticket === void 0 ? void 0 : ticket.name, type: "subtitle2" }),
            React.createElement(TextComponent, { marginLeft: 4, children: (_c = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate) === null || _c === void 0 ? void 0 : _c.additional, type: "caption1", color: COLORS.gray2, ellipsis: true })),
        React.createElement(TextComponent, { color: COLORS.gray2 }, ticket.benefitString),
        React.createElement(TextComponent, { marginBottom: 20, color: COLORS.gray2 },
            ticket.getTicketDataToString(true),
            " \uC720\uD6A8\uAE30\uAC04 :",
            ' ',
            ticket.expiredAt ? dayjs(ticket.expiredAt).format(DATE_FORMAT) : '무제한'),
        ticket.type === TICKET_TYPE.CHARGING_MONEY || ticket.type === TICKET_TYPE.COUNT ? (React.createElement(Layout.FluidInline, { gutter: 16 },
            React.createElement(FullWidth, null,
                React.createElement(HookFormInputs.NumberInput, { key: "ticket.data.deduction", inputProps: {
                        onChange: function (value) {
                            form.setValue('ticket.data.remain', ticket.data.remain - value);
                        },
                        suffix: ticket.type === TICKET_TYPE.CHARGING_MONEY ? '원' : '회'
                    }, label: ticket.type === TICKET_TYPE.CHARGING_MONEY ? '차감액' : '차감 횟수', controlProps: {
                        name: 'ticket.data.deduction',
                        control: form.control,
                        rules: {
                            max: {
                                value: ticket.data.remain,
                                message: ticket.type === TICKET_TYPE.CHARGING_MONEY
                                    ? '차감액 잔여액을 넘을 수 없습니다.'
                                    : '차감횟수는 잔여횟수를 넘을 수 없습니다.'
                            }
                        }
                    } })),
            React.createElement(FullWidth, null,
                React.createElement(HookFormInputs.NumberInput, { key: "ticket.data.remain", inputProps: {
                        disabled: true,
                        suffix: ticket.type === TICKET_TYPE.CHARGING_MONEY ? '원' : '회'
                    }, label: "\uCC28\uAC10 \uD6C4 \uC794\uC5EC", controlProps: {
                        name: 'ticket.data.remain',
                        control: form.control
                    } })))) : (React.createElement(Layout.FluidInline, { gutter: 16 },
            React.createElement(FullWidth, null,
                React.createElement(HookFormInputs.DatePicker, { key: "ticket.data.startedAt", inputProps: {
                        disabled: true
                    }, label: "\uC2DC\uC791\uC77C\uC2DC", controlProps: {
                        name: 'ticket.data.startedAt',
                        control: form.control
                    } })),
            React.createElement(FullWidth, null,
                React.createElement(HookFormInputs.DatePicker, { key: "ticket.data.expiredAt", inputProps: {}, label: "\uC885\uB8CC\uC77C\uC2DC", controlProps: {
                        name: 'ticket.data.expiredAt',
                        control: form.control,
                        rules: {
                            validate: function (value) {
                                if (dayjs(ticket.expiredAt).isBefore(value, 'day')) {
                                    return '종료일은 유효기간보다 과거로 설정해주세요.';
                                }
                                if (dayjs(ticket.data.startedAt).isAfter(value, 'day')) {
                                    return '종료일은 시작일보다 미래시점으로 설정해주세요.';
                                }
                                return true;
                            }
                        }
                    } }))))));
};
export var ManagerCardForRefund = function (props) {
    var index = props.index, spm = props.spm, fields = props.fields, form = props.form;
    return (React.createElement(React.Fragment, null, fields
        .map(function (field) {
        var key = field.key, label = field.label;
        var name = "salesPerManagers.".concat(index, ".methods.").concat(key);
        return (React.createElement(Layout.FluidInline, { key: spm.managerId, stretch: 1, justify: "start", style: { marginBottom: '10px' } },
            React.createElement(FullWidth, null,
                React.createElement(HookFormInputs.CheckboxInput, { key: "".concat(name, ".checked"), inputProps: {
                        onChange: function (ev) {
                            var checked = ev.target.checked;
                            form.setValue("".concat(name, ".checked"), checked);
                            form.setValue("".concat(name, ".amount"), 0);
                        },
                        checked: form.getValues("".concat(name, ".checked")),
                        children: label,
                        value: form.getValues("".concat(name, ".checked"))
                    }, controlProps: {
                        name: "".concat(name, ".amount"),
                        control: form.control
                    } })),
            React.createElement(FullWidth, null,
                React.createElement(StyledNumberInput, { key: "".concat(name, ".amount"), inputProps: {
                        suffix: '원',
                        disabled: !form.getValues("".concat(name, ".checked"))
                    }, controlProps: {
                        name: "".concat(name, ".amount"),
                        control: form.control
                    } }))));
    })
        .filter(Boolean)));
};
var StyledNumberInput = styled(HookFormInputs.NumberInput)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 130px !important;\n"], ["\n  width: 130px !important;\n"])));
var templateObject_1, templateObject_2;
