var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from 'styled-components';
import { convertHexToRgbA, get } from 'renderer/utils';
import { COLORS, MediaQuery } from 'renderer/styles';
import React from 'react';
import { DeletePopconfirm } from './antd/Popconfirm';
import Button from 'antd/lib/button';
import { Icon, ProfileImage, TextComponent, useGlobalModal } from 'renderer/components';
import { Row } from 'antd/lib/grid';
import { Divider } from 'antd/lib';
export var WrapperDiv = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-left: ", "px;\n  padding-right: ", "px;\n  padding-bottom: ", "px;\n  padding-top: ", "px;\n"], ["\n  padding-left: ", "px;\n  padding-right: ", "px;\n  padding-bottom: ", "px;\n  padding-top: ", "px;\n"])), function (props) { return props.paddingLeft || 0; }, function (props) { return props.paddingRight || 0; }, function (props) { return props.paddingTop || 0; }, function (props) { return props.paddingBottom || 0; });
export var FlexDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var RowDiv = styled(FlexDiv)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-direction: row;\n"], ["\n  flex-direction: row;\n"])));
export var ColDiv = styled(FlexDiv)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-direction: column;\n"], ["\n  flex-direction: column;\n"])));
export var CenterRowDiv = styled(RowDiv)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  justify-content: center;\n  align-items: center;\n"], ["\n  justify-content: center;\n  align-items: center;\n"])));
export var CenterColDiv = styled(ColDiv)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  justify-content: center;\n  align-items: center;\n"], ["\n  justify-content: center;\n  align-items: center;\n"])));
export var CircleButton = styled(Button).attrs({
    shape: 'round',
    type: 'primary'
})(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-width: 0px;\n  padding: 0px;\n  width: 38px !important;\n  height: 38px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  & > div {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    & > img {\n      width: 40px;\n      height: 40px;\n    }\n  }\n\n  ", "\n"], ["\n  border-width: 0px;\n  padding: 0px;\n  width: 38px !important;\n  height: 38px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  & > div {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    & > img {\n      width: 40px;\n      height: 40px;\n    }\n  }\n\n  ", "\n"])), MediaQuery.tabletWithMobile(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    width: 30px !important;\n    max-width: 30px !important;\n    min-width: 30px !important;\n    height: 30px !important;\n    border-radius: 15px;\n  "], ["\n    width: 30px !important;\n    max-width: 30px !important;\n    min-width: 30px !important;\n    height: 30px !important;\n    border-radius: 15px;\n  "]))));
export var DefaultCircleButton = styled(CircleButton).attrs({
    type: 'default'
})(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
export var ProfileCircleContent = function (props) {
    var picture = props.picture, firstChar = props.firstChar;
    return picture ? (React.createElement(ProfileImage, { src: picture, alt: "avatar" })) : (React.createElement(ProfileName, { type: "headline2", color: COLORS.white, children: firstChar }));
};
export var ProfileContentDiv = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
export var ProfileName = styled(TextComponent)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: -3px;\n"], ["\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: -3px;\n"])));
export var ManagerCircleButton = styled(CircleButton)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background-color: ", ";\n  border-width: 0px;\n  padding: 0px;\n\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border-width: 0px;\n  padding: 0px;\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (props) { var _a, _b; return ((_b = (_a = props.$manager) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.color) || COLORS.white; }, function (props) { var _a, _b; return convertHexToRgbA(((_b = (_a = props.$manager) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.color) || COLORS.black, 0.6); });
export var ActionInCell = function (props) {
    return (React.createElement(Wrapper, null,
        props.onEditClick && (React.createElement(DeleteDiv, { onClick: props.onEditClick, children: React.createElement(Icon, { size: 16, name: "edit", color: COLORS.gray1 }) })),
        props.onDeleteClick && (React.createElement(DeletePopconfirm, { title: props.deleteTitle || '정말 삭제하시겠어요?', confirm: "\uC0AD\uC81C", cancel: "\uCDE8\uC18C", onConfirm: props.onDeleteClick })),
        props.onUpClick && (React.createElement(DeleteDiv, { onClick: props.onUpClick, children: React.createElement(Icon, { size: 16, name: "up", color: COLORS.gray1 }) })),
        props.onDownClick && (React.createElement(DeleteDiv, { onClick: props.onDownClick, children: React.createElement(Icon, { size: 16, name: "down", color: COLORS.gray1 }) }))));
};
var Wrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-contents: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-contents: center;\n"])));
var DeleteDiv = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  cursor: pointer;\n\n  & > svg {\n    margin-left: 10px;\n  }\n"], ["\n  cursor: pointer;\n\n  & > svg {\n    margin-left: 10px;\n  }\n"])));
export var Marginer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  height: ", "px;\n  width: ", "px;\n"], ["\n  height: ", "px;\n  width: ", "px;\n"])), function (props) { return props.height; }, function (props) { return props.width; });
var PupleBg = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 100%;\n  padding: 20px 14px;\n  border-radius: 2px;\n  border: solid 1px ", ";\n  background-color: ", ";\n"], ["\n  width: 100%;\n  padding: 20px 14px;\n  border-radius: 2px;\n  border: solid 1px ", ";\n  background-color: ", ";\n"])), COLORS.primary2, COLORS.primary5);
export var PupleBgColumn = styled(PupleBg)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"])));
export var PupleBgRow = styled(PupleBg)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n"], ["\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n"])));
export var CustomerTextButton = function (props) {
    var _a;
    var modal = useGlobalModal();
    var customer = props.customer, rest = __rest(props, ["customer"]);
    return customer ? (React.createElement(StyledText, __assign({ type: "subtitle3", color: COLORS.gray1, onClick: function (ev) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                ev.stopPropagation();
                ev.preventDefault();
                if (customer) {
                    modal.open({ type: 'customerDetail', data: { customerId: Number(customer === null || customer === void 0 ? void 0 : customer.id) } });
                }
                if (props.onCustomerDetailOpen) {
                    props.onCustomerDetailOpen();
                }
                return [2 /*return*/];
            });
        }); } }, rest), (_a = props.customer) === null || _a === void 0 ? void 0 :
        _a.name,
        " >")) : (React.createElement(TextComponent, __assign({ color: COLORS.gray1 }, rest, { children: props.isDeleted ? '삭제된 고객' : '미등록' })));
};
var StyledText = styled(TextComponent)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  text-decoration: underline;\n  cursor: pointer;\n"], ["\n  text-decoration: underline;\n  cursor: pointer;\n"])));
export var ButtonTabs = function (props) {
    var defaultKey = props.tab || get(props.tabs, '0.0.id') || null;
    var _a = React.useState(defaultKey), key = _a[0], setKey = _a[1];
    React.useEffect(function () {
        if (props.tab !== key) {
            setKey((props.tab || defaultKey));
        }
    }, [props.tab]);
    return (React.createElement("div", null,
        React.createElement("div", { style: { marginBottom: '8px' } }, props.tabs.map(function (group, index) {
            var _a;
            var isLast = ((_a = props.tabs) === null || _a === void 0 ? void 0 : _a.length) - 1 === index;
            return (React.createElement(React.Fragment, null,
                React.createElement(Row, { key: "group_".concat(index) }, group.map(function (tab) {
                    return (React.createElement(Button, { style: {
                            marginRight: '5px',
                            backgroundColor: key === tab.id ? COLORS.primary5 : COLORS.white
                        }, children: tab.children, key: tab.id, onClick: function () {
                            setKey(tab.id);
                            props.onChange(tab.id);
                        } }));
                })),
                !isLast && React.createElement(Divider, { style: { margin: '8px 0' } })));
        })),
        key && props.renderByKey(key)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
