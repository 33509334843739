var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { FaTrashAlt } from '@react-icons/all-files/fa/FaTrashAlt';
import { FiRotateCcw } from '@react-icons/all-files/fi/FiRotateCcw';
import { FiSearch } from '@react-icons/all-files/fi/FiSearch';
import { FiEdit } from '@react-icons/all-files/fi/FiEdit';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import { FiBold } from '@react-icons/all-files/fi/FiBold';
import { FiFacebook } from '@react-icons/all-files/fi/FiFacebook';
import { FiInstagram } from '@react-icons/all-files/fi/FiInstagram';
import { FiX } from '@react-icons/all-files/fi/FiX';
import { FiUpload } from '@react-icons/all-files/fi/FiUpload';
import { FiDownload } from '@react-icons/all-files/fi/FiDownload';
import { FiCheck } from '@react-icons/all-files/fi/FiCheck';
import { FiChevronUp } from '@react-icons/all-files/fi/FiChevronUp';
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown';
import { FiSend } from '@react-icons/all-files/fi/FiSend';
import { FiFilter } from '@react-icons/all-files/fi/FiFilter';
import { FiCornerDownRight } from '@react-icons/all-files/fi/FiCornerDownRight';
import { FiArrowLeft } from '@react-icons/all-files/fi/FiArrowLeft';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';
import { FiEdit3 } from '@react-icons/all-files/fi/FiEdit3';
import { FiEye } from '@react-icons/all-files/fi/FiEye';
import { FiEyeOff } from '@react-icons/all-files/fi/FiEyeOff';
import { FiPlus } from '@react-icons/all-files/fi/FiPlus';
import { FiMinus } from '@react-icons/all-files/fi/FiMinus';
import { FiCalendar } from '@react-icons/all-files/fi/FiCalendar';
import { FiFileText } from '@react-icons/all-files/fi/FiFileText';
import { FiBox } from '@react-icons/all-files/fi/FiBox';
import { FiBarChart2 } from '@react-icons/all-files/fi/FiBarChart2';
import { FiDollarSign } from '@react-icons/all-files/fi/FiDollarSign';
import { FiHome } from '@react-icons/all-files/fi/FiHome';
import { FiDatabase } from '@react-icons/all-files/fi/FiDatabase';
import { FiCreditCard } from '@react-icons/all-files/fi/FiCreditCard';
import { FiUserPlus } from '@react-icons/all-files/fi/FiUserPlus';
import { FiFilePlus } from '@react-icons/all-files/fi/FiFilePlus';
import { FiMenu } from '@react-icons/all-files/fi/FiMenu';
import { FiMail } from '@react-icons/all-files/fi/FiMail';
import { FiCornerDownLeft } from '@react-icons/all-files/fi/FiCornerDownLeft';
import { FiSettings } from '@react-icons/all-files/fi/FiSettings';
import { FiAlertCircle } from '@react-icons/all-files/fi/FiAlertCircle';
import { FiMoreHorizontal } from '@react-icons/all-files/fi/FiMoreHorizontal';
import { FiCircle } from '@react-icons/all-files/fi/FiCircle';
import { FiCheckCircle } from '@react-icons/all-files/fi/FiCheckCircle';
import { FiPlusCircle } from '@react-icons/all-files/fi/FiPlusCircle';
import { FiMinusCircle } from '@react-icons/all-files/fi/FiMinusCircle';
import { FiSliders } from '@react-icons/all-files/fi/FiSliders';
import { FiClock } from '@react-icons/all-files/fi/FiClock';
import { FiArchive } from '@react-icons/all-files/fi/FiArchive';
import { FiMoreVertical } from '@react-icons/all-files/fi/FiMoreVertical';
import { FiPaperclip } from '@react-icons/all-files/fi/FiPaperclip';
import { FiSave } from '@react-icons/all-files/fi/FiSave';
import { FiAlertTriangle } from '@react-icons/all-files/fi/FiAlertTriangle';
import { FiList } from '@react-icons/all-files/fi/FiList';
import { FiCamera } from '@react-icons/all-files/fi/FiCamera';
import { FiGift } from '@react-icons/all-files/fi/FiGift';
import { FiUsers } from '@react-icons/all-files/fi/FiUsers';
import { FiSmile } from '@react-icons/all-files/fi/FiSmile';
import { FiAward } from '@react-icons/all-files/fi/FiAward';
import { FiTrash2 } from '@react-icons/all-files/fi/FiTrash2';
import { FiPhone } from '@react-icons/all-files/fi/FiPhone';
import { FiPercent } from '@react-icons/all-files/fi/FiPercent';
import { FiRefreshCw } from '@react-icons/all-files/fi/FiRefreshCw';
import { FiClipboard } from '@react-icons/all-files/fi/FiClipboard';
import { FiCopy } from '@react-icons/all-files/fi/FiCopy';
import { FiAlignLeft } from '@react-icons/all-files/fi/FiAlignLeft';
import { FiAlignRight } from '@react-icons/all-files/fi/FiAlignRight';
import { FiAlignCenter } from '@react-icons/all-files/fi/FiAlignCenter';
import { FiLink2 } from '@react-icons/all-files/fi/FiLink2';
import { FiLogOut } from '@react-icons/all-files/fi/FiLogOut';
import { FiPackage } from '@react-icons/all-files/fi/FiPackage';
import { FiCode } from '@react-icons/all-files/fi/FiCode';
import { FiZoomIn } from '@react-icons/all-files/fi/FiZoomIn';
import { FiZoomOut } from '@react-icons/all-files/fi/FiZoomOut';
import { FiTrash } from '@react-icons/all-files/fi/FiTrash';
import { FiPieChart } from '@react-icons/all-files/fi/FiPieChart';
import { RiMoneyDollarCircleLine } from '@react-icons/all-files/ri/RiMoneyDollarCircleLine';
import { BsFillCalendarFill } from '@react-icons/all-files/bs/BsFillCalendarFill';
import { BsFillEnvelopeOpenFill } from '@react-icons/all-files/bs/BsFillEnvelopeOpenFill';
import { IoIosGift } from '@react-icons/all-files/io/IoIosGift';
import { FaTicketAlt } from '@react-icons/all-files/fa/FaTicketAlt';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
var getIconElement = function (name) {
    switch (name) {
        case 'alert':
            return FiAlertCircle;
        case 'edit':
            return FiEdit;
        case 'menu':
            return FiMenu;
        case 'left-chevron':
            return FiChevronLeft;
        case 'right-chevron':
            return FiChevronRight;
        case 'down-chevron':
            return FiChevronDown;
        case 'up-chevron':
            return FiChevronUp;
        case 'search':
            return FiSearch;
        case 'rotate':
            return FiRotateCcw;
        case 'trash':
            return FiTrash;
        case 'trash-2':
            return FiTrash2;
        case 'bold':
            return FiBold;
        case 'bold':
            return FiBold;
        case 'facebook':
            return FiFacebook;
        case 'instagram':
            return FiInstagram;
        case 'x':
            return FiX;
        case 'upload':
            return FiUpload;
        case 'download':
            return FiDownload;
        case 'check':
            return FiCheck;
        case 'up':
            return FiChevronUp;
        case 'down':
            return FiChevronDown;
        case 'send':
            return FiSend;
        case 'filter':
            return FiFilter;
        case 'rotate':
            return FiRotateCcw;
        case 'corner-down-right':
            return FiCornerDownRight;
        case 'corner-down-left':
            return FiCornerDownLeft;
        case 'arrow-left':
            return FiArrowLeft;
        case 'external-link':
            return FiExternalLink;
        case 'mail':
            return FiMail;
        case 'edit3':
            return FiEdit3;
        case 'eye':
            return FiEye;
        case 'eye-off':
            return FiEyeOff;
        case 'plus':
            return FiPlus;
        case 'minus':
            return FiMinus;
        case 'calendar':
            return FiCalendar;
        case 'file-text':
            return FiFileText;
        case 'file-plus':
            return FiFilePlus;
        case 'box':
            return FiBox;
        case 'bar-chart-2':
            return FiBarChart2;
        case 'pie-chart':
            return FiPieChart;
        case 'dollar-sign':
            return FiDollarSign;
        case 'database':
            return FiDatabase;
        case 'mail':
            return FiMail;
        case 'home':
            return FiHome;
        case 'credit-card':
            return FiCreditCard;
        case 'user-plus':
            return FiUserPlus;
        case 'settings':
            return FiSettings;
        case 'arrow-left':
            return FiArrowLeft;
        case 'more-horizontal':
            return FiMoreHorizontal;
        case 'more-vertical':
            return FiMoreVertical;
        case 'circle':
            return FiCircle;
        case 'check-circle':
            return FiCheckCircle;
        case 'plus-circle':
            return FiPlusCircle;
        case 'minus-circle':
            return FiMinusCircle;
        case 'sliders':
            return FiSliders;
        case 'archive':
            return FiArchive;
        case 'clock':
            return FiClock;
        case 'paperclip':
            return FiPaperclip;
        case 'save':
            return FiSave;
        case 'alert-triangle':
            return FiAlertTriangle;
        case 'list':
            return FiList;
        case 'camera':
            return FiCamera;
        case 'gift':
            return FiGift;
        case 'users':
            return FiUsers;
        case 'smile':
            return FiSmile;
        case 'dollar':
            return FiDollarSign;
        case 'award':
            return FiAward;
        case 'phone':
            return FiPhone;
        case 'percent':
            return FiPercent;
        case 'refresh':
            return FiRefreshCw;
        case 'clipboard':
            return FiClipboard;
        case 'copy':
            return FiCopy;
        case 'alignLeft':
            return FiAlignLeft;
        case 'alignCenter':
            return FiAlignCenter;
        case 'alignRight':
            return FiAlignRight;
        case 'faTrash':
            return FaTrashAlt;
        case 'BsFillCalendarCheckFill':
            return BsFillCalendarFill;
        case 'BsFillEnvelopeOpenFill':
            return BsFillEnvelopeOpenFill;
        case 'IoIosGift':
            return IoIosGift;
        case 'FaTicketAlt':
            return FaTicketAlt;
        case 'link2':
            return FiLink2;
        case 'logout':
            return FiLogOut;
        case 'transfer':
            return RiMoneyDollarCircleLine;
        case 'package':
            return FiPackage;
        case 'zoomIn':
            return FiZoomIn;
        case 'zoomOut':
            return FiZoomOut;
        case 'code':
            return FiCode;
        default:
            return FiX;
    }
};
export var Icon = function (props) {
    var name = props.name, rest = __rest(props, ["name"]);
    return React.createElement(getIconElement(name), rest);
};
export var LeftIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 5px;\n"], ["\n  margin-right: 5px;\n"])));
export var Rectangle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", "px;\n  height: ", "px;\n  border-radius: 4px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: ", "px;\n  height: ", "px;\n  border-radius: 4px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), function (props) { return props.width || 28; }, function (props) { return props.width || 28; }, function (props) { return props.color || COLORS.black; });
export var Circle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", "px;\n  height: ", "px;\n  border-radius: 50%;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: ", "px;\n  height: ", "px;\n  border-radius: 50%;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), function (props) { return props.width || 28; }, function (props) { return props.width || 28; }, function (props) { return props.color || COLORS.black; });
export var ClickableIcon = styled(Icon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 5px;\n"], ["\n  cursor: pointer;\n  padding: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
