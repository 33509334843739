var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { DATE_FORMAT } from 'renderer/constants';
import { ManagerQuery, StoreQuery } from 'renderer/queries';
import { TextComponent, BigTitleModal, DatePickerAutoaccept, syncNaverCalendar, useEntireLoader } from 'renderer/components';
import dayjs from 'dayjs';
import { SmartPlcaceSetter } from './NewNaver';
export var NaverBlockModal = function (props) {
    var _a, _b, _c;
    var store = StoreQuery.getMyStoreInCache();
    var managers = ManagerQuery.getManagersInCache();
    var entireLoader = useEntireLoader();
    var _d = React.useState(props.targetDate), date = _d[0], setDate = _d[1];
    React.useEffect(function () {
        setDate(props.targetDate);
    }, [props.targetDate]);
    var blockSettings = (_b = (_a = store === null || store === void 0 ? void 0 : store.preference) === null || _a === void 0 ? void 0 : _a.smartplace) === null || _b === void 0 ? void 0 : _b.blockSettings;
    var typeString = (_c = blockSettings === null || blockSettings === void 0 ? void 0 : blockSettings.types) === null || _c === void 0 ? void 0 : _c.map(function (type) { return (type === 'reservation' ? '예약' : type === 'off' ? '휴무' : '일정'); }).join(',');
    return (React.createElement(BigTitleModal, { centered: true, open: !!props.targetDate, onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        entireLoader.show();
                        return [4 /*yield*/, syncNaverCalendar({ effectedDates: [date], store: store, managers: managers })];
                    case 1:
                        _a.sent();
                        entireLoader.hide();
                        props.onClose();
                        return [2 /*return*/];
                }
            });
        }); }, onCancel: props.onClose, okText: "\uB124\uC774\uBC84\uC608\uC57D \uB9C9\uAE30", title: "\uB124\uC774\uBC84 \uC608\uC57D \uB9C9\uAE30", children: React.createElement("div", { style: { padding: '20px' } },
            React.createElement(DatePickerAutoaccept, { value: dayjs(date), allowClear: false, inputReadOnly: true, showNow: false, onChange: function (value) { return setDate(value.toDate()); } }),
            React.createElement(TextComponent, { marginTop: 20, children: "".concat(dayjs(date).format(DATE_FORMAT), "\uC758 ").concat(typeString, ",\uC0C1\uC810\uC601\uC5C5\uC2DC\uAC04\uC5D0 \uB530\uB77C \uB124\uC774\uBC84\uC608\uC57D \uCE98\uB9B0\uB354\uB97C \uC870\uC815\uD569\uB2C8\uB2E4.") })) }));
};
export var NaverSmartPlaceCrawler = function (props) {
    return React.createElement(SmartPlcaceSetter, __assign({}, props));
};
