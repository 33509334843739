var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { TextComponent, Icon } from 'renderer/components';
import styled from 'styled-components';
import Modal from 'antd/lib/modal';
import { Col, Row } from 'antd/lib/grid';
import { COLORS } from 'renderer/styles';
import Button from 'antd/lib/button';
import { Lodash } from 'renderer/utils';
export function PasswordKeypad(props) {
    var newProps = __assign({ valueLength: 4 }, props);
    var title = newProps.title, valueLength = newProps.valueLength, body = newProps.body, error = newProps.error, onChange = newProps.onChange, onSubmit = newProps.onSubmit, onCancel = newProps.onCancel, open = newProps.open;
    var _a = React.useState(''), value = _a[0], setValue = _a[1];
    var keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, '취소', 0, '<'];
    React.useEffect(function () {
        if (value.length === valueLength && !error) {
            onSubmit(value);
        }
        if (error) {
            setValue('');
        }
    }, [value, error]);
    var handleChange = function (newValue) {
        setValue(newValue);
        onChange(newValue);
    };
    return (React.createElement(BackdropModel, { visible: open, closable: false, footer: null },
        React.createElement("div", { style: {
                top: 0,
                height: '50%',
                display: 'flex',
                flexDirection: 'column'
            } },
            React.createElement(TextComponent, { type: "h5", marginBottom: 10, marginTop: 10 },
                React.createElement("b", null, title)),
            React.createElement(TextComponent, { type: "subtitle1", marginBottom: 20, marginTop: 10 },
                React.createElement("b", null, body)),
            React.createElement(Row, { justify: "space-around", style: { marginTop: '10px' } }, Lodash.times(valueLength).map(function (index) {
                var isTyped = value.length >= index + 1;
                return (
                // fiber_manual_record
                React.createElement(Col, { key: index },
                    React.createElement(Icon, { name: isTyped ? 'check-circle' : 'circle', color: COLORS.black })));
            })),
            React.createElement(TextComponent, { type: "subtitle1", align: "center", marginBottom: 10, marginTop: 10 },
                React.createElement("b", null, error))),
        React.createElement(Row, { style: {
                position: 'fixed',
                bottom: 0,
                height: '50%',
                display: 'flex',
                flex: 1,
                width: '100%',
                left: 0
            } }, keys.map(function (key) {
            return (React.createElement(Col, { span: 8, key: key },
                React.createElement(Button, { style: { width: '100%', height: '100%' }, 
                    // classes={buttonClasses}
                    onClick: function () {
                        if (Lodash.isNumber(key)) {
                            if (value.length < valueLength) {
                                handleChange(value + "".concat(key));
                            }
                        }
                        else {
                            if (key === '<') {
                                if (value && value.length > 0) {
                                    handleChange(value.slice(0, -1));
                                }
                            }
                            else {
                                handleChange('');
                                onCancel();
                            }
                        }
                    } },
                    React.createElement(TextComponent, { type: "headline2", textAlign: "center" },
                        React.createElement("b", null, key)))));
        }))));
}
var BackdropModel = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  // .ant-modal-content {\n  //   background-color: ", ";\n  //   box-shadow: none;\n  // }\n\n  &.ant-modal {\n    width: 100%;\n    height: 100%;\n    top: 0px;\n    left: 0px;\n    padding-bottom: 0px;\n    margin: 0px;\n    margin-right: 0px;\n    max-width: 100%;\n  }\n  & .ant-modal-content {\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n  }\n\n  & .ant-modal-body {\n    flex: 1;\n  }\n"], ["\n  // .ant-modal-content {\n  //   background-color: ", ";\n  //   box-shadow: none;\n  // }\n\n  &.ant-modal {\n    width: 100%;\n    height: 100%;\n    top: 0px;\n    left: 0px;\n    padding-bottom: 0px;\n    margin: 0px;\n    margin-right: 0px;\n    max-width: 100%;\n  }\n  & .ant-modal-content {\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n  }\n\n  & .ant-modal-body {\n    flex: 1;\n  }\n"])), function (props) { return props.backgroundColor || 'transparent'; });
var templateObject_1;
