var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Row from 'antd/lib/row';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import useMeasure from 'react-use/lib/useMeasure';
import { AntButton } from 'renderer/components';
import styled from 'styled-components';
export var SignaturePad = function (props) {
    var ref = React.useRef();
    var _a = React.useState(props.value), value = _a[0], setValue = _a[1];
    var _b = useMeasure(), wrapperRef = _b[0], _c = _b[1], x = _c.x, y = _c.y, width = _c.width, height = _c.height, top = _c.top, right = _c.right, bottom = _c.bottom, left = _c.left;
    var handleEnd = function (data) {
        if (ref && ref.current) {
            var value_1 = ref.current.toDataURL();
            props.onChange(value_1);
            setValue(value_1);
        }
    };
    React.useEffect(function () {
        if (props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);
    var handleClear = function (event) {
        event.stopPropagation();
        event.preventDefault();
        if (value) {
            setValue(undefined);
            props.onChange(undefined);
            if (ref.current) {
                ref.current.clear();
            }
        }
    };
    return (React.createElement(SignaturePadWrapper, null,
        value || props.disabled ? (React.createElement(SizemeStyled, null,
            React.createElement("img", { src: value, style: { width: '100%', height: '100%' } }))) : (React.createElement(SizemeStyled, { ref: wrapperRef },
            React.createElement(SignatureCanvas, { ref: ref, canvasProps: { width: width, height: height }, onEnd: handleEnd }))),
        React.createElement(Row, { justify: "end" },
            React.createElement(AntButton, { children: "\uC218\uC815", onClick: handleClear, disabled: !value, type: "primary" }))));
};
export var SignaturePadWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 240px;\n  padding: 12px 12px 5px 12px;\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--gray-7);\n  display: flex;\n  justify-content: space-around;\n  flex-direction: column;\n"], ["\n  width: 100%;\n  height: 240px;\n  padding: 12px 12px 5px 12px;\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--gray-7);\n  display: flex;\n  justify-content: space-around;\n  flex-direction: column;\n"])));
export var SizemeStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  max-height: 170px;\n  flex: 1;\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n"], ["\n  width: 100%;\n  max-height: 170px;\n  flex: 1;\n  border-radius: 2px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white);\n"])));
var templateObject_1, templateObject_2;
