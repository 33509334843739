var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// https://gist.github.com/andywer/800f3f25ce3698e8f8b5f1e79fed5c9c
import React from 'react';
export function Catch(component, errorHandler) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.state = {
                error: undefined
            };
            return _this;
        }
        class_1.getDerivedStateFromError = function (error) {
            return { error: error };
        };
        class_1.prototype.componentDidCatch = function (error, info) {
            if (errorHandler) {
                errorHandler(error, info);
            }
        };
        class_1.prototype.render = function () {
            return component(this.props, this.state.error);
        };
        return class_1;
    }(React.Component));
}
